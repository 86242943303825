import React, { useRef, useState, useEffect } from "react";
import myteam from "../../assets/images/myteam.png";
import profile from "../../assets/images/profile.png";
import pic from "../../assets/images/1.webp";
import "./team.css";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { createTeam } from "../../api/team";
import { getProjects } from "../../api/project";
import { getMembers } from "../../api/members";
import { storageData } from "../../components/helpers/helper";
import Container from "../../components/layouts/container";
import LeftSide from "../../components/layouts/left_side";
function Team() {
  const storageInfo = storageData();
  const tokenid = storageInfo.data.token;
  const compid = storageInfo.data.company_info.id;
  const storeUserID = JSON.parse(localStorage.getItem("u_did"));
  const inputref = useRef(null);
  const [image, setimage] = useState("");
  const handleImageClick = () => {
    inputref.current.click();
  };
  const handleImageChange = (event) => {
    setimage(event.target.files[0]);
  };
  const [step, setStep] = useState(1);

  const handleNextStep = () => {
    // if (step === 1) {
    //   if (proName === "") {
    //     toast.error("Please enter team name");
    //     return false;
    //   }
    // } else if (step === 2) {
    //   if (projectId.length < 1) {
    //     toast.error("Please select a project");
    //     return false;
    //   } else if (assignname.length < 1) {
    //     toast.error("Please select assigned to");
    //     return false;
    //   }
    // } else if (step === 3) {
    //   if (description === "") {
    //     toast.error("Please enter description");
    //     return false;
    //   }
    // }
    if (step === 3) {
      TeamCreate();
      setStep(1);
      handleClose();
      return false;
    }
    setStep(step + 1);
  };

  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  const [datalist, setList] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [proName, setName] = useState("");
  const [projectId, setProjectId] = useState([]);
  const [assignname, setAssign] = useState([]);
  const [members, setMember] = useState([]);
  const [description, setDescription] = useState("");
  const valueArr = [];
  datalist.forEach((employee) => {
    valueArr.push({ value: `${employee.id}`, label: `${employee.name}` });
  });
  const memArr = [];
  members.forEach((mem) => {
    memArr.push({ value: `${mem.id}`, label: `${mem.full_name}` });
  });
  const options = valueArr;
  const memoptions = memArr;
  const handleCode = (event) => {
    setName(event.target.value);
  };
  const handleCode1 = (event) => {
    setProjectId(event.value);
  };
  const handleCode2 = (event) => {
    const eventArr = [];
    event.forEach((employee, index) => {
      eventArr.push(employee.value);
      setAssign(eventArr);
    });
  };
  const handleCode6 = (event) => {
    setDescription(event.target.value);
  };
  const showCards = async () => {
    try {
      const formData = {
        company_id: compid,
        department_id: 1,
      };
      const Token = tokenid;
      const response = await getProjects(formData, Token);
      const res = await getMembers(formData, Token);
      const memdata = res.data.data;
      const data = response.data.data;
      setList(data);
      setMember(memdata);

      toast.success(response.data.message);
      return false;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    showCards();
  }, []);
  const TeamCreate = async () => {
    try {
      const formData = {
        company_id: compid,
        name: proName,
        project_id: projectId,
        added_by: storeUserID,
        team_member: assignname,
        team_project_description: description,
      };
      const Token = tokenid;
      const response = await createTeam(formData, Token);
      toast.success(response.data.message);
      setProjectId([]);
      setAssign([]);
      setName("");
      setDescription("");
      return false;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  const resetStep = () => {
    setStep(1);
    handleShow();
  };
  const closeReset = () => {
    setProjectId([]);
    setAssign([]);
    setName("");
    setDescription("");
    handleClose();
  };

  const renderFormSection = () => {
    switch (step) {
      case 1:
        return (
          <div className="pt-5">
            <div className="team_image ">
              <div className="row step_row">
                <div className="col-lg-8">
                  <label for="validationCustom01" className="form-label">
                    Team Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="validationCustom01"
                    placeholder="e.g SEO Team"
                    onChange={handleCode}
                    value={proName}
                    required
                  />
                </div>
                <div className="col-lg-4">
                  <div className="pickerr">
                    <div className="image_picker" onClick={handleImageClick}>
                      {image ? (
                        <img src={URL.createObjectURL(image)} alt="" />
                      ) : (
                        <img src={profile} alt="" />
                      )}

                      <input
                        type="file"
                        onChange={handleImageChange}
                        ref={inputref}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="pt-5">
            <div className="mt-5">
              <label for="validationCustom01" className="form-label">
                Project
              </label>

              <Select
                options={options}
                onChange={handleCode1}
                // value={options}
              />
            </div>
            <div className="member_images mt-3">
              <img src={pic} alt="" />
              <img src={pic} alt="" className="imagies1" />
              <img src={pic} alt="" className="imagies2" />
              <img src={pic} alt="" className="imagies3" />
            </div>
            <div className="select_members">
              <label className="form-label">Select Members</label>
              <Select options={memoptions} onChange={handleCode2} isMulti />
            </div>
          </div>
        );
      case 3:
        return (
          <div>
            <div className="texted_area pt-5">
              <div className="col-md-12 mt-5">
                <label for="validationCustom04" className="form-label">
                  Project
                </label>
                <textarea
                  rows="7"
                  cols="50"
                  className="text_box"
                  placeholder="Write a short description"
                  onChange={handleCode6}
                  value={description}
                />
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const labelArr = ["About", "Detail", "Discription"];

  return (
    <div>
      <Container>
        <div className="dashboard_main">
          <div className="main_body">
            <LeftSide />
            <div className="right_side w-100">
              <div className="container-fluid">
                <div className="dashboard_inner col-11 m-auto">
                  <div className="board_header">
                    <div className="main_image">
                      <img src={myteam} alt="myteam" />
                      <p>
                        Make your team add new members and assign them task
                        directly
                      </p>
                    </div>
                    <div className="more_time">
                      <button
                        className="btn request_btn w-100"
                        type="button"
                        onClick={resetStep}
                      >
                        Create New Team
                      </button>
                    </div>
                  </div>
                  <div className="tabs_part">
                    <div className="row">
                      <div className="col-lg-3 col-md-3">
                        <div className="tabs_btn_part">
                          <div className="list_heading">
                            <h1>My Teams</h1>
                            <p>Total Teams: 12</p>
                          </div>
                          <div className="">
                            <div
                              className="nav nav-pills row"
                              id="v-pills-tab"
                              role="tablist"
                              aria-orientation="vertical"
                            >
                              <button
                                className="tabs_btn col-lg-12 col-md-12 col-6 active"
                                id="v-pills-home-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#v-pills-home"
                                type="button"
                                role="tab"
                                aria-controls="v-pills-home"
                                aria-selected="true"
                              >
                                Admins
                              </button>
                              <button
                                className="tabs_btn col-lg-12 col-md-12 col-6"
                                id="v-pills-profile-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#v-pills-profile"
                                type="button"
                                role="tab"
                                aria-controls="v-pills-profile"
                                aria-selected="false"
                              >
                                Team Leads
                              </button>

                              <button
                                className="tabs_btn col-lg-12 col-md-12 col-6"
                                id="v-pills-messages-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#v-pills-messages"
                                type="button"
                                role="tab"
                                aria-controls="v-pills-messages"
                                aria-selected="false"
                              >
                                Messages
                              </button>
                              <button
                                className="tabs_btn col-lg-12 col-md-12 col-6"
                                id="v-pills-settings-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#v-pills-settings"
                                type="button"
                                role="tab"
                                aria-controls="v-pills-settings"
                                aria-selected="false"
                              >
                                Settings
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-9 col-md-9">
                        <div className="team_head">
                          <div className="content_team">
                            <h3>Team SEO</h3>
                            <p>Total Members in this team: 09</p>
                          </div>
                          <div className="add_new_poppup">
                            <button
                              className="btn request_btn w-100"
                              type="button"
                              onClick={handleShow1}
                            >
                              Add New Members
                            </button>
                          </div>
                        </div>
                        <div className="table_head">
                          <div className="row">
                            <div className="col-4">
                              <p>Name</p>
                            </div>
                            <div className="col-4">
                              <p>Email</p>
                            </div>
                            <div className="col-4">
                              <p>Level</p>
                            </div>
                          </div>
                        </div>
                        <div className="tab-content" id="v-pills-tabContent">
                          <div
                            className="tab-pane fade active show"
                            id="v-pills-home"
                            role="tabpanel"
                            aria-labelledby="v-pills-home-tab"
                            tabIndex="0"
                          >
                            <div className="members_details mt-3">
                              <div className="row detail_row">
                                <div className="col-4">
                                  <div className="image_name">
                                    <img src={profile} alt="" />
                                    <p>John Doe</p>
                                  </div>
                                </div>
                                <div className="col-4">
                                  <div className="email">
                                    <p>Johndoe@gmail.com</p>
                                  </div>
                                </div>
                                <div className="col-3">
                                  <div className="level">
                                    <p>SEO</p>
                                  </div>
                                </div>
                                <div className="col-1">
                                  <div className="trash_icon">
                                    <i className="fa-solid fa-trash"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="insight_right_main mobile_view_insight pb-0">
                              <div className="mobile_view_insight border border-white">
                                <div
                                  className="row"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#M1"
                                  aria-expanded="false"
                                  aria-controls="M1"
                                >
                                  <div className="col-6">
                                    <div className="">
                                      <div className="header_contents text-center">
                                        <p>Name</p>
                                      </div>
                                      <div
                                        className="collapse height_set"
                                        id="M1"
                                      >
                                        <div className="header_contents text-center">
                                          <p className="">Email</p>
                                          <p className="">Level</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-6 position-relative">
                                    <div className="header_contents">
                                      <p>Saad bin Ghias</p>
                                    </div>
                                    <div className="drop_btn mobile_dropdown">
                                      <i className="fa-solid fa-trash"></i>
                                    </div>
                                    <div
                                      className="collapse height_set"
                                      id="M1"
                                    >
                                      <div className="">
                                        <p className="">Johndoe@gmail.com</p>
                                        <p className="">SEO</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="v-pills-profile"
                            role="tabpanel"
                            aria-labelledby="v-pills-profile-tab"
                            tabIndex="0"
                          >
                            <div className="members_details mt-3">
                              <div className="row detail_row">
                                <div className="col-4">
                                  <div className="image_name">
                                    <img src={profile} alt="" />
                                    <p>John Doe</p>
                                  </div>
                                </div>
                                <div className="col-4">
                                  <div className="email">
                                    <p>Johndoe@gmail.com</p>
                                  </div>
                                </div>
                                <div className="col-3">
                                  <div className="level">
                                    <p>SEO</p>
                                  </div>
                                </div>

                                <div className="col-1">
                                  <div className="trash_icon">
                                    <i className="fa-solid fa-trash"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="insight_right_main mobile_view_insight pb-0">
                              <div className="mobile_view_insight border border-white">
                                <div
                                  className="row"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#M1"
                                  aria-expanded="false"
                                  aria-controls="M1"
                                >
                                  <div className="col-6">
                                    <div className="">
                                      <div className="header_contents text-center">
                                        <p>Name</p>
                                      </div>
                                      <div
                                        className="collapse height_set"
                                        id="M1"
                                      >
                                        <div className="header_contents text-center">
                                          <p className="">Email</p>
                                          <p className="">Level</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-6 position-relative">
                                    <div className="header_contents">
                                      <p>Saad bin Ghias</p>
                                    </div>
                                    <div className="drop_btn mobile_dropdown">
                                      <i className="fa-solid fa-trash"></i>
                                    </div>
                                    <div
                                      className="collapse height_set"
                                      id="M1"
                                    >
                                      <div className="">
                                        <p className="">Johndoe@gmail.com</p>
                                        <p className="">SEO</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className="tab-pane fade"
                            id="v-pills-messages"
                            role="tabpanel"
                            aria-labelledby="v-pills-messages-tab"
                            tabIndex="0"
                          >
                            <div className="members_details mt-3">
                              <div className="row detail_row">
                                <div className="col-4">
                                  <div className="image_name">
                                    <img src={profile} alt="" />
                                    <p>John Doe</p>
                                  </div>
                                </div>
                                <div className="col-4">
                                  <div className="email">
                                    <p>Johndoe@gmail.com</p>
                                  </div>
                                </div>
                                <div className="col-3">
                                  <div className="level">
                                    <p>SEO</p>
                                  </div>
                                </div>

                                <div className="col-1">
                                  <div className="trash_icon">
                                    <i className="fa-solid fa-trash"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="insight_right_main mobile_view_insight pb-0">
                              <div className="mobile_view_insight border border-white">
                                <div
                                  className="row"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#M1"
                                  aria-expanded="false"
                                  aria-controls="M1"
                                >
                                  <div className="col-6">
                                    <div className="">
                                      <div className="header_contents text-center">
                                        <p>Name</p>
                                      </div>
                                      <div
                                        className="collapse height_set"
                                        id="M1"
                                      >
                                        <div className="header_contents text-center">
                                          <p className="">Email</p>
                                          <p className="">Level</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-6 position-relative">
                                    <div className="header_contents">
                                      <p>Saad bin Ghias</p>
                                    </div>
                                    <div className="drop_btn mobile_dropdown">
                                      <i className="fa-solid fa-trash"></i>
                                    </div>
                                    <div
                                      className="collapse height_set"
                                      id="M1"
                                    >
                                      <div className="">
                                        <p className="">Johndoe@gmail.com</p>
                                        <p className="">SEO</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="v-pills-settings"
                            role="tabpanel"
                            aria-labelledby="v-pills-settings-tab"
                            tabIndex="0"
                          >
                            <div className="members_details mt-3">
                              <div className="row detail_row">
                                <div className="col-4">
                                  <div className="image_name">
                                    <img src={profile} alt="" />
                                    <p>John Doe</p>
                                  </div>
                                </div>
                                <div className="col-4">
                                  <div className="email">
                                    <p>Johndoe@gmail.com</p>
                                  </div>
                                </div>
                                <div className="col-3">
                                  <div className="level">
                                    <p>SEO</p>
                                  </div>
                                </div>

                                <div className="col-1">
                                  <div className="trash_icon">
                                    <i className="fa-solid fa-trash"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="insight_right_main mobile_view_insight pb-0">
                              <div className="mobile_view_insight border border-white">
                                <div
                                  className="row"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#M1"
                                  aria-expanded="false"
                                  aria-controls="M1"
                                >
                                  <div className="col-6">
                                    <div className="">
                                      <div className="header_contents text-center">
                                        <p>Name</p>
                                      </div>
                                      <div
                                        className="collapse height_set"
                                        id="M1"
                                      >
                                        <div className="header_contents text-center">
                                          <p className="">Email</p>
                                          <p className="">Level</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-6 position-relative">
                                    <div className="header_contents">
                                      <p>Saad bin Ghias</p>
                                    </div>
                                    <div className="drop_btn mobile_dropdown">
                                      <i className="fa-solid fa-trash"></i>
                                    </div>
                                    <div
                                      className="collapse height_set"
                                      id="M1"
                                    >
                                      <div className="">
                                        <p className="">Johndoe@gmail.com</p>
                                        <p className="">SEO</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade modal-lg"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title" id="staticBackdropLabel">
                  Fill In The Form To Extend Your Deadline
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="">
                <form className="row g-0 needs-validation" noValidate>
                  <div className="modal-body">
                    <div className="request_info">
                      <div className="stepWrapper">
                        {labelArr.map((item, index) => {
                          return (
                            <div
                              className={
                                "stepBlock" +
                                (step === index + 1 ? " selected" : "")
                              }
                            >
                              <div
                                className="circleWrapper"
                                // onClick={() => updateStep(index + 1)}
                              >
                                <div className="circle"></div>
                              </div>
                              <span>{item}</span>
                            </div>
                          );
                        })}
                      </div>
                      {renderFormSection()}
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn cancel_btn"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <div className="">
                      <button
                        type="button"
                        hidden={step === 1}
                        className="btn request_btn back_btn"
                        onClick={handlePreviousStep}
                      >
                        Back
                      </button>
                      <button
                        type="button"
                        className="btn request_btn apply_btn"
                        onClick={handleNextStep}
                      >
                        Proceed
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <Modal
            show={show1}
            onHide={handleClose1}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <div className="model_heading">
                <h1 className="modal-title" id="staticBackdropLabel">
                  Add a new member
                </h1>
                <p>Here you can add new members</p>
              </div>
            </Modal.Header>
            <Modal.Body>
              <div className="request_info">
                <form action="">
                  <div className="col-md-12">
                    <label for="validationCustom01" className="form-label">
                      Email
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="validationCustom01"
                      placeholder="saad@gmail.com"
                      required
                    />
                  </div>
                </form>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                variant="secondary"
                className="cancel_btn"
                onClick={handleClose1}
              >
                Close
              </button>

              <button
                variant="primary"
                className="request_btn apply_btn"
                onClick={handleClose1}
              >
                Send Invite
              </button>
            </Modal.Footer>
          </Modal>
        </div>
        <Modal
          show={show}
          onHide={handleClose}
          size="lg"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <div className="model_heading">
              <h1 className="modal-title" id="staticBackdropLabel">
                Create New Team
              </h1>
              <p>
                Creating a new team in 3 simple steps makes easier with Task
                Definer.
              </p>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="request_info">
              <div className="stepWrapper">
                {labelArr.map((item, index) => {
                  return (
                    <div
                      className={
                        "stepBlock" + (step === index + 1 ? " selected" : "")
                      }
                    >
                      <div
                        className="circleWrapper"
                        // onClick={() => updateStep(index + 1)}
                      >
                        <div className="circle"></div>
                      </div>
                      <span>{item}</span>
                    </div>
                  );
                })}
              </div>
              {renderFormSection()}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              variant="secondary"
              className="cancel_btn"
              onClick={closeReset}
            >
              Close
            </button>
            <div className="">
              <button
                hidden={step === 1}
                className="request_btn back_btn"
                onClick={handlePreviousStep}
              >
                Back
              </button>
              <button
                variant="primary"
                className="request_btn apply_btn"
                onClick={handleNextStep}
              >
                Proceed
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </Container>
    </div>
  );
}

export default Team;
