import { Modal } from "react-bootstrap";

const DeleteModal = ({ handleClose2, removeCard, title }) => {
  return (
    <>
      <Modal.Body>
        <div className="assign_task_form d-flex justify-content-center align-baseline realy_want">
          <i className="fa-solid fa-triangle-exclamation"></i>
          <h6>Do you realy want to delete this {title}?</h6>
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <button className="cancel_btn" onClick={handleClose2}>
          NO
        </button>
        <button
          type="button"
          className="request_btn apply_btn"
          onClick={removeCard}
        >
          YES
        </button>
      </Modal.Footer>
    </>
  );
};

export default DeleteModal;
