import React from "react";
import { ReactComponent as Dots } from "../../../assets/images/dots.svg";

function DotsDropdown({ handleShowDeleteModal, task, handelTaskID, name }) {
  return (
    <div>
      <div className="dropdown justify-content-center">
        <div className="drop_btn">
          <button
            className="dots_btn dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            onClick={() => handelTaskID(task.id)}
          >
            <Dots />
          </button>
          <ul className="dropdown-menu">
            <li>
              <button
                className="dropdown-item"
                type="button"
                onClick={handleShowDeleteModal}
              >
                Remove {name}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default DotsDropdown;
