import * as yup from "yup";

export const editmemberValidationSchema = yup.object({
  member_name: yup.string().required("Name is required"),
});
export const invitememberValidationSchema = yup.object({
  member_email: yup.string().required("Email is required"),
});
export const updatePasswordValidationSchema = yup.object({
  new_pass: yup.string().required("Password is required"),
  confirm_pass: yup.string().required("Confirm password is required"),
});
