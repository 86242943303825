import React, { useState, useRef, useEffect } from "react";
import { ReactComponent as Send } from "../../assets/images/send.svg";
import { ReactComponent as File } from "../../assets/images/file.svg";
import { useTaskContextProvider } from "../../utils/TaskContextProvider";
import { storageData } from "../helpers/helper";
import { API } from "../../api/task";
import CommentCard from "./CommentCard";
import { toast } from "react-toastify";
import moment from "moment";
import { getCommentsHelper } from "../helpers/TaskHelpers";

const Activity = ({ taskId, projectId }) => {
  const {
    departIdasInt,
    comments,
    loading_1,
    activelogs,
    assignedPermissions,
    setComments,
  } = useTaskContextProvider();
  const [selectedFile, setSelectedFile] = useState("");
  const storageInfo = storageData();
  const [inputText, setInputText] = useState("");
  const [displayedTextList, setDisplayedTextList] = useState([]);

  const [displayedRepliesList, setDisplayedRepliesList] = useState([]);
  const [replyContainer, setReplyContainer] = useState(false);
  const [commentId, setCommentId] = useState(null);
  const [commentData, setCommentData] = useState({
    profileImage: "",
    profileName: "",
    comment: "",
    createdAt: "",
    fileName: "",
    filePath: "",
    fileType: "",
  });

  const fileInputRef = useRef(null);

  const handleInputChange = (e) => {
    setInputText(e.target.value);
  };
  const handleEnterDisplayText = (e) => {
    if (e.key === "Enter") {
      handleDisplayText();
    }
  };
  const handleDisplayText = async () => {
    const formData = new FormData();
    formData.append("department_id", departIdasInt);
    formData.append("task_id", taskId);
    formData.append("project_id", projectId);
    formData.append("comments", inputText);
    formData.append("files", selectedFile);
    // eslint-disable-next-line no-lone-blocks
    {
      replyContainer && formData.append("c_id", commentId);
    }

    try {
      if (inputText !== "") {
        const response = await API.post("task/save-comments", formData, {
          headers: {
            Authorization: `Bearer ${storageInfo?.data?.token}`,
          },
        });
        if (!replyContainer) {
          setDisplayedTextList([
            ...displayedTextList,
            {
              comments: inputText,
              created_at: new Date().toISOString(),
              profile_image: response.data.data.profile_image,
              full_name: response.data.data.full_name,
              file_path: response.data.data.file_path,
              file_name: response.data.data.file_name,
              file_type: response.data.data.file_type,
            },
          ]);
        } else {
          setDisplayedRepliesList([
            ...displayedRepliesList,
            {
              comments: inputText,
              created_at: new Date().toISOString(),
              profile_image: response.data.data.profile_image,
              full_name: response.data.data.full_name,
              file_path: response.data.data.file_path,
              file_name: response.data.data.file_name,
              file_type: response.data.data.file_type,
            },
          ]);
        }

        const commentResponse = await getCommentsHelper(taskId);
        setComments(commentResponse.data);
      } else {
        toast.error("Please Enter Comment/Reply");
      }
      setSelectedFile(null);
      setInputText("");
    } catch (error) {
      toast.error(error.response.data.message);
      console.error("Error posting comment:", error);
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setSelectedFile(selectedFile);
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  useEffect(() => {
    if (comments.length !== 0) {
      setDisplayedTextList(comments);
    }
  }, [comments]);

  return (
    <>
      <div className="activity-container">
        <div className="activity_description">
          <div className="activity_heading">
            <h6>Activity</h6>
          </div>
          <div className="activities">
            {activelogs?.map((post) => {
              const timestamp = moment(post.updated_at);
              const time12HourFormat = timestamp.format("MMM Do YYYY,hh:mm:ss");

              return (
                <ul className="active_log">
                  <li key={post.id}>
                    {post.actor_name} {post.type} {post.description}
                  </li>
                  <p className="active_date">{time12HourFormat}</p>
                </ul>
              );
            })}
          </div>
        </div>
      </div>

      {displayedTextList?.length !== 0 && (
        <div className="main_comment_section">
          <div className="main-reply-container">
            {replyContainer ? (
              <div className="reply-container">
                <div className="heading-container">
                  <i
                    onClick={() => setReplyContainer(false)}
                    className="fa fa-chevron-left"
                    aria-hidden="true"
                  ></i>
                  <h6>Thread by {commentData.profileName}</h6>
                </div>
                <CommentCard
                  profileImage={commentData.profileImage}
                  profileName={commentData.profileName}
                  createdAt={commentData.createdAt}
                  comment={commentData.comment}
                  replyContainer={replyContainer}
                  fileName={commentData.fileName}
                  filePath={commentData.filePath}
                  fileType={commentData.fileType}
                />
                {displayedRepliesList.length > 0 && (
                  <div className="replies">
                    <p className="reply-text">
                      {displayedRepliesList.length}{" "}
                      {displayedRepliesList.length === 1 ? "Reply" : "Replies"}
                    </p>
                    <hr />
                  </div>
                )}

                {loading_1 ? (
                  <div>Loading...</div>
                ) : (
                  <div className="comment_mes mb-1" style={{ padding: "1px" }}>
                    {displayedRepliesList &&
                      displayedRepliesList.map((data, index) => (
                        <>
                          <CommentCard
                            key={index}
                            profileImage={data.profile_image}
                            profileName={data.full_name}
                            createdAt={data.created_at}
                            comment={data.comments}
                            setReplyContainer={setReplyContainer}
                            setCommentData={setCommentData}
                            replyContainer={replyContainer}
                            fileName={data.file_name}
                            filePath={data.file_path}
                            fileType={data.file_type}
                            commentId={data.id}
                            setDisplayedRepliesList={setDisplayedRepliesList}
                          />
                        </>
                      ))}
                  </div>
                )}
              </div>
            ) : (
              displayedTextList.length !== 0 && (
                <div className="comment_mes mb-1">
                  {displayedTextList.map((data, index) => (
                    <>
                      <CommentCard
                        key={index}
                        profileImage={data.profile_image}
                        profileName={data.full_name}
                        createdAt={data.created_at}
                        comment={data.comments}
                        setReplyContainer={setReplyContainer}
                        setCommentData={setCommentData}
                        replyContainer={replyContainer}
                        fileName={data.file_name}
                        filePath={data.file_path}
                        fileType={data.file_type}
                        commentId={data.id}
                        setCommentId={setCommentId}
                        taskId={taskId}
                        replyCount={data.reply_count}
                        setDisplayedRepliesList={setDisplayedRepliesList}
                        setDisplayedTextList={setDisplayedTextList}
                      />
                    </>
                  ))}
                </div>
              )
            )}
          </div>
        </div>
      )}

      {selectedFile && (
        <div className="selected-file-container" onClick={handleRemoveFile}>
          <p>{selectedFile.name}</p>
        </div>
      )}
      {assignedPermissions.saveComments && (
        <div className="send_input">
          <input
            type="text"
            className="form-control comment_field"
            placeholder={replyContainer ? "Reply" : "Write a Comment"}
            value={inputText}
            onChange={handleInputChange}
            onKeyDown={handleEnterDisplayText}
          />
          <div className="send_svg">
            <button
              className="send_coment"
              style={{ top: "-10px" }}
              onClick={() => fileInputRef.current.click()}
            >
              <File />
              <input
                type="file"
                id="fileInput"
                style={{ display: "none" }}
                accept=".pdf, .jpg, .jpeg, .png"
                ref={fileInputRef}
                onChange={handleFileChange}
              />
            </button>
            <button
              type="button"
              className="send_coment"
              onClick={handleDisplayText}
            >
              <Send />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Activity;
