import React, { useEffect, useState } from "react";
import { getActivePlan } from "../../api/plan";
import ProgressBar from "react-bootstrap/ProgressBar";
function MyPlanContent() {
  const localdata = JSON.parse(localStorage.getItem("c_info"));
  const tokenid = localdata.data.token;
  const [basicInfo, setBasicData] = useState("");
  const [userInfo, setUserData] = useState("");
  const [date, setDate] = useState("");
  const now = (basicInfo.create_department / basicInfo.dept_limit) * 100;
  const vnow = (userInfo.created_users / basicInfo.user_limit) * 100;
  const showActivePlanDetails = async () => {
    try {
      const formData = {};
      const Token = tokenid;
      const response = await getActivePlan(formData, Token);
      const userData = response.data.data.users_info;
      const basicData = response.data.data.basic_info;
      setUserData({ ...userData });
      setBasicData({ ...basicData });
      var date = basicData.purchase_date;
      const [datePart] = date.split(" ");
      setDate(datePart);
      return false;
    } catch (error) {}
  };

  useEffect(() => {
    showActivePlanDetails();
  }, []);

  return (
    <div>
      <div className="subs_plan plan_subscription">
        <div className="plan_head">
          <h3>Plan</h3>
        </div>
        <div className="col-4 subs_col">
          <div className="mainPl_detail">
            <div className="plan_details">
              <p className="pl_name">Plan Name: </p>
              <p className="pl_name">Purchasing Date: </p>
            </div>
            <div className="plan_details">
              <p className="pl_type">{basicInfo.plan_name}</p>
              <p className="pl_type">{date}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="subs_plan plan_subscription">
        <div className="plan_head">
          <h3>Department</h3>
        </div>
        <div className="col-4 subs_col">
          <div className="mainPl_detail">
            <div className="plan_details">
              <p className="pl_name">Department Limit: </p>
              <p className="pl_name">Department Created:</p>
            </div>
            <div className="plan_details">
              <p className="pl_type">{basicInfo.dept_limit}</p>
            </div>
          </div>
          <div className="col-8 progress_col">
            <div className="Progress_content">
              <ProgressBar now={now} />
            </div>
            <div className="oneofthree">
              <p>{basicInfo.create_department}</p>
              <p>of {basicInfo.dept_limit}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="subs_plan plan_subscription">
        <div className="plan_head">
          <h3>User</h3>
        </div>
        <div className="col-4 subs_col">
          <div className="mainPl_detail">
            <div className="plan_details">
              <p className="pl_name">User Limit: </p>
              <p className="pl_name">User Created: </p>
            </div>
            <div className="plan_details">
              <p className="pl_type">{basicInfo.user_limit}</p>
            </div>
          </div>
          <div className="col-8 progress_col">
            <div className="Progress_content">
              <ProgressBar now={vnow} />
            </div>
            <div className="oneofthree">
              <p>{userInfo.created_users}</p>
              <p>of {basicInfo.user_limit}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyPlanContent;
