import React, { useEffect, useRef, useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite-no-reset.min.css";
import { onDragEnd, onDragStart } from "../helpers/DragHelpers";
import { ReactComponent as Dots } from "../../assets/images/dots.svg";
import { capitalizeFirstLetter } from "../helpers/helper";
import { useTaskContextProvider } from "../../utils/TaskContextProvider";
import SelectedMemberModal from "./SelectMemberModal";
import moment from "moment";
import {
  handleNameEditHelper,
  handleRemoveMemberHelper,
  saveMemberHelper,
} from "../helpers/TaskHelpers";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const DynamicTaskCard = ({
  task,
  selectedDates,
  selectedPriority,
  handleDates,
  handlePrioritySelect,
  handleShowDeleteModal,
  memoptions,
  setIsMember,
  getBoardProjects,
}) => {
  const navigate = useNavigate();
  const { departIdasInt, assignedPermissions } = useTaskContextProvider();
  const [isEditTaskName, setIsEditTaskName] = useState(false);
  const [taskName, setTaskName] = useState(task.task_name);
  const assignedUsers = JSON.parse(task.assigned_users_json);

  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowModal = () => {
    if (assignedPermissions.createTask) {
      setShowModal(true);
    } else {
      toast.error(
        "You don't have permission for this action. Please contact your admin!"
      );
    }
  };

  const [membersData, setMembersData] = useState(assignedUsers);
  const selectVal = membersData?.map((data) => data.user_id);
  const [selectValues, setSelectValues] = useState(selectVal);

  const handleRemoveMember = async (id) => {
    try {
      await handleRemoveMemberHelper(
        id,
        task.id,
        task.pid,
        membersData,
        setSelectValues,
        departIdasInt,
        setMembersData
      );
    } catch (error) {
      console.error("An error occurred in handleRemoveMember:", error);
    }
  };

  const handleCodeMember_ = (memberid) => {
    setSelectValues([memberid]);
  };

  const handleEnterKey = async (e) => {
    if (e.key === "Enter") {
      try {
        await handleNameEditHelper(
          task.id,
          task.pid,
          taskName,
          departIdasInt,
          "",
          setIsEditTaskName
        );
      } catch (error) {
        console.error("An error occurred in handleEnterKey:", error);
      }
    }
  };

  const saveMember = async (tid) => {
    try {
      if (!selectValues || selectValues.length === 0) {
        toast.error("Please select a member.");
        return;
      }

      await saveMemberHelper(
        tid,
        task.pid,
        departIdasInt,
        selectValues,
        setMembersData,
        handleCloseModal
      );
      handleCloseModal();
    } catch (error) {
      console.error("An error occurred in saveMember:", error);
    }
  };

  const handBlur = async () => {
    try {
      await handleNameEditHelper(
        task.id,
        task.pid,
        taskName,
        departIdasInt,
        getBoardProjects,
        setIsEditTaskName
      );
    } catch (error) {
      console.error("An error occurred in handleEnterKey:", error);
    }
  };

  const navigateToMember = () => {
    navigate("/members");
  };

  const navigateToTaskDetail = (taskId) => {
    if (assignedPermissions.taskDetails) {
      navigate(`/task/${taskId}`, { state: { taskId, projectId: task.pid } });
    } else {
      toast.error(
        "You don't have permission for this action. Please contact your admin!"
      );
    }
  };

  const myInputRef = useRef(null);
  useEffect(() => {
    if (isEditTaskName && myInputRef.current) {
      myInputRef.current.focus();
    }
  }, [isEditTaskName]);

  useEffect(() => {
    if (task.task_name) {
      setTaskName(task.task_name);
    }
  }, [task.task_name]);

  return (
    <>
      <div
        className="card"
        key={task.id}
        id={task.id}
        draggable
        onDragStart={(e) => onDragStart(e)}
        onDragEnd={(e) => onDragEnd(e)}
      >
        <div className="card_right">
          <div className="days">
            <p>{task.project_name}</p>

            {membersData === null ? (
              <div className="user-icon" onClick={() => handleShowModal()}>
                <i className="fa fa-user-plus" aria-hidden="true"></i>
              </div>
            ) : (
              <div className="user-info">
                <div className="user-icon" onClick={() => handleShowModal()}>
                  <i className="fa fa-user-plus" aria-hidden="true"></i>
                </div>
                <div className="user-info">
                  {membersData[0].user_id !== "" &&
                    membersData?.map((user) => {
                      return (
                        <>
                          <div className="position-relative">
                            <div className="user-avatar">
                              {user.user_name.charAt(0).toUpperCase()}
                              <div
                                onClick={() => handleRemoveMember(user.user_id)}
                                className="cross_icon-board"
                              >
                                <i className="fa-regular fa-circle-xmark"></i>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                </div>
              </div>
            )}
          </div>
          {isEditTaskName ? (
            <input
              className="form-control"
              placeholder="Task Name"
              value={taskName}
              ref={myInputRef}
              onChange={(e) => setTaskName(e.target.value)}
              onKeyDown={handleEnterKey}
              onBlur={(e) => handBlur(e.target.value, task.id)}
            />
          ) : (
            <div className="time">
              <p onClick={() => navigateToTaskDetail(task.id)}>{taskName}</p>
              {assignedPermissions.createTask && (
                <i
                  className="fa-solid fa-pencil"
                  onClick={() => setIsEditTaskName(true)}
                ></i>
              )}
            </div>
          )}

          {selectedDates !== null ? (
            <div className="dates">
              <p>
                {moment(selectedDates[0]).format("DD/MM/YYYY")} -{" "}
                {moment(selectedDates[1]).format("DD/MM/YYYY")}
              </p>
            </div>
          ) : task.from_date_report !== null && task.to_date_report !== null ? (
            <div className="dates">
              <p>
                {moment(task.from_date_report).format("DD/MM/YYYY")} -{" "}
                {moment(task.to_date_report).format("DD/MM/YYYY")}
              </p>
            </div>
          ) : null}

          {selectedPriority !== "Set Priority" ? (
            <div className="priority">
              {selectedPriority === "Low" ? (
                <i className="green_low fa-solid fa-flag"></i>
              ) : selectedPriority === "Medium" ? (
                <i className="gray_medium fa-solid fa-flag"></i>
              ) : (
                <i className="red_high fa-solid fa-flag"></i>
              )}
              <p>{selectedPriority[task.id]}</p>
            </div>
          ) : task.task_type !== null ? (
            <div className="priority">
              {task.task_type === "low" ? (
                <i className="green_low fa-solid fa-flag"></i>
              ) : task.task_type === "medium" ? (
                <i className="gray_medium fa-solid fa-flag"></i>
              ) : (
                <i className="red_high fa-solid fa-flag"></i>
              )}
              <p>{capitalizeFirstLetter(task.task_type)}</p>
            </div>
          ) : null}
          <div className="fa-icon-container">
            <DateRangePicker
              disabledDate={(date) => date < new Date()}
              onClick={() => setIsMember(task.assigned_users_json)}
              appearance="default"
              placeholder={
                task.from_date_report && task.to_date_report
                  ? task.from_date_report + " to " + task?.to_date_report
                  : "Due Date"
              }
              id={`dateRangePicker_${task.id}`}
              onChange={(dates) => handleDates(dates, task.id, task.pid)}
              value={
                selectedDates ||
                (task.from_date_report && task.to_date_report
                  ? [
                      moment(task.from_date_report).toDate(),
                      moment(task.to_date_report).toDate(),
                    ]
                  : null)
              }
              disabled={!assignedPermissions.createTask}
            />

            {assignedPermissions.createTask && (
              <Dropdown>
                <Dropdown.Toggle className="dropdown-btn" id="dropdown-basic">
                  <i className="fa-regular fa-flag"></i>
                </Dropdown.Toggle>

                <Dropdown.Menu variant="dark">
                  <Dropdown.Item
                    onClick={() =>
                      handlePrioritySelect("Low", task.id, task.pid)
                    }
                  >
                    <i className="green_low fa-solid fa-flag"></i> Low
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      handlePrioritySelect("Medium", task.id, task.pid)
                    }
                  >
                    <i className="gray_medium fa-solid fa-flag"></i> Medium
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      handlePrioritySelect("High", task.id, task.pid)
                    }
                  >
                    <i className="red_high fa-solid fa-flag"></i> High
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
            {assignedPermissions.removeTask && (
              <div className="dropdown justify-content-center">
                <div className="drop_btn">
                  <button
                    className="dots_btn dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <Dots />
                  </button>
                  <ul className="dropdown-menu">
                    <li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() => handleShowDeleteModal(task.id)}
                      >
                        Remove Task
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        size="sm"
        backdrop={true}
        backdropClassName="backdropclass"
      >
        <SelectedMemberModal
          memoptions={memoptions}
          handleCloseModal={handleCloseModal}
          taskId={task.id}
          handleCodeMember={(value, label) =>
            handleCodeMember_(value, label, task.id)
          }
          selectValues={selectValues}
        />
        {memoptions?.length !== 0 ? (
          <div className="btn_divider d-flex justify-content-end">
            <button
              className="request_btn apply_btn btn"
              onClick={() => saveMember(task.id)}
            >
              Save
            </button>
          </div>
        ) : (
          <div className="btn_divider d-flex justify-content-end">
            <button
              style={{
                backgroundColor: "#38ce94",
                border: "1px solid #38ce94",
              }}
              className="request_btn apply_btn btn"
              onClick={navigateToMember}
            >
              Add Member
            </button>
          </div>
        )}
      </Modal>
    </>
  );
};

export default DynamicTaskCard;
