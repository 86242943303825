// Routes.js
import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "../pages/auth/login";
import Dashboard from "../pages/dashboard/dashboard";
import PrivateRoute from "../../src/config/PrivateRoute";
import Signup from "../pages/auth/singup";
import Forget from "../pages/auth/forget";
import Recover from "../pages/auth/recover";
import Plans from "../pages/plans/Plans";
import Members from "../pages/members/Members";
import ProjectsList from "../pages/projects/list";
import Task from "../pages/task/task";
import Team from "../pages/team/Team";
import ProfileInfo from "../pages/profile/ProfileInfo";
import ProjectInsight from "../pages/projects/project_insight";
import Error404 from "../pages/404/Error404";
import SuperAdmin from "../pages/superadmin/SuperAdmin";
import PlanHistory from "../pages/plan/planHistory";
import SetPassword from "../pages/auth/SetPassword";
import Chat from "../pages/chat/Chat";
import ProjectTasks from "../pages/projects/project_tasks";
import MemberTaskList from "../pages/members/MemberTaskList";
import Permissions from "../pages/permissions/Permissions";
import PermissionDenied from "../pages/404/PermissionDenied";
import GoogleCallback from "../pages/callback/GoogleCallBack";
import MoreTasks from "../pages/moretasks/MoreTasks";
import FBcallBack from "../pages/callback/FBcallBack";
import Tested from "../pages/Tested";
import TaskDetailScreen from "../components/task/detail/taskDetailsScreen";

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/invitation/:token" element={<SetPassword />} />
        <Route path="/" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forget-password" element={<Forget />} />
        <Route path="/recover-password" element={<Recover />} />
        <Route path="/404" element={<Error404 />} />
        <Route path="*" element={<Navigate to="/404" />} />
        <Route path="/permission-denied" element={<PermissionDenied />} />
        <Route path="/member-tasks/:memberId" element={<MemberTaskList />} />
        <Route path="/auth/google" element={<GoogleCallback />} />
        <Route path="/auth/facebook" element={<FBcallBack />} />
        <Route path="/permissions" element={<Permissions />} />
        <Route path="/more-tasks" element={<MoreTasks />} />
        <Route path="/test" element={<Tested />} />

        <Route
          path="/dashboard"
          element={
            <PrivateRoute
              allowedRoles={["superadmin", "admin", "sub_admin", "member"]}
            >
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/chat"
          element={
            <PrivateRoute
              allowedRoles={["superadmin", "admin", "sub_admin", "member"]}
            >
              <Chat />
            </PrivateRoute>
          }
        />
        <Route
          path="/plan-history"
          element={
            <PrivateRoute allowedRoles={["admin"]}>
              <PlanHistory />
            </PrivateRoute>
          }
        />
        <Route
          path="/members"
          element={
            <PrivateRoute allowedRoles={["admin", "sub_admin", "member"]}>
              <Members />
            </PrivateRoute>
          }
        />
        <Route
          path="/task/"
          element={
            <PrivateRoute allowedRoles={["sub_admin", "member", "admin"]}>
              <Task />
            </PrivateRoute>
          }
        />

        <Route
          path="/task/:taskId"
          element={
            <PrivateRoute allowedRoles={["sub_admin", "member", "admin"]}>
              <TaskDetailScreen />
            </PrivateRoute>
          }
        />
        <Route
          path="/projects"
          element={
            <PrivateRoute allowedRoles={["sub_admin", "member", "admin"]}>
              <ProjectsList />
            </PrivateRoute>
          }
        />
        <Route
          path="/projects/insights/:projectId"
          element={
            <PrivateRoute allowedRoles={["sub_admin", "member"]}>
              <ProjectInsight />
            </PrivateRoute>
          }
        />
        <Route
          path="/projects/tasks/:projectId"
          element={
            <PrivateRoute allowedRoles={["sub_admin", "member"]}>
              <ProjectTasks />
            </PrivateRoute>
          }
        />
        <Route
          path="/team"
          element={
            <PrivateRoute>
              <Team />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile-info"
          element={
            <PrivateRoute
              allowedRoles={["admin", "sub_admin", "member", "superadmin"]}
            >
              <ProfileInfo />
            </PrivateRoute>
          }
        />
        <Route
          path="/plans"
          element={
            <PrivateRoute allowedRoles={["superadmin"]}>
              <Plans />
            </PrivateRoute>
          }
        />
        <Route
          path="/superadmin"
          element={
            <PrivateRoute allowedRoles={["superadmin"]}>
              <SuperAdmin />
            </PrivateRoute>
          }
        />
        <Route path="/" element={<Navigate to="/dashboard" replace />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
