import React from "react";

function TableHead() {
  return (
    <div>
      <div className="table_head">
        <div className="row">
          <div className="col-lg-2 col-3">
            <p>Name</p>
          </div>
          <div className="col-lg-3 col-3 email_head">
            <p>Email</p>
          </div>
          <div className="col-lg-2 col-3">
            <p>Role</p>
          </div>
          <div className="col-lg-2 col-3">
            <p>Department</p>
          </div>
          <div className="col-lg-3 col-3"></div>
        </div>
      </div>
    </div>
  );
}

export default TableHead;
