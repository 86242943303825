import axios from "axios";
const baseURL = process.env.REACT_APP_API_URL;

export const API = axios.create({
  baseURL: baseURL,
});

export default async function Auth(reqData) {
  const url = "auth/login";
  return handleRequest(url, "post", reqData);
}
export const authSignup = async (reqData) => {
  const url = "auth/register";
  return handleRequest(url, "post", reqData);
};
export const authForget = async (reqData) => {
  const url = "auth/forgotPassword";
  return handleRequest(url, "post", reqData);
};
export const authRecover = async (reqData) => {
  const url = "auth/resetPassword";
  return handleRequest(url, "post", reqData);
};

export const userVerificationData = async (reqData) => {
  const url = "auth/userInvite";
  return handleRequest(url, "post", reqData);
};

export const userVerifyInvite = async (reqData) => {
  const url = "auth/verifyInvite";
  return handleRequest(url, "post", reqData);
};

const handleRequest = async (url, method, reqData = {}, token = null) => {
  try {
    return await API({
      method: method,
      url: url,
      data: reqData,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  } catch (error) {
    throw error;
  }
};
