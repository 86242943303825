import * as yup from "yup";

export const addtaskValidationSchema = yup.object({
  project_id: yup.string().required("Please select a project"),
  member: yup
    .array()
    .required("Please select at least one member")
    .min(1, "Please select at least one member"),
  from_date: yup.string().required("From Date is required"),
  to_date: yup.string().required("To Date is required"),
  short_name: yup.string().required("Title is required"),
  description: yup.string().required("Description is required"),
});
