import React from "react";
import logo from "../../assets/images/logo.webp";
import { Link } from "react-router-dom";
function LoginHeader() {
  return (
    <div>
      <div className="container-fluid">
        <div className="d-flex p-3">
          <Link to={"/login"}>
            <div className="logo d-flex align-items-center">
              <img src={logo} alt="LOGO" />
              <h1 className="ps-2 m-0">Task Definer</h1>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default LoginHeader;
