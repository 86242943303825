import React, { useEffect, useState } from "react";
import "./login.css";
import google from "../../assets/images/google.png";
import facebook from "../../assets/images/facebook.png";
import line from "../../assets/images/line.png";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { authSignup } from "../../api/login";
import LoginHeader from "./login_header";
import Gbutton from "./gbutton";
import { authValidationSchema } from "./auth_validation";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTaskContextProvider } from "../../utils/TaskContextProvider";
import Spinner from "react-bootstrap/Spinner";
import { FacebookAuth, GoogleAuth } from "../../api/social";

function Signup() {
  const navigate = useNavigate();
  const [passwordType, setPasswordType] = useState("password");
  const [passwordTypeA, setPasswordTypeA] = useState("password");
  const { isBtnLoading, setBtnLoading } = useTaskContextProvider();

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    } else {
      setPasswordType("password");
    }
  };
  const togglePasswordA = () => {
    if (passwordTypeA === "password") {
      setPasswordTypeA("text");
      return;
    } else {
      setPasswordTypeA("password");
    }
  };
  let emailCheck = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/i;
  let passwordcheck = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(authValidationSchema),
  });
  async function onSubmit(data) {
    if (!data.comp_email.match(emailCheck)) {
      toast.error("Enter a valid email");
      return false;
    } else if (!data.password.match(passwordcheck)) {
      toast.error(
        "Password must be at least 8 character with combination of uper case,special characterand number"
      );
      return false;
    } else if (data.password !== data.c_password) {
      toast.error("Password not matched");
      return false;
    }
    setBtnLoading(true);

    try {
      const formData = {
        name: data.comp_name,
        email: data.comp_email,
        password: data.password,
        confirm_password: data.c_password,
      };
      const response = await authSignup(formData);
      setBtnLoading(false);

      toast.success(response.data.message);
      navigate("/login");
    } catch (error) {
      setBtnLoading(false);
      toast.error(error.response.data.message);
    }
  }
  const [loginUrl, setLoginUrl] = useState(null);
  const [FBloginUrl, setFBLoginUrl] = useState(null);
  const handleGoogleLogin = async () => {
    try {
      const response = await GoogleAuth();
      setLoginUrl(response.data.url);
      // Handle token storage and redirection to dashboard
    } catch (error) {
      console.error(error.response.data);
    }
  };
  const handleFacebookLogin = async () => {
    try {
      const response = await FacebookAuth();
      setFBLoginUrl(response.data.url);
      // Handle token storage and redirection to dashboard
    } catch (error) {
      console.error(error.response.data);
    }
  };
  useEffect(() => {
    handleFacebookLogin();
    handleGoogleLogin();
  }, []);
  return (
    <div>
      <div className="mian_body pb-4">
        <LoginHeader />
        <div className="container">
          <div className="sign_header col-lg-10 m-auto">
            <div className="heading_">
              <h3>Signup</h3>
            </div>
          </div>
          <ToastContainer />
          <div className="col-lg-6 m-auto">
            <div className="signup_card mt-4">
              <h3>Create Account With</h3>
              <div className="social_icons d-flex justify-content-center">
                <Link to={loginUrl}>
                  <div className="google_icon">
                    <img src={google} alt="GOOGLE" />
                  </div>
                </Link>
                {/* <Link to={FBloginUrl}>
                  <div className="face_icon">
                    <img src={facebook} alt="FACEBOOK" />
                  </div>
                </Link> */}
              </div>
              <div className="line my-2">
                <img src={line} alt="" />
              </div>
              <div className="fields_area">
                <form
                  className="row g-3"
                  onSubmit={handleSubmit(onSubmit)}
                  autoComplete="off"
                >
                  <div className="col-md-12">
                    <label className="form-label">Company Name</label>
                    <input
                      {...register("comp_name")}
                      name="comp_name"
                      type="text"
                      placeholder="Company Name"
                      className="form-control"
                    />
                    {errors.comp_name && (
                      <p className="error text-danger m-0">
                        {errors.comp_name.message}
                      </p>
                    )}
                  </div>
                  <div className="col-md-12">
                    <label className="form-label">Email</label>
                    <input
                      {...register("comp_email")}
                      name="comp_email"
                      type="text"
                      placeholder="yourname@domain.com"
                      className="form-control"
                    />
                    {errors.comp_email && (
                      <p className="error text-danger m-0">
                        {errors.comp_email.message}
                      </p>
                    )}
                  </div>
                  <div className="col-md-12 position-relative passwords">
                    <label className="form-label">Password</label>
                    <input
                      {...register("password")}
                      type={passwordType}
                      name="password"
                      placeholder="Password"
                      className="form-control"
                    />
                    {errors.password && (
                      <p className="error text-danger m-0">
                        {errors.password.message}
                      </p>
                    )}
                    <div className="input-group-btn pass_icon">
                      <span onClick={togglePassword}>
                        {passwordType === "password" ? (
                          <i className="fa-sharp fa-solid fa-eye-slash"></i>
                        ) : (
                          <i className="fa-solid fa-eye"></i>
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-12 position-relative passwords">
                    <label className="form-label">Confirm Password</label>
                    <input
                      {...register("c_password")}
                      type={passwordTypeA}
                      name="c_password"
                      className="form-control"
                      placeholder="Confirm Password"
                    />
                    {errors.c_password && (
                      <p className="error text-danger m-0">
                        {errors.c_password.message}
                      </p>
                    )}
                    <div className="input-group-btn pass_icon">
                      <span onClick={togglePasswordA}>
                        {passwordTypeA === "password" ? (
                          <i className="fa-sharp fa-solid fa-eye-slash"></i>
                        ) : (
                          <i className="fa-solid fa-eye"></i>
                        )}
                      </span>
                    </div>
                  </div>
                  <Gbutton
                    name={
                      !isBtnLoading ? (
                        "Signup"
                      ) : (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )
                    }
                  />
                </form>
              </div>
              <div className="terms">
                <p>
                  By clicking signup you agree to our
                  <span> terms and condition</span> and{" "}
                  <span>privacy policy</span> of using this app.
                </p>
              </div>
              <hr />
              <div className="terms text-center">
                <p>
                  I am already registered{" "}
                  <Link to="/login">
                    <span>Sign in</span>
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Signup;
