import React, { useEffect } from "react";
import "./project_insight.css";
import ProjectInsightBar from "../../components/projects/insight/ProjectInsightBar";
import ProjectOverview from "../../components/projects/insight/ProjectOverview";
import Container from "../../components/layouts/container";
import LeftSide from "../../components/layouts/left_side";
import { useParams } from "react-router-dom";
import { storageData } from "../../components/helpers/helper";
import { getProjectDetail } from "../../api/project";
import { useTaskContextProvider } from "../../utils/TaskContextProvider";
import GeneralSpinner from "../../components/spiner/Spiner";

const ProjectInsight = () => {
  const { departIdasInt, setInsightProject, insightdata, setLoading, loading } =
    useTaskContextProvider();

  const { projectId } = useParams();
  const storageInfo = storageData();

  const checkProjectAccess = async () => {
    setLoading(true);
    const formData = {
      id: storageInfo.data.id,
      department_id: departIdasInt,
      p_id: JSON.parse(projectId),
      company_id: storageInfo.data.company_info.id,
    };
    const getProject = await getProjectDetail(formData, storageInfo.data.token);
    if (getProject.data.data.task_members.length >= 0) {
      setInsightProject(getProject.data.data);
      setLoading(false);
    } else {
      // setLoading(false);
      // navigate("/404");
    }
  };

  useEffect(() => {
    checkProjectAccess();
  }, []);

  return (
    <div>
      <Container>
        <div className="dashboard_main">
          <div className="main_body">
            <LeftSide />
            <div className="right_side w-100">
              <div className="container-fluid">
                <div className="dashboard_inner col-11 m-auto">
                  <ProjectInsightBar />
                  <div className="insight_main mt-3">
                    <div className="row">
                      {loading && insightdata.length === 0 ? (
                        <GeneralSpinner />
                      ) : (
                        <ProjectOverview insightdata={insightdata} />
                        // <ProjectMember />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ProjectInsight;
