import React from "react";
import notfound from "../../assets/images/notfound.webp";

function ImageFunction(props) {
  return (
    <div>
      <div className="d-flex justify-content-center mt-4 found_img">
        <img src={notfound} alt="" />
      </div>
      <div className="notfound_content mt-3">
        <h3>No {props.name} Found</h3>
        <p>{props.line}</p>
      </div>
    </div>
  );
}

export default ImageFunction;
