import React, { useEffect, useState } from "react";
import "../dashboard/dashboard.css";
import LeftSide from "../../components/layouts/left_side";
import Container from "../../components/layouts/container";
import UserDepartments from "../../components/department/user_departments";
import SubscriptionModule from "../../components/subscription/subscription";
import { storageData } from "../../components/helpers/helper";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";
import Analytic from "../analytics/Analytic";
import { useTaskContextProvider } from "../../utils/TaskContextProvider";
import { getActivePlan } from "../../api/plan";
import rolePermissionsHelper from "../../components/helpers/rolePermissionHelper";
import { companyInfoData } from "../../api/profile";
import allDepartments, {
  getCompanyDepartment,
  saveDepartment,
} from "../../api/department";
import { Spinner } from "react-bootstrap";
import { departmentValidationSchema } from "../../components/department/departmentValidationSchema";
import GeneralSpinner from "../../components/spiner/Spiner";
import ImageFunction from "../../components/department/ImageFunction";

function Dashboard() {
  const storageInfo = storageData();
  const userType = storageInfo.data.user_type;
  const {
    activePlan,
    setActivePlan,
    setActivePlanId,
    setActivePlanLimit,
    setAssignedPermissions,
    setBtnLoading,
    setActivePlanUser,
    setLoading,
    loading,
    activePlanId,
    allcompdepartments,
    setcompDepartments,
    isBtnLoading,
  } = useTaskContextProvider();
  const token = storageInfo.data.token;

  const dept_id = localStorage.getItem("u_did");
  const role_id = localStorage.getItem("role_id");
  const [show, setShow] = useState(false);
  const [all_departments, setallDepartments] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const fetchActivePlan = async () => {
    if (userType === "admin" || userType === "member") {
      try {
        const activeResponse = await getActivePlan("", token);
        localStorage.setItem("activePlanStatus", activeResponse.data.success);
        setActivePlanId(activeResponse.data.data.basic_info.id);
        setActivePlanLimit(activeResponse.data.data.basic_info.dept_limit);
        setActivePlanUser(activeResponse.data.data.basic_info.user_limit);
        setActivePlan(true);
      } catch (error) {
        localStorage.setItem("activePlanStatus", false);
        setActivePlan(false);
      }
    }
  };
  const companyData = async () => {
    try {
      const formData = new FormData();
      const response = await companyInfoData(formData, storageInfo.data.token);
      storageInfo.data.company_info.name = response.data.data.name;
      storageInfo.data.company_info.address = response.data.data.address;
      storageInfo.data.company_info.city = response.data.data.city;
      storageInfo.data.company_info.phone_no = response.data.data.phone_no;
      storageInfo.data.company_info.state = response.data.data.state;
      storageInfo.data.company_info.company_logo =
        response.data.data.company_logo;
      localStorage.setItem("c_info", JSON.stringify(storageInfo));
    } catch (error) {}
  };
  useEffect(() => {
    const storedActivePlanStatus = localStorage.getItem("activePlanStatus");
    if (storedActivePlanStatus !== null) {
      setActivePlan(JSON.parse(storedActivePlanStatus));
    }
    getDepatmentList();
    fetchDept();
    fetchActivePlan();
    companyData();
    if (userType === "member") {
      rolePermissionsHelper(
        dept_id,
        role_id,
        token,
        setAssignedPermissions,
        setBtnLoading
      );
    }
  }, []);
  const getDepatmentList = async () => {
    setLoading(true);
    try {
      const getCompanyDept = await getCompanyDepartment();
      setcompDepartments(getCompanyDept.data.data);
      setLoading(false);
      return false;
    } catch (error) {
      setLoading(false);
    }
  };
  const fetchDept = async () => {
    const alldepartments = await allDepartments();
    setallDepartments(alldepartments.data.data);
  };
  // useEffect(() => {
  //   getDepatmentList();
  //   fetchDept();
  // }, []);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(departmentValidationSchema),
  });
  const showDeparts = () => {
    reset();
    handleShow();
  };
  const onSubmit = async (data) => {
    setBtnLoading(true);
    try {
      const formData = {
        name: data.dept_name,
        department_id: data.dept_option,
        plan_id: activePlanId,
      };
      const addDepartment = await saveDepartment(formData);
      getDepatmentList();
      handleClose();
      toast.success(addDepartment.data.message);
      setBtnLoading(false);
      data.dept_name = "";
      data.dept_option = "";
      return false;
    } catch (error) {
      setBtnLoading(false);
      toast.error(error.response.data.message);
    }
  };
  return (
    <div>
      <Container>
        <div className="dashboard_main">
          <div className="main_body">
            <LeftSide />
            <div className="right_side w-100">
              <div className="container-fluid">
                <div className="dashboard_inner col-11 m-auto">
                  {!activePlan ? (
                    <SubscriptionModule fetchActivePlan={fetchActivePlan} />
                  ) : activePlan && userType === "admin" ? (
                    <>
                      <div className="board_header">
                        <div className="main_heading">
                          <h3>All Departments</h3>
                          <p>
                            Here you can see complete list of tasks that you
                            assigned to others in the past
                          </p>
                        </div>
                        <div className="more_time">
                          <button
                            className="btn request_btn w-100"
                            type="button"
                            onClick={showDeparts}
                          >
                            Add Department
                          </button>
                        </div>
                      </div>
                      <div className="cards_main mt-4">
                        <div className="row">
                          {!loading ? (
                            allcompdepartments.length === 0 ? (
                              <ImageFunction
                                name="Department"
                                line="Try to create more department to your employees or"
                              />
                            ) : (
                              allcompdepartments.map((post) => {
                                return (
                                  <UserDepartments
                                    post={post}
                                    allcompdepartments={allcompdepartments}
                                  />
                                );
                              })
                            )
                          ) : (
                            <GeneralSpinner />
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <Analytic />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <div className="model_heading">
            <h1 className="modal-title" id="staticBackdropLabel">
              Add New Department
            </h1>
            <p>Write name of department and select type</p>
          </div>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <label for="validationCustom01" className="form-label">
              Name
            </label>
            <input
              {...register("dept_name")}
              type="text"
              className="form-control"
              placeholder="Department name"
              name="dept_name"
              // onChange={handleCode}
            />
            {errors.dept_name && (
              <p className="error text-danger m-0">
                {errors.dept_name.message}
              </p>
            )}
            <label>Select Department Type</label>
            {errors.dept_option && (
              <p className="error text-danger">{errors.dept_option.message}</p>
            )}
            {all_departments?.map((post, index) => {
              return (
                <div key={index} className="check_bok">
                  <div className="checkbox_wrapper">
                    <input
                      {...register("dept_option")}
                      type="radio"
                      name="dept_option"
                      id={`flexRadioDefault${post.id}`}
                      value={post.id}
                    />
                    <label for={`flexRadioDefault${post.id}`}>
                      {post.name}
                    </label>
                  </div>
                </div>
              );
            })}
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <button
              type="submit"
              className="request_btn apply_btn"
              disabled={isBtnLoading}
            >
              {!isBtnLoading ? (
                "Add"
              ) : (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
}

export default Dashboard;
