import React, { useEffect, useRef, useState } from "react";
import { FaEye } from "react-icons/fa";
import { Link } from "react-router-dom";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useNavigate } from "react-router-dom";
import { DatePicker } from "rsuite";
import { useTaskContextProvider } from "../../utils/TaskContextProvider";
import { toast } from "react-toastify";
import { createProject, getProjects } from "../../api/project";
import { formatDate, storageData } from "../helpers/helper";
import DotsDropdown from "../task/taskmodals/DotsDropdown";
import DeleteTaskModal from "../task/taskmodals/DeleteModal";
import { Dropdown, DropdownButton, Modal, Spinner } from "react-bootstrap";
import moment from "moment";
import api from "../helpers/AccessHelper";

function ProjectList({ post }) {
  const [isEditTaskName, setIsEditTaskName] = useState(false);
  const [taskName, setTaskName] = useState(post.name);
  const [newname, setNewName] = useState("");
  const [loadingTasks, setLoadingTasks] = useState({});
  const [taskid, setTaskID] = useState("");
  const {
    setprojectid,
    departIdasInt,
    projectid,
    setSelectedDates,
    setProjectName,
    setListData,
    setLoading,
    assignedPermissions,
    setSelectedPriority,
  } = useTaskContextProvider();
  const handelPencil = (e) => {
    setIsEditTaskName(true);
    setTaskID(e.target.id);
  };
  const storageInfo = storageData();
  const completionPercentage = (
    (post.project_completed_tasks / post.total_project_tasks) *
    100
  ).toFixed(0);
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);
  const handleShowDeleteModal = () => setShowDeleteModal(true);
  const handelTaskID = (ID) => {
    setprojectid(ID);
  };
  const handleInputBlur = async (e) => {
    if (e.key === "Enter") {
      setIsEditTaskName(false);
      try {
        setLoadingTasks((prevLoadingTasks) => ({
          ...prevLoadingTasks,
          [post.id]: true,
        }));
        const formData = new FormData();
        formData.append("department_id", departIdasInt);
        formData.append("name", taskName);
        formData.append("id", taskid);
        const response = await createProject(
          formData,
          storageInfo?.data?.token
        );

        toast.success(response.data.message);
        setNewName("");
        setLoadingTasks((prevLoadingTasks) => ({
          ...prevLoadingTasks,
          [post.id]: false,
        }));

        return false;
      } catch (error) {
        setLoadingTasks((prevLoadingTasks) => ({
          ...prevLoadingTasks,
          [post.id]: false,
        }));

        if (error?.response?.data?.data === "permission") {
          navigate("/404");
        }
        if (error?.response?.data?.data === "plan") {
          navigate("/dashboard");
        }
      }
    }
  };

  const handBlur = (name, pid) => {
    AddProject(pid, name, null, null, null);
    setIsEditTaskName(false);
  };
  const handlePrioritySelect = (priority, pid, taskname) => {
    setSelectedPriority((prevPriorities) => ({
      ...prevPriorities,
      [pid]: priority,
    }));
    AddProject(pid, taskname, priority);
  };
  async function listofProject() {
    setLoading(true);
    try {
      const proejctFormData = {
        department_id: JSON.parse(departIdasInt),
      };
      const projectData = await getProjects(
        proejctFormData,
        storageInfo.data.token
      );
      const resultProjects = projectData?.data?.data;
      setListData(resultProjects);
      setLoading(false);
    } catch (error) {}
  }

  async function AddProject(pid, taskname, priority, startDate, endDate) {
    setLoadingTasks((prevLoadingTasks) => ({
      ...prevLoadingTasks,
      [post.id]: true,
    }));
    try {
      const formData = new FormData();
      formData.append("department_id", departIdasInt);
      formData.append("name", newname ? newname : taskname);
      formData.append("id", pid === undefined ? projectid : pid);
      if (startDate) {
        formData.append("start_date", startDate);
      }
      if (endDate) {
        formData.append("end_date", endDate);
      }
      if (priority) {
        formData.append("project_type", priority ? priority : "");
      }
      const response = await api.post("project/create-project", formData, {
        headers: {
          Authorization: `Bearer ${storageInfo?.data?.token}`,
        },
      });
      listofProject();
      toast.success(response.data.message);
      setNewName("");
      setLoadingTasks((prevLoadingTasks) => ({
        ...prevLoadingTasks,
        [post.id]: false,
      }));
      return false;
    } catch (error) {
      setLoadingTasks((prevLoadingTasks) => ({
        ...prevLoadingTasks,
        [post.id]: false,
      }));
      if (error?.response?.data?.data === "permission") {
        navigate("/404");
      }
      if (error?.response?.data?.data === "plan") {
        navigate("/dashboard");
      }
    }
  }
  const removeCard = async () => {
    handleShowDeleteModal();
    try {
      const formData = {
        id: projectid,
      };

      const response = await api.post("project/delete", formData, {
        headers: {
          Authorization: `Bearer ${storageInfo?.data?.token}`,
        },
      });

      setListData((prevListData) =>
        prevListData.filter((project) => project.id !== projectid)
      );

      handleCloseDeleteModal();
      toast.success(response.data.message);
      return false;
    } catch (error) {
      if (error?.response?.data?.data === "permission") {
        navigate("/404");
      }
      if (error?.response?.data?.data === "plan") {
        navigate("/dashboard");
      }
    }
  };

  const [starteddate, setStartDate] = useState(null);
  const handleStartDates = (dates, pid, taskname) => {
    setStartDate(dates);
    setSelectedDates((prevDates) => ({
      ...prevDates,
      [pid]: dates,
    }));
    const startDate = dates !== null && formatDate(dates);
    AddProject(pid, taskname, null, startDate, null);
  };
  const [endeddate, setEndDate] = useState(null);

  const handleEndDates = (dates, pid, taskname) => {
    setEndDate(dates);
    setSelectedDates((prevDates) => ({
      ...prevDates,
      [pid]: dates,
    }));
    const endDate = dates !== null && formatDate(dates);
    AddProject(pid, taskname, null, null, endDate);
  };
  const myInputRef = useRef(null);
  useEffect(() => {
    if (isEditTaskName && myInputRef.current) {
      myInputRef.current.focus();
    }
  }, [isEditTaskName]);

  useEffect(() => {
    if (post.name) {
      setTaskName(post.name);
    }
  }, [post]);

  return (
    <>
      {
        !loadingTasks[post.id] ? (
          <div className="project_row">
            <div className="row">
              <div className="col-2  d-flex align-items-center position-relative project_data">
                {isEditTaskName ? (
                  <div className="edit_hide">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Project Name"
                      ref={myInputRef}
                      value={taskName}
                      onChange={(e) => setTaskName(e.target.value)}
                      onKeyDown={handleInputBlur}
                      onBlur={(e) => handBlur(e.target.value, post.id)}
                    />
                  </div>
                ) : (
                  <>
                    <Link
                      onClick={() => setProjectName(post.name)}
                      to={`/projects/tasks/${post.id}`}
                      id={post.id}
                    >
                      <p>{taskName}</p>
                    </Link>

                    {assignedPermissions.createProject && (
                      <div className="edit_options">
                        <div className="pencil_icon">
                          <i
                            className="fa-solid fa-pencil"
                            onClick={handelPencil}
                            id={post.id}
                          ></i>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>

              <div className="col-1 d-flex align-items-center justify-content-center">
                {assignedPermissions.projectInsights ? (
                  <Link to={`/projects/insights/${post.id}`} id={post.id}>
                    <FaEye />
                  </Link>
                ) : (
                  <FaEye />
                )}
              </div>
              <div className="project_date col-2 d-flex justify-content-between align-items-baseline">
                <DatePicker
                  id={`dateRangePicker_${post.id}`}
                  onChange={(dates) =>
                    handleStartDates(dates, post.id, post.name)
                  }
                  value={
                    starteddate || post.start_date
                      ? moment(post.start_date).toDate()
                      : null
                  }
                  disabled={!assignedPermissions.createProject}
                />
              </div>
              <div className="project_date col-2 d-flex justify-content-between align-items-baseline">
                <DatePicker
                  id={`dateRangePicker_${post.id}`}
                  onChange={(dates) =>
                    handleEndDates(dates, post.id, post.name)
                  }
                  value={
                    endeddate || post.end_date
                      ? moment(post.end_date).toDate()
                      : null
                  }
                  disabled={!assignedPermissions.createProject}
                />
              </div>
              <div className="col-2 pt-2">
                <div className="project_progress">
                  <ProgressBar
                    now={
                      completionPercentage === isNaN ? 0 : completionPercentage
                    }
                  />
                  <label>
                    {`${post.project_completed_tasks}/${post.total_project_tasks}`}
                  </label>
                </div>
              </div>

              <div className="col-2 row d-flex align-items-center">
                <div className="col-2 p-0">
                  {assignedPermissions.createProject ? (
                    <DropdownButton
                      key="down"
                      drop="down"
                      title={
                        <i
                          className={
                            post.project_type
                              ? post.project_type === "medium"
                                ? "gray_medium fa-solid fa-flag"
                                : post.project_type === "high"
                                ? "red_high fa-solid fa-flag"
                                : "green_low fa-solid fa-flag"
                              : ""
                          }
                        ></i>
                      }
                      className="down_btn"
                      menuVariant="dark"
                    >
                      <Dropdown.Item
                        onClick={() =>
                          handlePrioritySelect("low", post.id, post.name)
                        }
                      >
                        <i className="green_low fa-solid fa-flag"></i> Low
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() =>
                          handlePrioritySelect(
                            "medium",
                            post.id,

                            post.name
                          )
                        }
                      >
                        <i className="gray_medium fa-solid fa-flag"></i> Medium
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() =>
                          handlePrioritySelect("high", post.id, post.name)
                        }
                      >
                        <i className="red_high fa-solid fa-flag"></i> High
                      </Dropdown.Item>
                    </DropdownButton>
                  ) : (
                    <i
                      className={
                        post.project_type
                          ? post.project_type === "medium"
                            ? "gray_medium fa-solid fa-flag"
                            : post.project_type === "high"
                            ? "red_high fa-solid fa-flag"
                            : "green_low fa-solid fa-flag"
                          : ""
                      }
                    ></i>
                  )}
                </div>
                <div className="col-10">
                  <p
                    className="m-0 d-flex align-items-baseline"
                    style={{ textTransform: "capitalize" }}
                  >
                    {post.project_type}
                  </p>
                </div>
              </div>
              {assignedPermissions.removeProject && (
                <div className="col-1 d-flex align-items-center justify-content-end">
                  <DotsDropdown
                    handleShowDeleteModal={handleShowDeleteModal}
                    task={post}
                    handelTaskID={handelTaskID}
                    name="Project"
                  />
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-center spin_pedding">
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          </div>
        )
        // : (
        //   <GeneralSpinner />
        // )
      }

      <Modal
        show={showDeleteModal}
        onHide={handleCloseDeleteModal}
        backdrop="static"
        keyboard={false}
      >
        <DeleteTaskModal
          handleCloseDeleteModal={handleCloseDeleteModal}
          removeTask={removeCard}
          title="Project"
        />
      </Modal>
    </>
  );
}

export default ProjectList;
