import React from "react";
import { Link } from "react-router-dom";
const ProjectTaskBar = ({ projectlistTask }) => {
  return (
    <div className="board_header">
      <div className="main_heading">
        {projectlistTask.success &&
          Object.entries(projectlistTask.data).map(
            ([projectName, projectData]) => <h3>{projectName}</h3>
          )}
        <p>Let's see your project's tasks based on their status</p>
      </div>
      <div className="more_time">
        <Link className="btn request_btn w-100" to="/projects">
          Back to projects
        </Link>
      </div>
    </div>
  );
};
export default ProjectTaskBar;
