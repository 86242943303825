import React, { useEffect, useRef, useState } from "react";
import "../../components/department/department.css";
import ProgressBar from "react-bootstrap/ProgressBar";
import "react-circular-progressbar/dist/styles.css";
import ImageFunction from "../../components/department/ImageFunction";
import { useTaskContextProvider } from "../../utils/TaskContextProvider";
import GeneralSpinner from "../spiner/Spiner";
import { editTmpDepartment } from "../../api/department";
import { storageData } from "../helpers/helper";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";

const UserDepartments = ({ post }) => {
  const {
    selectedDepartmentId,
    setSelectedDepartmentId,
    loading,
    planuser,
    allcompdepartments,
  } = useTaskContextProvider();
  const [isEditTaskName, setIsEditTaskName] = useState(false);
  const [taskName, setTaskName] = useState(post.tmp_department_name);
  const storageInfo = storageData();
  const [loadingTasks, setLoadingTasks] = useState({});

  const isSelected = post.user_department_id === selectedDepartmentId;
  const totaluser = planuser / allcompdepartments.length;
  const totalfixuser = totaluser.toFixed(0);
  const users = (post.total_users / totalfixuser) * 100;
  const fixuser = users.toFixed(0);
  const projects = (post.total_projects / 100) * 100;
  const myInputRef = useRef(null);
  const handleInputBlur = async (e) => {
    if (e.key === "Enter") {
      if (!taskName.trim()) {
        toast.error("Please enter department name");
        return;
      }

      setLoadingTasks((prevLoadingTasks) => ({
        ...prevLoadingTasks,
        [post.user_department_id]: true,
      }));

      try {
        const formData = {
          tmp_name: taskName,
          id: post.user_department_id,
        };
        const getCompanyDept = await editTmpDepartment(
          formData,
          storageInfo.data.token
        );
        setUpdatedDepart(getCompanyDept.data.data.tmp_department_name);
        setIsEditTaskName(false);
        setLoadingTasks((prevLoadingTasks) => ({
          ...prevLoadingTasks,
          [post.user_department_id]: false,
        }));
        return false;
      } catch (error) {
        setLoadingTasks((prevLoadingTasks) => ({
          ...prevLoadingTasks,
          [post.user_department_id]: false,
        }));
      }
    }
  };
  const [updatedDepart, setUpdatedDepart] = useState("");

  const handBlur = async () => {
    if (!taskName.trim()) {
      toast.error("Please enter department name");
      return;
    }
    setLoadingTasks((prevLoadingTasks) => ({
      ...prevLoadingTasks,
      [post.user_department_id]: true,
    }));
    try {
      const formData = {
        tmp_name: taskName,
        id: post.user_department_id,
      };
      const getCompanyDept = await editTmpDepartment(
        formData,
        storageInfo.data.token
      );
      setUpdatedDepart(getCompanyDept.data.data.tmp_department_name);
      setIsEditTaskName(false);
      setLoadingTasks((prevLoadingTasks) => ({
        ...prevLoadingTasks,
        [post.user_department_id]: false,
      }));
      return false;
    } catch (error) {
      setLoadingTasks((prevLoadingTasks) => ({
        ...prevLoadingTasks,
        [post.user_department_id]: false,
      }));
    }
  };

  useEffect(() => {
    if (isEditTaskName && myInputRef.current) {
      myInputRef.current.focus();
    }
  }, [isEditTaskName]);
  return (
    <div
      key={post.id}
      className={`col-lg-4 col-md-4 col-sm-6 mt-4 ${
        isSelected ? "selected" : ""
      }`}
      onClick={() => setSelectedDepartmentId(post.user_department_id)}
    >
      {/* {!loading ? (
        allcompdepartments.length === 0 ? (
          <ImageFunction
            name="Department"
            line="Try to create more department to your employees or"
          />
        ) : ( */}
      {!loadingTasks[post.user_department_id] ? (
        <div>
          <div className="depart_card tasktable_data">
            <div className="card_heading">
              <div className="d-flex align-items-center position-relative mb-2">
                {isEditTaskName ? (
                  <div className="edit_hide">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Department Name"
                      ref={myInputRef}
                      value={taskName}
                      onChange={(e) => setTaskName(e.target.value)}
                      onKeyDown={handleInputBlur}
                      onBlur={handBlur}
                    />
                  </div>
                ) : (
                  <>
                    <h3>
                      {updatedDepart ? updatedDepart : post.tmp_department_name}
                    </h3>
                    <div
                      className="edit_options"
                      style={{ marginTop: "-0.5rem" }}
                    >
                      <div className="pencil_icon">
                        <i
                          className="fa-solid fa-pencil"
                          onClick={() => setIsEditTaskName(true)}
                          id={post.user_department_id}
                        ></i>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="depart_tag col-5  mb-3">
                <p>{post.name}</p>
              </div>
              <div className="user_progress mb-3">
                <div className="oneofthree">
                  <label>Users</label>
                  <p>
                    {post.total_users != null ? post.total_users : "0"}
                    <span>/{totalfixuser}</span>
                  </p>
                </div>
                <div className="Progress_content mb-3">
                  <ProgressBar
                    now={fixuser}
                    label={`${fixuser}%`}
                    visuallyHidden
                  />
                </div>
              </div>
              {/* <div className="space_progress mb-3">
                          <div className="oneofthree">
                            <label>Space</label>
                            <p>
                              {post.total_space != null
                                ? post.total_space
                                : "0"}
                              <span>/60</span>
                            </p>
                          </div>
                          <div className="Progress_content mb-3">
                            <ProgressBar
                              now={now}
                              label={`${now}%`}
                              visuallyHidden
                              variant="danger"
                            />
                          </div>
                        </div> */}
              <div className="card_progress">
                <div className="oneofthree">
                  <label>Projects</label>
                  <p>
                    {post.total_projects != null ? post.total_projects : "0"}
                    <span>/100</span>
                  </p>
                </div>
                <div className="Progress_content mb-3">
                  <ProgressBar
                    now={projects}
                    label={`${projects}%`}
                    visuallyHidden
                    variant="warning"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-center spin_pedding align-items-center h-100">
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        </div>
      )}
      {/* //   )
      // ) : (
      //   <GeneralSpinner />
      // )} */}
    </div>
  );
};

export default UserDepartments;
