import axios from "axios";
const baseURL = process.env.REACT_APP_API_URL;
export const API = axios.create({
  baseURL: baseURL,
});
export const getProjects = async (reqData, token) => {
  const url = "project/get-list";
  return handleRequest(url, "post", reqData, token);
};
export const createProject = async (reqData, token) => {
  const url = "project/create-project";
  return handleRequest(url, "post", reqData, token);
};
export const editProject = async (reqData, token) => {
  const url = "project/edit";
  return handleRequest(url, "post", reqData, token);
};
export const projectRemove = async (reqData, token) => {
  const url = "project/delete";
  return handleRequest(url, "post", reqData, token);
};
export const getProjectDetail = async (reqData, token) => {
  const url = "project/insight";
  return handleRequest(url, "post", reqData, token);
};
const handleRequest = async (url, method, reqData = {}, token = null) => {
  try {
    return await API({
      method: method,
      url: url,
      data: reqData,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  } catch (error) {
    throw error;
  }
};
