import React from "react";
import "./test.css";
import logo from "../assets/images/logo1.webp";

function Tested() {
  return (
    <table
      width="100%"
      cellSpacing="0"
      cellPadding="0"
      border="0"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <tbody>
        <tr>
          <td align="center" valign="top">
            <table width="600" cellSpacing="0" cellPadding="0" border="0">
              <tbody
                style={{
                  background: "#fff",
                  borderRadius: "20px",
                  border: "2px solid #ff6464",
                  padding: "20px",
                  display: "table-caption",
                }}
              >
                <tr>
                  <td
                    align="start"
                    valign="top"
                    style={{ padding: "32px 0px" }}
                  >
                    <a href="/dashboard">
                      <img src={logo} alt="LOGO" />
                    </a>
                  </td>
                </tr>
                <tr>
                  <td align="start" valign="top">
                    <h6>Hi Saad</h6>
                  </td>
                </tr>
                <tr>
                  <td align="start" valign="top">
                    <p>We have received a request to reset your password.</p>
                  </td>
                </tr>
                <tr>
                  <td align="start" valign="top">
                    <p>Your OTP code is here:</p>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "20px 0",
                    }}
                  >
                    <h2
                      style={{
                        background: "#ff6464",
                        margin: "0",
                        color: "#fff",
                        borderRadius: "10px",
                        padding: "5px 20px",
                      }}
                    >
                      1 2 3 4
                    </h2>
                  </td>
                </tr>
                <tr>
                  <td align="start">
                    <p>This code will expire in 10 mintues</p>
                  </td>
                </tr>
                <tr>
                  <td align="start">
                    <p>
                      If you did not request this email, please contact an
                      administration
                    </p>
                  </td>
                </tr>
                <tr>
                  <td align="start">
                    <a
                      href="admin@taskdefiner.com"
                      style={{ textDecoration: "underline" }}
                    >
                      admin@taskdefiner.com
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default Tested;
