import React, { useState } from "react";
import "./login.css";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { authRecover } from "../../api/login";
import Gbutton from "./gbutton";
import LoginHeader from "./login_header";
import { recoverValidationSchema } from "./auth_validation";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTaskContextProvider } from "../../utils/TaskContextProvider";
import Spinner from "react-bootstrap/Spinner";

function Recover() {
  const navigate = useNavigate();
  const { isBtnLoading, setBtnLoading } = useTaskContextProvider();

  const [passwordType, setPasswordType] = useState("password");
  const [passwordTypeA, setPasswordTypeA] = useState("password");
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    } else {
      setPasswordType("password");
    }
  };
  const togglePasswordA = () => {
    if (passwordTypeA === "password") {
      setPasswordTypeA("text");
      return;
    } else {
      setPasswordTypeA("password");
    }
  };

  let passwordcheck = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm;
  let emailCheck = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/i;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(recoverValidationSchema),
  });
  const onSubmited = async (data) => {
    if (!data.recover_email.match(emailCheck)) {
      toast.error("Enter a valid email");
      return false;
    } else if (!data.recover_password.match(passwordcheck)) {
      toast.error(
        "Password must be at least 8 character with combination of uper case,special characterand number"
      );
      return false;
    } else if (data.recover_password !== data.recover_conf_password) {
      toast.error("Password not matched");
      return false;
    }
    setBtnLoading(true);
    try {
      const formData = {
        email: data.recover_email,
        code: data.recover_code,
        password: data.recover_password,
        confirm_password: data.recover_conf_password,
      };
      const response = await authRecover(formData);
      setBtnLoading(false);

      toast.success(response.data.message);
      navigate("/login");
    } catch (error) {
      setBtnLoading(false);

      toast.error(error.response.data.message);
    }
  };
  return (
    <div>
      <div className="mian_body pb-4">
        <LoginHeader />
        <ToastContainer />
        <div className="container">
          <div className="sign_header col-lg-10 m-auto">
            <div className="heading_">
              <h3>Reset Password</h3>
            </div>
          </div>
          <div className="col-lg-6 m-auto">
            <div className="signup_card mt-4">
              <div className="fields_area">
                <form className="row g-3" onSubmit={handleSubmit(onSubmited)}>
                  <div className="col-md-12">
                    <label className="form-label">Email</label>
                    <input
                      {...register("recover_email")}
                      name="recover_email"
                      className="form-control"
                      type="text"
                      placeholder="yourname@domain.com"
                    />
                    {errors.recover_email && (
                      <p className="error text-danger m-0">
                        {errors.recover_email.message}
                      </p>
                    )}
                  </div>
                  <div className="col-md-12">
                    <label className="form-label">Code</label>
                    <input
                      {...register("recover_code")}
                      name="recover_code"
                      className="form-control"
                      type="text"
                      placeholder="Enter your code"
                    />
                    {errors.recover_code && (
                      <p className="error text-danger m-0">
                        {errors.recover_code.message}
                      </p>
                    )}
                  </div>
                  <div className="position-relative col-md-12 passwords">
                    <label className="form-label">Password</label>
                    <input
                      {...register("recover_password")}
                      name="recover_password"
                      type={passwordType}
                      placeholder="Password"
                      className="form-control"
                    />
                    {errors.recover_password && (
                      <p className="error text-danger m-0">
                        {errors.recover_password.message}
                      </p>
                    )}
                    <div className="input-group-btn pass_icon">
                      <span onClick={togglePassword}>
                        {passwordType === "password" ? (
                          <i className="fa-sharp fa-solid fa-eye-slash"></i>
                        ) : (
                          <i className="fa-solid fa-eye"></i>
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="position-relative col-md-12 passwords">
                    <label className="form-label">Confirm Password</label>
                    <input
                      {...register("recover_conf_password")}
                      name="recover_conf_password"
                      type={passwordTypeA}
                      className="form-control"
                      placeholder="Confirm Password"
                    />
                    {errors.recover_conf_password && (
                      <p className="error text-danger m-0">
                        {errors.recover_conf_password.message}
                      </p>
                    )}
                    <div className="input-group-btn pass_icon">
                      <span onClick={togglePasswordA}>
                        {passwordTypeA === "password" ? (
                          <i className="fa-sharp fa-solid fa-eye-slash"></i>
                        ) : (
                          <i className="fa-solid fa-eye"></i>
                        )}
                      </span>
                    </div>
                  </div>
                  <Gbutton
                    name={
                      !isBtnLoading ? (
                        "Change Password"
                      ) : (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )
                    }
                  />
                </form>
                <div className="back_login">
                  <p>
                    Back to{" "}
                    <span>
                      <Link to="/login">Sign In</Link>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Recover;
