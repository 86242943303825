import axios from "axios";
const baseURL = process.env.REACT_APP_API_URL;
export const API = axios.create({
  baseURL: baseURL,
});
export const GoogleAuth = async (reqData) => {
  const url = "auth/google/redirect";
  return handleRequest(url, "get", reqData);
};
export const FacebookAuth = async (reqData) => {
  const url = "auth/facebook/redirect";
  return handleRequest(url, "get", reqData);
};

const handleRequest = async (url, method, reqData = {}, token = null) => {
  try {
    return await API({
      method: method,
      url: url,
      data: reqData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    throw error;
  }
};
