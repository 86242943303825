import React, { useState } from "react";
import { useTaskContextProvider } from "../../utils/TaskContextProvider";
import Select from "react-select";
import { useParams } from "react-router-dom";
import { addNewTaskHelper } from "../helpers/TaskHelpers";
import { toast } from "react-toastify";

const NewTaskCard = ({
  statusId,
  getBoardProjects,
  setDivs,
  projectIdTask,
}) => {
  const { projectId } = useParams();
  const projectIdParams = projectId;

  const [selectedProject, setSelectedProject] = useState("");
  const [selectedProjectValue, setSelectedProjectValue] = useState(projectId);
  const [taskName, setTaskName] = useState("");
  const { projectlistForTasks, departIdasInt } = useTaskContextProvider();
  const proArr = [];
  projectlistForTasks.forEach((mem) => {
    if (mem.id === projectIdTask || projectIdTask === null) {
      proArr.push({ value: `${mem.id}`, label: `${mem.project_name}` });
    }
  });

  const proptions = proArr;
  const handleSelectProject = (event) => {
    setSelectedProject(event);
    setSelectedProjectValue(event.value);
  };

  const addNewTask = async () => {
    try {
      if (!taskName || !selectedProjectValue) {
        toast.error("Please provide task name and select a project.");
        return;
      }

      await addNewTaskHelper(
        projectIdParams,
        selectedProjectValue,
        departIdasInt,
        taskName,
        statusId,
        getBoardProjects,
        setDivs
      );
    } catch (error) {
      console.error("An error occurred in addNewTask:", error);
    }
  };

  return (
    <div className="order small-box-add">
      <div className="order small-box-add">
        <section className="drag_container">
          <div className="container">
            <div className="drag_column">
              <div className="drag_row">
                <div className="card" key="followerscart" id="6">
                  <div className="card_right">
                    {projectIdParams === undefined && (
                      <>
                        <label className="form-label">Choose Project</label>
                        <Select
                          options={proptions}
                          value={selectedProject}
                          onChange={handleSelectProject}
                        />
                      </>
                    )}

                    <input
                      className="form-control mt-2"
                      placeholder="Task Name"
                      onChange={(e) => setTaskName(e.target.value)}
                    />
                    <div className="d-flex justify-content-end mt-3">
                      <button
                        className="cancel_btn"
                        onClick={() => setDivs([])}
                        style={{ marginRight: "10px" }}
                      >
                        Cancel
                      </button>
                      <button className="save_btn" onClick={addNewTask}>
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default NewTaskCard;
