import axios from "axios";
const baseURL = process.env.REACT_APP_API_URL;
export const API = axios.create({
  baseURL: baseURL,
});

export const GetTasksForBoard = async (reqData, token) => {
  const url = "task/boards-project";
  return handleRequest(url, "post", reqData, token);
};

export const GetProjectTasks = async (reqData, token) => {
  const url = "task/task-projects";
  return handleRequest(url, "post", reqData, token);
};

export const GetTasksList = async (reqData, token) => {
  const url = "task/project-task";
  return handleRequest(url, "post", reqData, token);
};

export const postComments = async (reqData, token) => {
  const url = "task/save-comments";
  return handleRequest(url, "post", reqData, token);
};

export const getComments = async (reqData, token) => {
  const url = "task/comment-task";
  return handleRequest(url, "post", reqData, token);
};

export const getCommentsReply = async (reqData, token) => {
  const url = "task/reply-comments";
  return handleRequest(url, "post", reqData, token);
};

export const deleteComment = async (reqData, token) => {
  const url = "task/delete-comment";
  return handleRequest(url, "post", reqData, token);
};

export const postAttachments = async (reqData, token) => {
  const url = "task/attachment";
  return handleRequest(url, "post", reqData, token);
};

export const getAttachments = async (reqData, token) => {
  const url = "task/attachment-list";
  return handleRequest(url, "post", reqData, token);
};

export const deleteAttachments = async (reqData, token) => {
  const url = "task/attachment-delete";
  return handleRequest(url, "post", reqData, token);
};

export const GetProjectsForTasks = async (reqData, token) => {
  const url = "task/task-projects";
  return handleRequest(url, "post", reqData, token);
};

export const GetMembersForTasks = async (reqData, token) => {
  const url = "member/all-members";
  return handleRequest(url, "post", reqData, token);
};
export const GetProjectsAndTasks = async (reqData, token) => {
  const url = "task/project-task-data";
  return handleRequest(url, "post", reqData, token);
};
export const getTasksDetailsForEdit = async (reqData, token) => {
  const url = "task/edit-task";
  return handleRequest(url, "post", reqData, token);
};

export const createStatus = async (reqData, token) => {
  const url = "task/create-status";
  return handleRequest(url, "post", reqData, token);
};

export const extendTime = async (reqData, token) => {
  const url = "task/more-request-time";
  return handleRequest(url, "post", reqData, token);
};
export const activeLog = async (reqData, token) => {
  const url = "task/activity-log";
  return handleRequest(url, "post", reqData, token);
};
///////////////////////previous//////////////////
export const getMembers = async (reqData, token) => {
  const url = "task/dept-member";
  return handleRequest(url, "post", reqData, token);
};
export const createTask = async (reqData, token) => {
  const url = "task/create-task";
  return handleRequest(url, "post", reqData, token);
};
export const createTaskStatus = async (reqData, token) => {
  const url = "task/create-status";
  return handleRequest(url, "post", reqData, token);
};

export const deleteTaskStatus = async (reqData, token) => {
  const url = "task/delete-status";
  return handleRequest(url, "post", reqData, token);
};
export const getAssignTasks = async (reqData, token) => {
  const url = "task/get-assign-task";
  return handleRequest(url, "post", reqData, token);
};
export const getTaskDetails = async (reqData, token) => {
  const url = "task/task-details";
  return handleRequest(url, "post", reqData, token);
};

export const deleteTask = async (reqData, token) => {
  const url = "task/remove-task";
  return handleRequest(url, "post", reqData, token);
};

export const memberTaskList = async (reqData, token) => {
  const url = "member/task-list";
  return handleRequest(url, "post", reqData, token);
};
//////////////////////// Checklist ////////////////////////

export const getChecklist = async (reqData, token) => {
  const url = "task/check-list";
  return handleRequest(url, "post", reqData, token);
};

export const createChecklist = async (reqData, token) => {
  const url = "task/create-check-list";
  return handleRequest(url, "post", reqData, token);
};

export const deleteChecklist = async (reqData, token) => {
  const url = "task/delete-check-list";
  return handleRequest(url, "post", reqData, token);
};
export const DeleteTasksStatus = async (reqData, token) => {
  const url = "task/delete-status";
  return handleRequest(url, "post", reqData, token);
};
export const GetProjectStatusList = async (reqData, token) => {
  const url = "task/project-status-list";
  return handleRequest(url, "post", reqData, token);
};
const handleRequest = async (url, method, reqData = {}, token = null) => {
  try {
    return await API({
      method: method,
      url: url,
      data: reqData,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  } catch (error) {
    throw error;
  }
};
