import React, { useEffect, useState } from "react";
import { capitalizeFirstLetter, storageData } from "../helpers/helper";
import { createTask, getTaskDetails } from "../../api/task";
import { useTaskContextProvider } from "../../utils/TaskContextProvider";
import { toast } from "react-toastify";
import { DateRangePicker, Dropdown, Modal } from "rsuite";
import {
  createTaskHelper,
  getTaskDetailsHelper,
  handleDatesHelper,
  handlePrioritySelectHelper,
} from "../helpers/TaskHelpers";
import { Link } from "react-router-dom";
import moment from "moment";

const TaskDetails = ({ taskDetails, handleCloseTaskDetails }) => {
  const storageInfo = storageData();
  const {
    departIdasInt,
    setLoading,
    setTaskDetails,
    assignedPermissions,
    memberlistForTasks,
  } = useTaskContextProvider();
  const [isEditTaskPriority, setIsEditTaskPriority] = useState(false);
  const [description, setDescription] = useState(taskDetails?.task_description);
  const [priority, setPriority] = useState("");
  const [members, setMembers] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [isEditMember, setIsEditMember] = useState(false);

  const [isEditDate, setIsEditDate] = useState(false);
  const [selectedDates, setSelectedDates] = useState({});

  const [isMember, setIsMember] = useState(null);

  const handleDates = async (dates, taskId, projectId) => {
    try {
      setSelectedDates(dates);
      await handleDatesHelper(
        dates,
        taskId,
        projectId,
        departIdasInt,
        isMember,
        "",
        ""
      );
      const taskDetailsPromise = getTaskDetailsHelper(taskId, projectId);
      const [taskDetailsData] = await Promise.all([taskDetailsPromise]);
      setTaskDetails(taskDetailsData);
      setIsEditDate(false);
    } catch (error) {
      console.error("An error occurred in handleDates:", error);
    }
  };
  const memArr = [];
  memberlistForTasks.forEach((mem) => {
    memArr.push({
      value: `${mem.id}`,
      label: `${mem.full_name}`,
      email: `${mem.email}`,
      selected: false,
    });
  });
  const memoptions = memArr;

  const handleEnterKey = async (e, taskId, projectId) => {
    if (e.key === "Enter") {
      const formData = {
        id: taskId,
        project_id: projectId,
        department_id: departIdasInt,
        task_description: description,
      };

      const response = await createTask(formData, storageInfo.data.token);

      const formData_ = {
        task_id: taskId,
        project_id: projectId,
      };

      const taskDetailsData = await getTaskDetails(
        formData_,
        storageInfo.data.token
      );
      setLoading(true);
      setTaskDetails(taskDetailsData.data);
      toast.success(response.data.message);
      setLoading(false);
    }
  };

  const handlePriorityChange = async (value) => {
    setPriority(value);
    setIsEditTaskPriority(false);
    try {
      const response = await handlePrioritySelectHelper(
        value,
        taskDetails.id,
        taskDetails.pid,
        departIdasInt,
        createTask
      );

      toast.success(response.message);
    } catch (error) {
      console.error("An error occurred in handlePrioritySelect:", error);
    }
  };

  const handleMemberSelect = async (member) => {
    setSelectedMember(member);
    try {
      const formData = {
        id: taskDetails.id,
        project_id: taskDetails.pid,
        department_id: departIdasInt,
        member: JSON.stringify([member.value]),
      };

      const response = await createTaskHelper(formData);
      toast.success(response.data.message);

      const formData_ = {
        task_id: taskDetails.id,
        project_id: taskDetails.pid,
      };
      const taskDetailsData = await getTaskDetails(
        formData_,
        storageInfo.data.token
      );
      setLoading(true);
      setTaskDetails(taskDetailsData.data);
      setIsEditMember(false);
      setLoading(false);
    } catch (error) {
      console.error("An error occurred while updating task details:", error);
      toast.error("Failed to update task details");
    }
  };

  useEffect(() => {
    if (taskDetails?.task_type !== undefined) {
      setPriority(taskDetails?.task_type);
    }
    if (
      taskDetails.assigned_users_json !== null &&
      typeof taskDetails.assigned_users_json === "string"
    ) {
      const assignedUsers = JSON.parse(taskDetails.assigned_users_json);
      setMembers(assignedUsers);
      if (assignedUsers.length > 0) {
        setSelectedMember(assignedUsers[0]);
      }
    }
  }, [taskDetails]);

  return (
    <>
      <div className="row">
        <div className="col-lg-2 col-6">
          <div className="detail_points">
            <p>Project:</p>
            <p>Assigned By:</p>
          </div>
        </div>
        <div className="col-lg-2 col-6">
          <div className="detail_points_data">
            <p>{taskDetails?.project_name}</p>
            {taskDetails?.Assigned_by ? (
              <p>{taskDetails?.Assigned_by}</p>
            ) : (
              <div className="periority">
                <p style={{ paddingLeft: "0px" }}>None</p>
              </div>
            )}
          </div>
        </div>
        <div className="col-lg-2 col-6">
          <div className="detail_points">
            <p>Priority:</p>
            <p>Assigned To:</p>
          </div>
        </div>
        <div className="col-lg-2 col-6">
          <div className="detail_points_data">
            <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
              {taskDetails?.task_type ? (
                <div
                  className="periority"
                  style={{ display: "flex", gap: "10px" }}
                >
                  {isEditTaskPriority ? (
                    <Dropdown
                      title={` ${capitalizeFirstLetter(priority)}`}
                      placement="bottomStart"
                      onSelect={handlePriorityChange}
                      style={{ marginTop: "-0.5rem", marginBottom: "0.5rem" }}
                    >
                      <Dropdown.Item eventKey="low">Low</Dropdown.Item>
                      <Dropdown.Item eventKey="medium">Medium</Dropdown.Item>
                      <Dropdown.Item eventKey="high">High</Dropdown.Item>
                    </Dropdown>
                  ) : (
                    <div
                      className="periority"
                      style={{ display: "flex", gap: "10px" }}
                    >
                      <div
                        className={
                          priority === "high"
                            ? "high_priority"
                            : priority === "medium"
                            ? "medium_priority"
                            : priority === "low"
                            ? "low_priority"
                            : ""
                        }
                      ></div>
                      <p>{capitalizeFirstLetter(priority)}</p>
                      {assignedPermissions.createTask && (
                        <i
                          className="fa-solid fa-pencil"
                          onClick={() => setIsEditTaskPriority(true)}
                        ></i>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <div className="periority">
                  <p style={{ paddingLeft: "0px" }}>None</p>
                </div>
              )}
            </div>

            <div className="assigned-members">
              {!isEditMember ? (
                taskDetails.assigned_users_json !== null ? (
                  <>
                    {members?.map((user) => (
                      <div key={user.user_id} className="assigned-member">
                        <div className="user-avatar">
                          <div className="position-relative margen-set">
                            <Link to={`/member-tasks/${btoa(user.user_id)}`}>
                              <div
                                className="initial-avatar"
                                style={{ fontSize: "12px" }}
                              >
                                {user.user_name.charAt(0).toUpperCase()}
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    ))}
                    {assignedPermissions.createTask && (
                      <i
                        className="fa-solid fa-pencil"
                        onClick={() => setIsEditMember(true)}
                      ></i>
                    )}
                  </>
                ) : (
                  <>
                    <div className="periority" style={{ marginTop: "-0.5rem" }}>
                      <p style={{ paddingLeft: "0px" }}>None</p>
                    </div>
                  </>
                )
              ) : (
                <Dropdown
                  title={`Select Member`}
                  placement="bottomStart"
                  style={{ marginTop: "-0.5rem", marginBottom: "0.5rem" }}
                >
                  {memoptions.map((data) => {
                    return (
                      <Dropdown.Item
                        eventKey={data.value}
                        onClick={() => handleMemberSelect(data)}
                      >
                        {data.email}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown>
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-1 col-6">
          <div className="detail_points">
            <p>Dates:</p>
          </div>
        </div>
        <div className="col-lg-3 col-6">
          <div className="detail_points_data">
            {isEditDate ? (
              <DateRangePicker
                disabledDate={(date) => date < new Date()}
                onClick={() => setIsMember(taskDetails.assigned_users_json)}
                appearance="default"
                placeholder={
                  taskDetails.from_date_report && taskDetails.to_date_report
                    ? taskDetails.from_date_report +
                      " to " +
                      taskDetails?.to_date_report
                    : "Due Date"
                }
                id={`dateRangePicker_${taskDetails.id}`}
                onChange={(dates) =>
                  handleDates(dates, taskDetails.id, taskDetails.pid)
                }
                value={
                  selectedDates ||
                  (taskDetails.from_date_report && taskDetails.to_date_report)
                    ? [
                        moment(taskDetails.from_date_report).toDate(),
                        moment(taskDetails.to_date_report).toDate(),
                      ]
                    : null
                }
                disabled={!assignedPermissions.createTask}
              />
            ) : (
              <div
                className="periority"
                style={{ display: "flex", gap: "10px" }}
              >
                {taskDetails?.from_date_report ? (
                  <p>
                    {taskDetails?.from_date_report &&
                    taskDetails?.to_date_report
                      ? taskDetails?.from_date_report +
                        " to " +
                        taskDetails?.to_date_report
                      : "None"}
                  </p>
                ) : (
                  <div className="periority">
                    <p style={{ paddingLeft: "0px" }}>None</p>
                  </div>
                )}
                {assignedPermissions.createTask && (
                  <i
                    style={{ marginTop: "-0.5rem" }}
                    className="fa-solid fa-pencil"
                    onClick={() => setIsEditDate(true)}
                  ></i>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <hr />
      <div className="detail_discription">
        <div className="desc-container">
          <h6>Description</h6>
        </div>
        {!assignedPermissions.createTask ? (
          <p>
            {taskDetails?.task_description
              ? taskDetails?.task_description
              : "None"}
          </p>
        ) : (
          <textarea
            name="description"
            rows="10"
            className="text_box"
            value={description}
            placeholder="Add Description"
            onChange={(e) => setDescription(e.target.value)}
            onKeyDown={(e) =>
              handleEnterKey(e, taskDetails.id, taskDetails.pid)
            }
          />
        )}
      </div>
    </>
  );
};

export default TaskDetails;
