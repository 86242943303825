import React from "react";
import { useLocation } from "react-router-dom";
import Header from "../layouts/header";

export default function Container(props) {
  const { pathname } = useLocation();

  React.useEffect(() => {
    document.body.style = "";
  }, [pathname]);

  return (
    <div className="page__wrap">
      <Header />
      <div className="page__content">{props.children}</div>
    </div>
  );
}
