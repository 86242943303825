import React, { useEffect, useState } from "react";
import { Form, Modal, Spinner } from "react-bootstrap";
import Select from "react-select";
import { storageData } from "../../helpers/helper";
import { useTaskContextProvider } from "../../../utils/TaskContextProvider";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { addtaskValidationSchema } from "../TaskValidation";
import { createTask } from "../../../api/task";

const TaskModal = ({ checkDeptAccess }) => {
  const storageInfo = storageData();
  const baseURL = process.env.REACT_APP_URL;
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPriority, setSelectedPriority] = useState("low");
  const [filePreview, setFilePreview] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [minToDate, setMinToDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  const {
    projectlistForTasks,
    memberlistForTasks,
    departIdasInt,
    handleClose1,
    type,
    taskDetails,
    setTaskDetails,
  } = useTaskContextProvider();
  const taskInfo = taskDetails?.taskInfo;

  const isPDF = (file) => {
    if (taskDetails?.taskInfo?.task_image !== null && type === "Edit") {
      if (file instanceof File) {
        return file.type === "application/pdf";
      } else if (typeof file === "string") {
        const filename = file.substring(file.lastIndexOf("/") + 1);
        const blob = new Blob([file], { type: "application/pdf" });
        const newfile = new File([blob], filename, { type: "application/pdf" });
        return newfile && newfile.type === "application/pdf";
      }
    } else {
      return file && file.type === "application/pdf";
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(addtaskValidationSchema),
  });

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setSelectedFile(selectedFile);
    setFilePreview(URL.createObjectURL(selectedFile));
  };

  const proArr = [];
  projectlistForTasks.forEach((mem) => {
    proArr.push({ value: `${mem.id}`, label: `${mem.name}` });
  });
  const proptions = proArr;

  const handleCode2 = (event) => {
    setSelectedOption(event);
    setValue("project_id", event.value);
  };

  const memArr = [];
  memberlistForTasks.forEach((mem) => {
    memArr.push({ value: `${mem.id}`, label: `${mem.full_name}` });
  });
  const memoptions = memArr;
  const handleCode = (selectedOptions) => {
    setSelectedMembers(selectedOptions);
    const eventArr = selectedOptions.map((employee) => {
      const memberId = parseInt(employee.value, 10);
      return memberId;
    });
    setValue("member", eventArr);
  };

  const handleCode3 = (event) => {
    setValue("name", event.target.value);
  };
  const handleCode4 = (event) => {
    setValue("from_date_report", event.target.value);
  };
  const handleCode5 = (event) => {
    setValue("to_date_report", event.target.value);
  };
  const handleCode6 = (event) => {
    setValue("task_description", event.target.value);
  };

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("department_id", JSON.parse(departIdasInt));
      formData.append("project_id", data.project_id);
      formData.append("member", JSON.stringify(data.member));
      formData.append("from_date_report", data.from_date);
      formData.append("to_date_report", data.to_date);
      formData.append("name", data.short_name);
      formData.append("task_description", data.description);
      formData.append("task_type", data.priority);
      formData.append("id", type === "Create" ? "" : taskInfo?.id);
      if (selectedFile) {
        formData.append("img", selectedFile);
      } else {
        formData.append("img", null);
      }
      const response = await createTask(formData, storageInfo.data.token);
      checkDeptAccess();
      toast.success(response.data.message);
      handleClose1();
      setValue("project_id", "");
      setSelectedFile("");
      setValue("short_name", "");
      setValue("from_date_report", "");
      setValue("to_date_report", "");
      setValue("task_description", "");
      setValue("member", "");
      setValue("priority", "low");
      setValue("img", null);
    } catch (error) {
      console.error("API error:", error);
      toast.error(error.data.message);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (type === "Edit" && taskDetails) {
      setValue("project_id", taskInfo?.project_id);
      setValue("short_name", taskInfo?.name);
      setValue("from_date", taskInfo?.from_date_report);
      setValue("to_date", taskInfo?.to_date_report);
      setValue("description", taskInfo?.task_description);
      setValue("priority", taskInfo?.task_type);
      const selectedOption_1 = proptions.find(
        (option) => option.value == taskInfo?.project_id
      );
      setSelectedOption(selectedOption_1);
      const assignedMembers = memArr?.filter((mem) =>
        taskDetails?.member?.some((member) => member?.assigned_to == mem?.value)
      );
      setSelectedMembers(assignedMembers);
      const selectedEventArr = assignedMembers.map((employee) => {
        const memberId = parseInt(employee.value, 10);
        return memberId;
      });
      setValue("member", selectedEventArr);
      setFilePreview(`${baseURL}${taskInfo?.task_image}`);
      setSelectedFile(taskInfo?.task_image);
      setValue("img", selectedFile);
    } else if (type === "Create") {
      setTaskDetails("");
      setValue("project_id", "");
      setValue("short_name", "");
      setValue("from_date_report", "");
      setValue("to_date_report", "");
      setValue("task_description", "");
      setValue("member", "");
      setValue("priority", selectedPriority);
      setValue("img", null);
    }
  }, [taskDetails, setValue]);
  return (
    <>
      <Modal.Header closeButton>
        <div className="model_heading">
          <h1 className="modal-title" id="staticBackdropLabel">
            {type === "Edit"
              ? `Edit ${taskInfo?.name ? taskInfo?.name : "Task"}`
              : "Assign New Task"}
          </h1>
          <p>
            {type === "Edit"
              ? `You can edit your task.`
              : " You can add a new task, assign it to anyone, set the timeline and status here."}
          </p>
        </div>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="modal_body p-4 ">
            <div className="assign_task_form row g-0">
              <div className="col-lg-6 col-md-6 p-2">
                <label className="form-label">
                  {type === "Edit" ? `Edit Project` : "Choose Project"}
                </label>
                <Select
                  {...register("project_id")}
                  name="project_id"
                  options={proptions}
                  value={selectedOption}
                  onChange={handleCode2}
                />
                {errors.project_id && (
                  <p className="error text-danger">
                    {errors.project_id.message}
                  </p>
                )}
              </div>
              <div className="col-lg-6 col-md-6 p-2">
                <label className="form-label">
                  {type === "Edit" ? `Edit Assignee` : "Assignee"}
                </label>
                <Select
                  {...register("member")}
                  name="member"
                  options={memoptions}
                  onChange={handleCode}
                  isMulti
                  value={selectedMembers}
                />
                {errors.member && (
                  <p className="error text-danger">
                    Please select at least one member
                  </p>
                )}
              </div>
              <div className="col-lg-6 col-md-6 p-2 ">
                <label className="form-label">
                  {type === "Edit" ? `Edit Priority` : "Set Project"}
                </label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  data-show-icon="true"
                  id="mySelect"
                  onChange={(e) => setSelectedPriority(e.target.value)}
                  {...register("priority")}
                >
                  <option value="low">Low</option>
                  <option value="medium">Medium</option>
                  <option value="high">High</option>
                </select>
              </div>
              <div className="col-lg-6 col-md-6 p-2">
                <label className="form-label">
                  {type === "Edit"
                    ? `Edit Task Name`
                    : "Give your task a Descriptive Title"}
                </label>
                <input
                  {...register("short_name")}
                  name="short_name"
                  type="text"
                  className="form-control"
                  placeholder="Write a title"
                  onChange={handleCode3}
                />
                {errors.short_name && (
                  <p className="error text-danger m-0">
                    {errors.short_name.message}
                  </p>
                )}
              </div>
              <div className="col-lg-6 col-md-6 p-2 pt-0 pb-0 date_col">
                <label className="form-label">Date Of Report</label>
                <div className="date_report">
                  <div className="">
                    <Form.Label>From</Form.Label>
                    <div className="from_date me-2">
                      <Form.Group controlId="dob">
                        <Form.Control
                          {...register("from_date")}
                          name="from_date"
                          type="date"
                          dateFormat="MM-DD-YYYY"
                          onChange={(e) => {
                            handleCode4(e);
                            setMinToDate(e.target.value);
                          }}
                          min={minToDate}
                        />
                      </Form.Group>
                    </div>
                  </div>

                  <div className="">
                    <Form.Label>To</Form.Label>
                    <div className="from_date">
                      <Form.Group controlId="to">
                        <Form.Control
                          {...register("to_date")}
                          name="to_date"
                          type="date"
                          dateFormat="MM-DD-YYYY"
                          onChange={handleCode5}
                          min={minToDate}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </div>
              </div>
              <div className="error_div">
                {errors.from_date && (
                  <p className="error text-danger ">
                    {errors.from_date.message}
                  </p>
                )}
                {errors.to_date && (
                  <p className="error text-danger ms-5 ">
                    {errors.to_date.message}
                  </p>
                )}
              </div>
              <div className="col-lg-6 col-md-6 p-2 ">
                <label className="form-label mt-3">
                  {type === "Edit" ? `Edit Description` : "Add Description"}
                </label>
                <textarea
                  {...register("description")}
                  name="description"
                  rows="10"
                  cols="50"
                  className="text_box"
                  placeholder="Write something here"
                  onChange={handleCode6}
                />
                {errors.description && (
                  <p className="error text-danger m-0">
                    {errors.description.message}
                  </p>
                )}
              </div>
              <div className="col-lg-6 col-md-6 p-2">
                <label className="form-label mt-3">Attachment</label>

                {filePreview && (
                  <div className="file-preview-div">
                    {isPDF(selectedFile) ? (
                      <iframe
                        title="PDF Preview"
                        src={filePreview}
                        className="file-preview-img"
                      />
                    ) : (
                      <img
                        src={filePreview}
                        alt="File Preview"
                        className="file-preview-img"
                      />
                    )}
                  </div>
                )}

                <input
                  className="custom-file-input mt-2"
                  type="file"
                  id="fileInput"
                  onChange={handleFileChange}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <button onClick={handleClose1} className="request_btn back_btn">
            Close
          </button>
          <button
            type="submit"
            variant="primary"
            className="request_btn apply_btn"
            onClick={handleSubmit(onSubmit)}
            disabled={isLoading}
          >
            {isLoading ? (
              <Spinner animation="border" size="sm" />
            ) : type === "Edit" ? (
              "Update"
            ) : (
              "Proceed"
            )}
          </button>
        </Modal.Footer>
      </form>
    </>
  );
};

export default TaskModal;
