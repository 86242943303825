import React from "react";
import "./error.css";
import errorimage from "../../assets/images/Error 404.webp";
import { Link } from "react-router-dom";

function Error404() {
  return (
    <div>
      <div className="error_image">
        <img src={errorimage} alt="error 404" />
      </div>
      <div className="error404_btn mt-5 d-flex justify-content-center">
        <Link className="btn request_btn" to="/dashboard">
          Back to Dashboard
        </Link>
      </div>
    </div>
  );
}

export default Error404;
