import React from "react";
import { Modal, Spinner } from "react-bootstrap";

const DeleteTaskModal = ({
  handleCloseDeleteModal,
  removeTask,
  title,
  isBtnLoading,
}) => {
  return (
    <>
      <Modal.Body>
        <div className="assign_task_form d-flex justify-content-center align-baseline realy_want">
          <i className="fa-solid fa-triangle-exclamation"></i>
          <h6>Do you realy want to delete this {title}?</h6>
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <button className="cancel_btn" onClick={handleCloseDeleteModal}>
          NO
        </button>
        <button
          type="button"
          className="request_btn apply_btn"
          onClick={removeTask}
          disabled={isBtnLoading}
        >
          {!isBtnLoading ? (
            "YES"
          ) : (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
        </button>
      </Modal.Footer>
    </>
  );
};

export default DeleteTaskModal;
