import React from "react";

function ProjectListHead() {
  return (
    <div className="projecttable_head">
      <div className="row">
        <div className="col-2">
          <p>Name</p>
        </div>
        <div className="col-1 text-center">
          <p>Insight</p>
        </div>
        <div className="col-2">
          <p>Start Date</p>
        </div>
        <div className="col-2">
          <p>End Date</p>
        </div>
        <div className="col-2">
          <p>Progress</p>
        </div>
        <div className="col-2 p-0">
          <p>Priority</p>
        </div>
        <div className="col-1"></div>
      </div>
    </div>
  );
}

export default ProjectListHead;
