import React, { useEffect, useState } from "react";
import "./members.css";
import { toast } from "react-toastify";
import { Modal, Spinner } from "react-bootstrap";
import Container from "../../components/layouts/container";
import LeftSide from "../../components/layouts/left_side";
import { allRole, getMembers, inviteMembers } from "../../api/members";
import { storageData } from "../../components/helpers/helper";
import TabContent from "../../components/members/tabcontent/tabContent";
import Select from "react-select";
import { getCompanyDepartment } from "../../api/department";
import TableHead from "../../components/members/tabcontent/TableHead";
import GeneralSpinner from "../../components/spiner/Spiner";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { invitememberValidationSchema } from "../../components/members/tabcontent/memberValidation";
import { useTaskContextProvider } from "../../utils/TaskContextProvider";
import ImageFunction from "../../components/department/ImageFunction";
import { useNavigate } from "react-router-dom";
import rolePermissionsHelper from "../../components/helpers/rolePermissionHelper";

function Members() {
  const {
    setLoading,
    loading,
    assignedPermissions,
    isBtnLoading,
    cpompdepart,
    compDepartData,
    setAssignedPermissions,
    setBtnLoading,
  } = useTaskContextProvider();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [membersdetail, setMembers] = useState([]);
  const [membername, setMemberName] = useState("");
  const [roleid, setRoleID] = useState(null);
  const [loadMore, setLoadMore] = useState(true);
  const storageInfo = storageData();
  const localdata = JSON.parse(localStorage.getItem("did"));
  const [depId, setDepId] = useState("");
  const [inviteID, setinviteDepId] = useState("");
  const [allrole, setAllRole] = useState([]);
  const [pageNumber, setPageNumber] = useState(2);

  const dept_id = localStorage.getItem("u_did");
  const role_id = localStorage.getItem("role_id");
  const userType = storageInfo.data.user_type;
  const token = storageInfo.data.token;
  const tokenid = storageInfo.data.token;
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(invitememberValidationSchema),
  });
  const onSubmit = (data) => {};

  const valueArr = [];
  cpompdepart.forEach((employee) => {
    valueArr.push({
      value: `${employee.user_department_id}`,
      label: `${employee.tmp_department_name}`,
    });
  });

  const invitevalueArr = [{ value: null, label: "None" }];
  cpompdepart.forEach((employee) => {
    invitevalueArr.push({
      value: employee.user_department_id,
      label: `${employee.tmp_department_name}`,
      departId: employee.id,
    });
  });
  const inviteoptions = invitevalueArr;

  const allRolesArr = [];

  allrole.forEach((role) => {
    allRolesArr.push({
      value: role.role_id,
      label: `${role.role_name}`,
    });
  });
  const rolesOptions = allRolesArr;

  valueArr.unshift({ value: null, label: "None" });

  const memberName = (event) => {
    setMemberName(event.target.value);
  };
  const handleCode1 = (event) => {
    setMembers([]);
    const selectedValue = event.value;
    setDepId(selectedValue);
    listMember(selectedValue, roleid);
  };

  const handleDepart = (selectedOption) => {
    if (selectedOption) {
      const departmentId = selectedOption.departId;
      const userDepId = selectedOption.value;
      setinviteDepId(userDepId);
      showAllRole(departmentId);
    } else {
      setinviteDepId(null);
    }
  };

  const modelShow = () => {
    setinviteDepId(null);
    reset();
    handleShow();
  };
  const showCompanyDeparts = async () => {
    try {
      const formData = {};
      const Token = tokenid;
      const response = await getCompanyDepartment(formData, Token);
      const data = response.data.data ? response.data.data : "";
      compDepartData(data);
      return false;
    } catch (error) {}
  };

  const addMember = async () => {
    if (inviteID === null) {
      toast.error("Select a department");
      return false;
    }
    setBtnLoading(true);

    try {
      const formData = {
        email: membername,
        department_id: inviteID,
        role_id: roleid,
      };
      const Token = tokenid;
      const response = await inviteMembers(formData, Token);
      handleClose();

      const responseData = response.data.data;

      const newMember = {
        id: responseData.id,
        full_name: responseData.full_name,
        profile_image: responseData.profile_image,
        role_name: responseData.role_name,
        tmp_department_name: responseData.tmp_department_name,
        email: responseData.email,
      };

      setMembers((prevMembers) => [...prevMembers, newMember]);
      reset();
      setBtnLoading(false);

      toast.success(response.data.message);
      return false;
    } catch (error) {
      setBtnLoading(false);
      toast.error(error.response.data.message);
    }
  };

  // const handleLoadMore = async () => {
  //   try {
  //     const formData = {
  //       department_id: depId ? depId : localdata,
  //       role_id: roleid,
  //       page_no: pageNumber,
  //     };
  //     const Token = tokenid;
  //     const response = await getMembers(formData, Token);
  //     const data = response.data.data;
  //     if (data.length > 0) {
  //       setMembers((prevData) => [...prevData, ...data]);
  //       setPageNumber((prevPage) => prevPage + 1);
  //       setLoading(false);
  //     } else {
  //       setLoading(false);
  //       setLoadMore(false);
  //     }
  //     return false;
  //   } catch (error) {
  //     toast.error(error.response.data.message);
  //   }
  // };
  const listMember = async (selectedValue, ID, pageNumber) => {
    setLoading(true);
    try {
      const formData = {
        department_id: selectedValue,
        page_no: pageNumber,
      };
      const Token = tokenid;
      const response = await getMembers(formData, Token);
      const data = response.data.data;

      if (data.length > 0) {
        setMembers(data);
        setLoadMore(true);
        setLoading(false);
      } else {
        setLoading(false);
      }
      return false;
    } catch (error) {
      if (error.response && error.response.status === 404) {
        navigate("/permission-denied");
      }
      toast.error(error.response.data.message);
    }
  };

  const showAllRole = async (departmentId) => {
    try {
      const formData = {
        dpt_id: departmentId,
      };
      const Token = tokenid;
      const response = await allRole(formData, Token);
      const data = response.data.data ? response.data.data : "";
      setAllRole(data);
      return false;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const handleRole = (selectedRole) => {
    if (selectedRole) {
      const roleId = selectedRole.value;
      setRoleID(roleId);
    } else {
      setRoleID(null);
    }
  };

  useEffect(() => {
    listMember();
    showCompanyDeparts();

    if (userType === "member") {
      rolePermissionsHelper(
        dept_id,
        role_id,
        token,
        setAssignedPermissions,
        setBtnLoading
      );
    }
  }, []);

  return (
    <div>
      <Container>
        <div className="dashboard_main">
          <div className="main_body">
            <LeftSide />
            <div className="right_side w-100">
              <div className="container-fluid">
                <div className="dashboard_inner col-11 m-auto">
                  <div className="board_header">
                    <div className="main_heading">
                      <h3>Members</h3>
                      <p>Here you can manage and see your work performance </p>
                    </div>

                    {assignedPermissions.create && (
                      <div className="add_new_member">
                        <button
                          className="btn new_member"
                          type="button"
                          onClick={modelShow}
                        >
                          Add New Member
                        </button>
                      </div>
                    )}
                  </div>

                  <div className="tabs_part">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="date_filter justify-content-end mb-3">
                          <div className="filter_by">
                            <label className="form-label">
                              Filter by Departments
                            </label>
                            <Select
                              className="select-dropdown"
                              options={inviteoptions}
                              onChange={handleCode1}
                            />
                          </div>
                        </div>
                        <TableHead />
                        {!loading ? (
                          membersdetail.length === 0 ? (
                            <ImageFunction
                              name="Members"
                              line="Try to add more members"
                            />
                          ) : (
                            <TabContent
                              assignedPermissions={assignedPermissions}
                              tabdata={membersdetail}
                              depID={depId}
                              apifunction={listMember}
                              setMembers={setMembers}
                            />
                          )
                        ) : (
                          <GeneralSpinner />
                        )}
                        <div className="loadmore_btn d-flex justify-content-center mt-3">
                          {/* {loadMore === true ? (
                            <button
                              className="btn request_btn text-center load_more"
                              type="button"
                              onClick={handleLoadMore}
                            >
                              Load More
                            </button>
                          ) : (
                            ""
                          )} */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <div className="model_heading">
              <h1 className="modal-title" id="staticBackdropLabel">
                Add a new member
              </h1>
              <p>Here you can add new members</p>
            </div>
          </Modal.Header>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Body>
              <div className="request_info">
                <div className="col-md-12 pt-2">
                  <label for="validationCustom01" className="form-label">
                    Select Department
                  </label>
                  <Select
                    placeholder="Select Department"
                    options={inviteoptions}
                    onChange={handleDepart}
                  />
                </div>
                {inviteID && (
                  <>
                    <div className="col-md-12 pt-2">
                      <label htmlFor="role" className="form-label">
                        Select Role
                      </label>
                      <Select
                        options={rolesOptions}
                        onChange={handleRole}
                        placeholder="Select Role"
                      />
                    </div>
                  </>
                )}

                <div className="col-md-12">
                  <label for="validationCustom01" className="form-label">
                    Email
                  </label>
                  <input
                    {...register("member_email")}
                    name="member_email"
                    type="text"
                    className="form-control"
                    placeholder="saad@gmail.com"
                    onChange={memberName}
                    required
                  />
                  {errors.member_email && (
                    <p className="error text-danger m-0">
                      {errors.member_email.message}
                    </p>
                  )}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className="justify-content-center mt-2">
              <button className="cancel_btn" onClick={handleClose}>
                Cancel
              </button>
              <button
                type="submit"
                className="request_btn apply_btn"
                onClick={handleSubmit(addMember)}
                disabled={isBtnLoading}
              >
                {!isBtnLoading ? (
                  "Send Invite"
                ) : (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </button>
            </Modal.Footer>
          </form>
        </Modal>
      </Container>
    </div>
  );
}

export default Members;
