import React from "react";
import { ReactComponent as Dots } from "../../../assets/images/dots.svg";

function DropDown(props) {
  return (
    <>
      <div className="col-lg-1 col-1">
        <div className="dropdown justify-content-center">
          <div className="drop_btn">
            <button
              className="dots_btn dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              onClick={props.click}
              id={props.post}
            >
              <Dots />
            </button>

            <ul className="dropdown-menu">
              {props.assignedPermissions.edit && (
                <li>
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={() => props.model(props.DID)}
                  >
                    Edit
                  </button>
                </li>
              )}
              {props.assignedPermissions.updatePw && (
                <li>
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={props.show1}
                  >
                    Update Password
                  </button>
                </li>
              )}

              {/* {props.assignedPermissions.remove && (
                <li>
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={props.show2}
                  >
                    Remove Member
                  </button>
                </li>
              )} */}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
export default DropDown;
