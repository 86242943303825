import React, { useEffect, useRef, useState } from "react";
import profile from "../../assets/images/profile.png";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { memberProfileValidationSchema } from "./ProfileValidation";
import { storageData } from "../helpers/helper";
import { API } from "../../api/profile";
import { useTaskContextProvider } from "../../utils/TaskContextProvider";
import Spinner from "react-bootstrap/Spinner";
function MemberInfo() {
  const storageInfo = storageData();
  const membername = storageInfo.data.full_name;
  const memberemail = storageInfo.data.email;
  const localdata = JSON.parse(localStorage.getItem("did"));

  const { isBtnLoading, setBtnLoading } = useTaskContextProvider();
  const userPic = storageInfo.data.profile_image;
  const baseURL = process.env.REACT_APP_URL;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(memberProfileValidationSchema),
  });
  const [image, setimage] = useState("");
  const inputref = useRef(null);
  const handleImageClick = () => {
    inputref.current.click();
  };
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setimage(file);
  };
  const [compname, setName] = useState(membername);
  const HandleName = (event) => {
    setName(event.target.value);
  };
  const [email, setEmail] = useState(memberemail);
  const HandleEmail = (event) => {
    setEmail(event.target.value);
  };
  useEffect(() => {
    setBtnLoading(false);
  }, []);

  const onSubmit = async (data) => {
    setBtnLoading(true);
    try {
      const formData = new FormData();
      formData.append("full_name", data.full_name);
      formData.append("department_id", localdata);
      if (image) {
        formData.append("img", image);
        formData.append("p_img", userPic);
      } else if (userPic !== null) {
        formData.append("img", "");
      }

      const response = await API.post("user/personal-info", formData, {
        headers: {
          Authorization: `Bearer ${storageInfo?.data?.token}`,
        },
      });
      setBtnLoading(false);
      setimage("");
      storageInfo.data.full_name = response.data.data.full_name;
      storageInfo.data.profile_image = response.data.data.profile_image;
      localStorage.setItem("c_info", JSON.stringify(storageInfo));

      return false;
    } catch (error) {
      setBtnLoading(false);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-lg-7 col-md-7">
            <div className="personal_info">
              <h3>Personal Information</h3>
              <div className="personal_form row g-3">
                <div className="personal_col col-12">
                  <label>Full Name</label>
                  <input
                    {...register("full_name")}
                    name="full_name"
                    type="text"
                    className="form-control"
                    placeholder="Full Name"
                    onChange={HandleName}
                    value={compname}
                  />
                  {errors.full_name && (
                    <p className="error text-danger m-0">
                      {errors.full_name.message}
                    </p>
                  )}
                </div>
                <div className="personal_col pas_inputs col-12">
                  <label>Email</label>
                  <input
                    {...register("email")}
                    name="email"
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    onChange={HandleEmail}
                    value={email}
                    readOnly
                  />
                  {errors.email && (
                    <p className="error text-danger m-0">
                      {errors.email.message}
                    </p>
                  )}
                </div>
                <div className="sign_btn ">
                  <button
                    type="submit"
                    className="request_btn apply_btn btn"
                    disabled={isBtnLoading}
                  >
                    {!isBtnLoading ? (
                      "Save"
                    ) : (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-5">
            <div className="picker col-8">
              <div className="inner_picker">
                <div className="image_picker p-0" onClick={handleImageClick}>
                  <label className="penlabel" for="image-picker">
                    <i className="fa-solid fa-pen"></i>
                  </label>

                  {image ? (
                    <img src={URL.createObjectURL(image)} alt="" />
                  ) : userPic ? (
                    <img src={`${baseURL}${userPic}`} alt="" />
                  ) : (
                    <img src={profile} alt="" />
                  )}

                  <input
                    type="file"
                    onChange={handleImageChange}
                    ref={inputref}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default MemberInfo;
