import React from "react";
import { ReactComponent as Question } from "../../../assets/images/question.svg";
import BigTimer from "../../../components/timer/bigtimer";

function SubmitTime() {
  return (
    <div>
      <div className="submit_before">
        <div className="task_heading d-flex justify-content-between">
          <h1>Submit before</h1>
          <Question />
        </div>
        <div className="big_timer">
          <BigTimer />
        </div>
        <div className="hurry">
          <p>Hurry up, you are running out of time 😱</p>
        </div>
      </div>
    </div>
  );
}

export default SubmitTime;
