import React, { useState } from "react";
import { useTaskContextProvider } from "../../utils/TaskContextProvider";
import TaskDataRow from "./TaskDataRow";
function DynamicTaskRow({
  divs,
  tasksname,
  ProjectsandTasks,
  projectData,
  checkDeptAccess,
  tasknumber,
}) {
  const {
    setTaskProjectID,
    setStatusID,
    SelectedDates,
    setSelectedPriority,
    setTaskId,
    setShowModal,
  } = useTaskContextProvider();

  const [editedTasks, setEditedTasks] = useState({});
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleShowModal = (taskId) => {
    setShowModal(true);
    setTaskId(taskId);
  };

  const handleToggleEdit = (value, taskId, pid) => {
    setEditedTasks((prev) => ({
      ...prev,
      [taskId]: value,
    }));
    setTaskProjectID(pid);
    setStatusID(taskId);
  };
  const handleInputChange = (value, taskId) => {
    setEditedTasks((prev) => ({ ...prev, [taskId]: value }));
  };

  const renderTaskCards = () => {
    return tasksname.map((task) => (
      <>
        <TaskDataRow
          checkDeptAccess={checkDeptAccess}
          handleCloseModal={handleCloseModal}
          ProjectsandTasks={ProjectsandTasks}
          task={task}
          projectData={projectData}
          selectedDates={SelectedDates}
          handleShowModal={handleShowModal}
          editedTasks={editedTasks}
          setEditedTasks={setEditedTasks}
          handleInputChange={handleInputChange}
          handleToggleEdit={handleToggleEdit}
          setSelectedPriority={setSelectedPriority}
        />
      </>
    ));
  };
  return (
    <div>
      <div className={tasknumber > 3 ? "manin_table_data" : ""}>
        {renderTaskCards()}
        {divs}
      </div>
    </div>
  );
}

export default DynamicTaskRow;
