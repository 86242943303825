import React from "react";
import { useTaskContextProvider } from "../../utils/TaskContextProvider";
import { Link } from "react-router-dom";
import TaskDetailModal from "../task/taskmodals/DetailTaskModal";
import GeneralSpinner from "../spiner/Spiner";
import { Modal } from "react-bootstrap";
import moment from "moment";
function TaskMemberData({
  handleShowDetails,
  handleCloseTaskDetails,
  showTaskDetails,
}) {
  const { memtasklist, setProjectName, taskDetails, loading } =
    useTaskContextProvider();
  return (
    <div>
      {memtasklist?.map((post) => {
        return (
          <div className="project_row">
            <div className="row">
              <div className="col-2">
                <div className="task_project_name">
                  <Link
                    onClick={() => setProjectName(post.name)}
                    to={`/projects/tasks/${post.pid}`}
                    id={post.id}
                  >
                    <p>{post.project_name}</p>
                  </Link>
                </div>
              </div>
              <div className="col-2">
                <div className="_task_name">
                  <p onClick={() => handleShowDetails(post.task_id, post.pid)}>
                    {post.task_name}
                  </p>
                </div>
              </div>
              <div className="col-2">
                <div className="start_end_date">
                  <p
                    style={{
                      fontSize: "11px",
                    }}
                  >
                    {post.from_date_report === null
                      ? "N/A"
                      : `${moment(post.from_date_report).format(
                          "DD/MM/YYYY"
                        )} to ${moment(post.to_date_report).format(
                          "DD/MM/YYYY"
                        )}`}
                  </p>
                </div>
              </div>
              <div className="col-2">
                <div className="task_status">
                  <p
                    style={{
                      color: post.color_code,
                      textTransform: "capitalize",
                    }}
                  >
                    {post.status_name}
                  </p>
                </div>
              </div>
              <div className="col-2">
                <div className="task_assign_by">
                  <p>{post.assigned_by}</p>
                </div>
              </div>
              <div className="col-2">
                <div className="task_priority">
                  <p
                    style={{
                      textTransform: "capitalize",
                    }}
                  >
                    <i
                      className={
                        post.task_type
                          ? post.task_type === "medium"
                            ? "gray_medium fa-solid fa-flag"
                            : post.task_type === "high"
                            ? "red_high fa-solid fa-flag"
                            : "green_low fa-solid fa-flag"
                          : ""
                      }
                    ></i>
                    {post.task_type}
                  </p>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <Modal
        show={showTaskDetails}
        onHide={handleCloseTaskDetails}
        size="xl"
        fullscreen
        backdrop="static"
        keyboard={false}
      >
        {!loading ? (
          <TaskDetailModal taskDetails={taskDetails} />
        ) : (
          <GeneralSpinner />
        )}
      </Modal>
    </div>
  );
}

export default TaskMemberData;
