import React, { useEffect, useState } from "react";
import LeftSide from "../../components/layouts/left_side";
import Container from "../../components/layouts/container";
import "./permission.css";
import { useTaskContextProvider } from "../../utils/TaskContextProvider";
import {
  capitalizeFirstLetter,
  storageData,
} from "../../components/helpers/helper";
import { assignPermissions, getPermissions } from "../../api/permissions";
import { toast } from "react-toastify";
import GeneralSpinner from "../../components/spiner/Spiner";
import { Spinner } from "react-bootstrap";
import Select from "react-select";
import { getCompanyDepartment } from "../../api/department";
import { allRole } from "../../api/members";

function Permissions() {
  const {
    permissions,
    roleID,
    inviteID,
    setLoading,
    loading,
    setPermissions,
    setBtnLoading,
    isBtnLoading,
    setRoleID,
    setInviteId,
  } = useTaskContextProvider();
  const storageInfo = storageData();

  const modules = permissions?.modules;
  const modulesPermissions = permissions?.modulePermissions;
  const departPermissions = permissions?.deptPermissions;

  const [expandedAccordion, setExpandedAccordion] = useState(0);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [selectAllStates, setSelectAllStates] = useState({});
  const [selectAllState, setSelectAllState] = useState("Select All");

  const handleAccordionClick = (index) => {
    setExpandedAccordion(index === expandedAccordion ? null : index);
  };

  const filterModulePermissions = (moduleId) => {
    return (
      modulesPermissions?.filter(
        (permission) => permission.module_id === moduleId
      ) || []
    );
  };

  const handleCheckboxChange = (moduleId, permissionId) => {
    const selectedIndex = selectedPermissions.indexOf(permissionId);
    if (selectedIndex === -1) {
      setSelectedPermissions([...selectedPermissions, permissionId]);
    } else {
      const updatedPermissions = [...selectedPermissions];
      updatedPermissions.splice(selectedIndex, 1);
      setSelectedPermissions(updatedPermissions);
    }
  };

  const handleSelectAllChange = (moduleId, isChecked) => {
    const updatedSelectAllStates = {
      ...selectAllStates,
      [moduleId]: isChecked,
    };
    setSelectAllStates(updatedSelectAllStates);

    if (isChecked) {
      const modulePermissions = filterModulePermissions(moduleId).map(
        (permission) => permission.id
      );
      setSelectedPermissions((prevSelected) => [
        ...prevSelected,
        ...modulePermissions,
      ]);
    } else {
      setSelectedPermissions((prevSelected) =>
        prevSelected.filter(
          (permissionId) =>
            !filterModulePermissions(moduleId).some(
              (permission) => permission.id === permissionId
            )
        )
      );
    }
  };

  const handleAssignPermissions = async () => {
    setBtnLoading(true);
    try {
      const formData = {
        ids: selectedPermissions,
        u_deptid: inviteID,
        role_id: roleID,
      };

      if (selectedPermissions.length === 0) {
        toast.error("Please check at least one permission!");
        setLoading(false);
        setBtnLoading(false);
      } else {
        const token = storageInfo.data.token;
        const response = await assignPermissions(formData, token);
        toast.success(response.data.message);
        setBtnLoading(false);
        handlePermissions();
      }
      return false;
    } catch (error) {
      toast.error(error.response.data.message);
      setBtnLoading(false);
    }
  };

  const [all_departments, setallDepartments] = useState([]);
  const [allroles, setAllRoles] = useState([]);

  const fetchDept = async () => {
    const alldepartments = await getCompanyDepartment();
    setallDepartments(alldepartments.data.data);
  };

  const invitevalueArr = [];
  all_departments.forEach((employee) => {
    invitevalueArr.push({
      value: employee.user_department_id,
      label: `${employee.tmp_department_name}`,
      departId: employee.id,
    });
  });
  const inviteoptions = invitevalueArr;

  const allRolesArr = [];
  allroles.forEach((role) => {
    allRolesArr.push({
      value: role.role_id,
      label: `${role.role_name}`,
    });
  });
  const rolesOptions = allRolesArr;

  const handleDepart = (selectedOption) => {
    if (selectedOption) {
      const departmentId = selectedOption.departId;
      const userDepId = selectedOption.value;
      setInviteId(userDepId);
      showAllRole(departmentId);
    } else {
      setInviteId(null);
    }
  };

  const showAllRole = async (departmentId) => {
    setRoleID(null);
    try {
      const formData = {
        dpt_id: departmentId,
      };
      const token = storageInfo.data.token;
      const response = await allRole(formData, token);
      const data = response.data.data ? response.data.data : "";
      setAllRoles(data);
      return false;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const showPermissions = (selectedRole) => {
    handlePermissions(selectedRole.value);
    setRoleID(selectedRole.value);
  };
  const handlePermissions = async (selectedRole) => {
    setLoading(true);
    try {
      const formData = {
        dept_id: inviteID,
        role_id: selectedRole,
      };
      if (inviteID === null) {
        setLoading(false);
        toast.error("Select a Department");
      } else if (selectedRole === null) {
        toast.error("Select a Role");
        setLoading(false);
      } else {
        const token = storageInfo.data.token;
        const response = await getPermissions(formData, token);
        setPermissions(response.data.data);
        setLoading(false);
      }
      return false;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const handleSelectAll = () => {
    if (selectAllState === "Select All") {
      const allPermissions = modulesPermissions.reduce((acc, module) => {
        const modulePermissions = filterModulePermissions(module.id).map(
          (permission) => permission.id
        );
        return [...acc, ...modulePermissions];
      }, []);

      setSelectedPermissions(allPermissions);
      setSelectAllState("Deselect All");
    } else {
      setSelectedPermissions([]);
      setSelectAllState("Select All");
    }
  };

  useEffect(() => {
    fetchDept();
    if (departPermissions) {
      setSelectedPermissions(JSON.parse(departPermissions));
    }
  }, [departPermissions]);

  return (
    <div>
      <Container>
        <div className="dashboard_main">
          <div className="main_body">
            <LeftSide />
            <div className="right_side w-100">
              <div className="container-fluid">
                <div className="dashboard_inner col-11 m-auto">
                  <div className="board_header">
                    <div className="main_heading">
                      <h3>Permissions</h3>
                      <p>Here you can manage permissions</p>
                    </div>
                  </div>
                  <div className="permission_table_main mt-3">
                    <div className="filter-head">
                      <div
                        style={{
                          display: "flex",
                          gap: "1rem",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            gap: "1rem",
                            alignItems: "center",
                          }}
                        >
                          <div className="department_filter">
                            <label htmlFor="">Department</label>
                            <Select
                              options={inviteoptions}
                              onChange={handleDepart}
                            />
                          </div>

                          {inviteID && (
                            <div className="department_filter">
                              <label htmlFor="">Role</label>
                              <Select
                                options={rolesOptions}
                                onChange={showPermissions}
                              />
                            </div>
                          )}
                        </div>

                        {permissions !== null && (
                          <button
                            className="request_btn apply_btn btn"
                            onClick={handleSelectAll}
                          >
                            {selectAllState}
                          </button>
                        )}
                      </div>
                    </div>
                    {!loading ? (
                      permissions !== null && (
                        <div className="permission_body mt-3">
                          <div className="row">
                            {modules.map((data, index) => {
                              const filteredPermissions =
                                filterModulePermissions(data.id);
                              const isAccordionAllChecked =
                                filteredPermissions.length > 0 &&
                                filteredPermissions.every((permission) =>
                                  selectedPermissions.includes(permission.id)
                                );
                              return (
                                <div key={index} className="col-6 mt-2">
                                  <div
                                    className="accordion"
                                    id={`accordion${index}`}
                                  >
                                    <div className="accordion-item">
                                      <h2
                                        className="accordion-header"
                                        id={`heading${index}`}
                                      >
                                        <button
                                          className={`accordion-button ${
                                            expandedAccordion === index
                                              ? ""
                                              : "collapsed"
                                          }`}
                                          type="button"
                                          onClick={() =>
                                            handleAccordionClick(index)
                                          }
                                          aria-expanded={
                                            expandedAccordion === index
                                          }
                                          aria-controls={`collapse${index}`}
                                        >
                                          {data.name}
                                        </button>
                                      </h2>
                                      <div
                                        id={`collapse${index}`}
                                        className={`accordion-collapse collapse ${
                                          expandedAccordion === index
                                            ? "show"
                                            : ""
                                        }`}
                                        aria-labelledby={`heading${index}`}
                                        data-bs-parent={`#accordion${index}`}
                                      >
                                        <div className="accordion-body">
                                          <div className="row">
                                            <div className="col-12 mb-2">
                                              <div className="checkbox-wrapper-12">
                                                <label className="check_label form-label">
                                                  <div className="cbx">
                                                    <input
                                                      id={`cbx-${index}-all`}
                                                      type="checkbox"
                                                      onChange={(e) =>
                                                        handleSelectAllChange(
                                                          data.id,
                                                          e.target.checked
                                                        )
                                                      }
                                                      checked={
                                                        isAccordionAllChecked
                                                      }
                                                    />
                                                    <label
                                                      htmlFor={`cbx-${index}-all`}
                                                    ></label>
                                                    <svg
                                                      width="15"
                                                      height="14"
                                                      viewBox="0 0 15 14"
                                                      fill="none"
                                                    >
                                                      <path d="M2 8.36364L6.23077 12L13 2"></path>
                                                    </svg>
                                                  </div>
                                                  Select All
                                                </label>
                                              </div>
                                            </div>

                                            {filteredPermissions.map(
                                              (permission, permIndex) => (
                                                <div
                                                  key={permIndex}
                                                  className="col-6"
                                                >
                                                  <div className="checkbox-wrapper-12">
                                                    <label className="check_label form-label">
                                                      <div className="cbx">
                                                        <input
                                                          id={`cbx-${index}-${permIndex}`}
                                                          type="checkbox"
                                                          onChange={() =>
                                                            handleCheckboxChange(
                                                              data.id,
                                                              permission.id
                                                            )
                                                          }
                                                          checked={selectedPermissions.includes(
                                                            permission.id
                                                          )}
                                                        />
                                                        <label
                                                          htmlFor={`cbx-${index}-${permIndex}`}
                                                        ></label>
                                                        <svg
                                                          width="15"
                                                          height="14"
                                                          viewBox="0 0 15 14"
                                                          fill="none"
                                                        >
                                                          <path d="M2 8.36364L6.23077 12L13 2"></path>
                                                        </svg>
                                                      </div>
                                                      {capitalizeFirstLetter(
                                                        permission.name
                                                      )}
                                                    </label>
                                                  </div>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                            <div className="save_filter d-flex justify-content-end mt-3">
                              <button
                                onClick={handleAssignPermissions}
                                className="request_btn apply_btn btn"
                                disabled={isBtnLoading}
                              >
                                {!isBtnLoading ? (
                                  "Save"
                                ) : (
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      )
                    ) : (
                      <GeneralSpinner />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Permissions;
