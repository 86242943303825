import axios from "axios";
const baseURL = process.env.REACT_APP_API_URL;
export const API = axios.create({
  baseURL: baseURL,
});
export default async function allDepartments(reqData) {
  const url = "department/list";
  const c_info = JSON.parse(localStorage.getItem("c_info"));
  return handleRequest(url, "post", reqData, c_info.data.token);
}
export const addDepartments = async (reqData, token) => {
  const url = "department/create";
  return handleRequest(url, "post", reqData, token);
};
export const dellDepartment = async (reqData, token) => {
  const url = "department/delete";
  return handleRequest(url, "post", reqData, token);
};
export const editDepartment = async (reqData, token) => {
  const url = "department/edit";
  return handleRequest(url, "post", reqData, token);
};
export const editTmpDepartment = async (reqData, token) => {
  const url = "department/tmp-department";
  return handleRequest(url, "post", reqData, token);
};
export const updateDepartment = async (reqData, token) => {
  const url = "department/update";
  return handleRequest(url, "post", reqData, token);
};
export async function saveDepartment(reqData) {
  const url = "subscribe/assign-company-departments";
  const c_info = JSON.parse(localStorage.getItem("c_info"));
  return handleRequest(url, "post", reqData, c_info.data.token);
}
export async function getCompanyDepartment() {
  const url = "company/get-departments";
  const c_info = JSON.parse(localStorage.getItem("c_info"));

  const reqData = {
    id: c_info.data.company_info.id,
  };

  return handleRequest(url, "post", reqData, c_info.data.token);
}

export const accessDept = async (reqData, token) => {
  const url = "department/available-dept";
  return handleRequest(url, "post", reqData, token);
};
const handleRequest = async (url, method, reqData = {}, token = null) => {
  try {
    return await API({
      method: method,
      url: url,
      data: reqData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    throw error;
  }
};
