import React, { useEffect, useRef, useState } from "react";
import "../../pages/task/task.css";
import { useTaskContextProvider } from "../../utils/TaskContextProvider";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { storageData } from "../../components/helpers/helper";
import api from "../helpers/AccessHelper";

function NewTask({ ProjectsandTasks, setDivs }) {
  const inputRef = useRef(null);
  const storageInfo = storageData();
  const { taskprojectID, departIdasInt, taskStatusID, setEditing } =
    useTaskContextProvider();
  const navigate = useNavigate();

  const [text, setText] = useState("");
  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const handleInputChange = (event) => {
    setText(event.target.value);
  };

  const handleInputBlur = (e) => {
    if (e.key === "Enter") {
      if (text === "") {
        setDivs([]);
      } else {
        setEditing(false);
        onSubmit();
      }
    }
  };
  const handBlur = () => {
    if (text === "") {
      setDivs([]);
    } else {
      onSubmit();
    }
  };

  const onSubmit = async () => {
    if (text === "") {
      toast.error("Please Enter Name First");
      return false;
    }
    try {
      const formData = new FormData();
      formData.append("department_id", JSON.parse(departIdasInt));
      formData.append("project_id", taskprojectID);
      formData.append("task_status_id", taskStatusID);
      formData.append("name", text);
      formData.append("act", "created");

      await api.post("task/create-task", formData, {
        headers: {
          Authorization: `Bearer ${storageInfo?.data?.token}`,
        },
      });
      ProjectsandTasks();
      setDivs([]);
    } catch (error) {
      if (error?.response?.data?.data === "permission") {
        navigate("/404");
      }
      if (error?.response?.data?.data === "plan") {
        navigate("/dashboard");
      }
    }
  };
  return (
    <div>
      <div className="row tasktable_data">
        <div className="col-lg-3 d-flex align-items-center position-relative">
          <div className="edit_hide">
            <input
              className="form-control"
              type="text"
              ref={inputRef}
              onChange={handleInputChange}
              onKeyDown={handleInputBlur}
              onBlur={handBlur}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewTask;
