import React from "react";
import { useTaskContextProvider } from "../../utils/TaskContextProvider";

function Gbutton(props) {
  const { isBtnLoading } = useTaskContextProvider();

  return (
    <div>
      <div className="col-12">
        <button
          className="btn signup_btn w-100 py-2"
          type="submit"
          disabled={isBtnLoading}
        >
          {props.name}
        </button>
      </div>
    </div>
  );
}

export default Gbutton;
