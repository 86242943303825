import React from "react";
import Container from "../../components/layouts/container";
import LeftSide from "../../components/layouts/left_side";
import Analytic from "../analytics/Analytic";

function SuperAdmin() {
  return (
    <div>
      <Container>
        <div className="dashboard_main">
          <div className="main_body">
            <LeftSide />
            <div className="right_side w-100">
              <div className="container-fluid">
                <div className="dashboard_inner col-11 m-auto">
                  <Analytic />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default SuperAdmin;
