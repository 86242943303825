import React from "react";
import ProfileSecurity from "../../../components/profile/ProfileSecurity";
// import ProfileAbout from "../../../components/profile/ProfileAbout";
import MemberInfo from "../../../components/profile/MemberInfo";
function MemberProfile() {
  return (
    <div>
      <div className="profile_detail">
        <div className="tab-content" id="nav-tabContent">
          <div
            className="tab-pane fade show active"
            id="nav-home"
            role="tabpanel"
            aria-labelledby="nav-home-tab"
            tabindex="0"
          >
            <MemberInfo />
          </div>
          <div
            className="tab-pane fade"
            id="nav-profile"
            role="tabpanel"
            aria-labelledby="nav-profile-tab"
            tabindex="0"
          >
            {/* <ProfileAbout /> */}
          </div>
          <div
            className="tab-pane fade"
            id="nav-contact"
            role="tabpanel"
            aria-labelledby="nav-contact-tab"
            tabindex="0"
          >
            <ProfileSecurity />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MemberProfile;
