import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { updatePasswordValidationSchema } from "./memberValidation";
import { Modal } from "react-bootstrap";
import { updatePassword } from "../../../api/members";
import { storageData } from "../../helpers/helper";
import { ToastContainer, toast } from "react-toastify";
import { useTaskContextProvider } from "../../../utils/TaskContextProvider";
import { Spinner } from "react-bootstrap";

function PassModal(props) {
  const { isBtnLoading, setBtnLoading } = useTaskContextProvider();
  const storageInfo = storageData();
  const tokenid = storageInfo.data.token;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(updatePasswordValidationSchema),
  });
  const onSubmit = (data) => {};

  const [passwordType, setPasswordType] = useState("password");
  const [passwordTypeA, setPasswordTypeA] = useState("password");
  const [newpassword, setPassword] = useState("");
  const [confirmPass, setConfPassword] = useState("");
  const newPassword = (event) => {
    const pass = event.target.value;
    setPassword(pass);
  };
  const confirmPassword = (event) => {
    const confpass = event.target.value;
    setConfPassword(confpass);
  };
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    } else {
      setPasswordType("password");
    }
  };
  const togglePasswordA = () => {
    if (passwordTypeA === "password") {
      setPasswordTypeA("text");
      return;
    } else {
      setPasswordTypeA("password");
    }
  };
  let passwordcheck = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm;

  const passwordUpdate = async (ID) => {
    setBtnLoading(true);
    if (!newpassword.match(passwordcheck)) {
      toast.error(
        "Password must be at least 8 character with combination of uper case,special characterand number"
      );
      setBtnLoading(false);
      return false;
    } else if (newpassword !== confirmPass) {
      toast.error("Password not matched");
      setBtnLoading(false);
      return false;
    }
    try {
      const formData = {
        id: props.memberid,
        password: newpassword,
        confirm_password: confirmPass,
      };
      const Token = tokenid;
      const response = await updatePassword(formData, Token);
      toast.success(response.data.message);
      props.handleClose1();
      setBtnLoading(false);
      return false;
    } catch (error) {
      toast.success(error.data.message);
      setBtnLoading(false);
    }
  };
  return (
    <div>
      <ToastContainer />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="request_info">
            <div className="col-md-12 position-relative passwords">
              <label for="validationCustom01" className="form-label">
                New Password
              </label>
              <input
                {...register("new_pass")}
                name="new_pass"
                type={passwordType}
                className="form-control"
                placeholder="New Password"
                onChange={newPassword}
                required
              />
              {errors.new_pass && (
                <p className="error text-danger m-0">
                  {errors.new_pass.message}
                </p>
              )}
              <div className="input-group-btn pass_icon">
                <span onClick={togglePassword}>
                  {passwordType === "password" ? (
                    <i className="fa-sharp fa-solid fa-eye-slash"></i>
                  ) : (
                    <i className="fa-solid fa-eye"></i>
                  )}
                </span>
              </div>
            </div>
            <div className="col-md-12 position-relative passwords">
              <label for="validationCustom01" className="form-label">
                Confirm Password
              </label>
              <input
                {...register("confirm_pass")}
                name="confirm_pass"
                type={passwordTypeA}
                className="form-control"
                placeholder="Confirm Password"
                onChange={confirmPassword}
                required
              />
              {errors.confirm_pass && (
                <p className="error text-danger m-0">
                  {errors.confirm_pass.message}
                </p>
              )}
              <div className="input-group-btn pass_icon">
                <span onClick={togglePasswordA}>
                  {passwordTypeA === "password" ? (
                    <i className="fa-sharp fa-solid fa-eye-slash"></i>
                  ) : (
                    <i className="fa-solid fa-eye"></i>
                  )}
                </span>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <button
            type="submit"
            className="request_btn apply_btn"
            onClick={handleSubmit(passwordUpdate)}
            disabled={isBtnLoading ? isBtnLoading : false}
          >
            {!isBtnLoading ? (
              "Update"
            ) : (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </button>
        </Modal.Footer>
      </form>
    </div>
  );
}

export default PassModal;
