import React from "react";
import "./chat.css";
import user from "../../assets/images/1.webp";
import { ReactComponent as Account } from "../../assets/images/account.svg";
import { ReactComponent as EYE } from "../../assets/images/eye.svg";
import Gallery from "../../assets/images/galry.png";
import Document from "../../assets/images/document.png";
import Image from "../../assets/images/gallry.png";
import Linked from "../../assets/images/linked.png";
import File from "../../assets/images/folder.png";
import Link from "../../assets/images/links.png";
import huts from "../../assets/images/huts.png";
import eye from "../../assets/images/eye.png";
import { ReactComponent as Search } from "../../assets/images/search.svg";
import { AiOutlineSearch } from "react-icons/ai";
import LeftSide from "../../components/layouts/left_side";
import Container from "../../components/layouts/container";
import { ReactComponent as Send } from "../../assets/images/send.svg";
import { ReactComponent as Attach } from "../../assets/images/Pin-2.svg";

function Chat() {
  const btnArry = [
    {
      name: "Gallery",
      img: Image,
      data: "Some placeholder content for the collapse component. This panel is hidden by default but revealed when the user activates the relevant trigger.",
    },
    {
      name: "Documents",
      img: File,
      data: "Some placeholder content for the collapse component. This panel is hidden by default but revealed when the user activates the relevant trigger.",
    },
    {
      name: "Links",
      img: Linked,
      data: "Some placeholder content for the collapse component. This panel is hidden by default but revealed when the user activates the relevant trigger.",
    },
  ];
  return (
    <div>
      <Container>
        <div className="dashboard_main">
          <div className="main_body">
            <LeftSide />
            <div className="right_side w-100">
              <div className="container-fluid">
                <div className="dashboard_inner col-11 m-auto">
                  <div className="">
                    <div className="main_heading">
                      <h3>Messages</h3>
                    </div>
                  </div>
                  <div className="main_chat">
                    <div className="row">
                      <div className="col-lg-9">
                        <div className="chat_part">
                          <div className="row">
                            <div className="col-lg-4 col-md-4">
                              <div className="chat_list">
                                <div className="search_list">
                                  <input
                                    type="text"
                                    className="search_input w-100"
                                    placeholder="Search List"
                                  />
                                  <AiOutlineSearch />
                                </div>
                                <div className="chat_user">
                                  <div
                                    className="nav nav-pills nav_pils"
                                    id="v-pills-tab"
                                    role="tablist"
                                    aria-orientation="vertical"
                                  >
                                    <button
                                      className="chat_tabs_btn col-lg-12 col-md-12 col-6  active"
                                      id="v-pills-home-tab"
                                      data-bs-toggle="pill"
                                      data-bs-target="#v-pills-home"
                                      type="button"
                                      role="tab"
                                      aria-controls="v-pills-home"
                                      aria-selected="true"
                                    >
                                      <div className="chat_img_name">
                                        <img src={user} alt="user" />
                                        <div className="user_name">
                                          <h6>Faraz</h6>
                                          <p className="lets_start">
                                            Let's start a new
                                          </p>
                                        </div>
                                        <div className="date_number">
                                          <p>01:20AM</p>
                                          <div className="d-flex justify-content-end">
                                            <div className="chat_number">
                                              <p>2</p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </button>
                                    <button
                                      className="chat_tabs_btn col-lg-12 col-md-12 col-6 "
                                      id="v-pills-profile-tab"
                                      data-bs-toggle="pill"
                                      data-bs-target="#v-pills-profile"
                                      type="button"
                                      role="tab"
                                      aria-controls="v-pills-profile"
                                      aria-selected="false"
                                    >
                                      <div className="chat_img_name">
                                        <img src={user} alt="user" />
                                        <div className="user_name">
                                          <h6>Faraz</h6>
                                          <p className="lets_start">
                                            Let's start a new
                                          </p>
                                        </div>
                                        <div className="date_number">
                                          <p>01:20AM</p>
                                        </div>
                                      </div>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-8 col-md-8">
                              <div
                                className="tab-content"
                                id="v-pills-tabContent"
                              >
                                <div
                                  className="tab-pane fade show active"
                                  id="v-pills-home"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-home-tab"
                                  tabindex="0"
                                >
                                  <div className="profile_head">
                                    <div className="img_name">
                                      <img src={user} alt="user" />
                                      <div className="user_name">
                                        <h6>Faraz</h6>
                                        <p>Active</p>
                                      </div>
                                    </div>
                                    <div className="head_icons">
                                      <div className="me-3">
                                        <Account />
                                      </div>
                                      <div>
                                        <Search />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="user_chat">
                                    <div className="chat_date ">
                                      <p>39/2/2023</p>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-6">
                                        <div className="chat_deliver">
                                          <p>nfo?.project_id', '</p>
                                        </div>
                                      </div>
                                      <div className="col-lg-6"></div>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-6"></div>
                                      <div className="col-lg-6">
                                        <div className="chat_send">
                                          <p>
                                            nfo?.project_id',
                                            'taskInfo?.task_description',
                                            'taskInfo?.task_type',
                                            'taskInfo?.to_date_report', and
                                            'type'. Either include them or
                                            remove the
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="send_field">
                                    <div className="attached_file position-relative">
                                      <div
                                        className="file_dropdown nav-link dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                        href="#"
                                        role="button"
                                        aria-expanded="false"
                                      >
                                        <Attach />
                                      </div>
                                      <ul className="dropdown-menu">
                                        <li>
                                          <div
                                            className="dropdown-item"
                                            href="#"
                                          >
                                            <div className="doc_drop">
                                              <img src={Gallery} alt="" />
                                              Gallery
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div
                                            className="dropdown-item"
                                            href="#"
                                          >
                                            <div className="doc_drop">
                                              <img src={Document} alt="" />
                                              Documents
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div
                                            className="dropdown-item"
                                            href="#"
                                          >
                                            <div className="doc_drop">
                                              <img src={Link} alt="" />
                                              Link
                                            </div>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                    <div className="send_input w-100">
                                      <input
                                        type="text"
                                        className="form-control comment_field message_field"
                                        placeholder="Write something"
                                        value=""
                                      />
                                      <div className="send_svg">
                                        <button
                                          type="button"
                                          className="send_coment"
                                        >
                                          <Send />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="chat_part">
                          <div className="border_heading">
                            <h6>Find Previous Documents</h6>
                          </div>
                          <div className="document_list">
                            <div className="list_btns">
                              {btnArry.map((post) => {
                                return (
                                  <>
                                    <div
                                      className="buttons_tags"
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target={`#${post.name}`}
                                      aria-expanded="false"
                                      aria-controls={post.name}
                                    >
                                      <div className="doc_drop">
                                        <img src={post.img} alt="" />
                                        {post.name}
                                      </div>
                                    </div>
                                    <div className="collapse" id={post.name}>
                                      <div className="card card-body p-0 border-0">
                                        <div className="uper_line">
                                          <EYE />
                                          <p>
                                            click this icon to reveal in chat
                                          </p>
                                        </div>
                                        <div className="image_view">
                                          <div className="row mt-3">
                                            <div className="col-lg-6 col-md-3 image_col">
                                              <div className="view_img position-relative">
                                                <div className="eye_img">
                                                  <img src={eye} alt="" />
                                                </div>
                                                <img src={huts} alt="" />
                                              </div>
                                            </div>
                                            <div className="col-lg-6 col-md-3 image_col">
                                              <div className="view_img position-relative">
                                                <img src={huts} alt="" />
                                              </div>
                                            </div>
                                            <div className="col-lg-6 col-md-3 image_col">
                                              <div className="view_img position-relative">
                                                <img src={huts} alt="" />
                                              </div>
                                            </div>
                                            <div className="col-lg-6 col-md-3 image_col">
                                              <div className="view_img position-relative">
                                                <img src={huts} alt="" />
                                              </div>
                                            </div>{" "}
                                            <div className="col-lg-6 col-md-3 image_col">
                                              <div className="view_img position-relative">
                                                <img src={huts} alt="" />
                                              </div>
                                            </div>{" "}
                                            <div className="col-lg-6 col-md-3 image_col">
                                              <div className="view_img position-relative">
                                                <img src={huts} alt="" />
                                              </div>
                                            </div>{" "}
                                            <div className="col-lg-6 col-md-3 image_col">
                                              <div className="view_img position-relative">
                                                <img src={huts} alt="" />
                                              </div>
                                            </div>{" "}
                                            <div className="col-lg-6 col-md-3 image_col">
                                              <div className="view_img position-relative">
                                                <img src={huts} alt="" />
                                              </div>
                                            </div>{" "}
                                            <div className="col-lg-6 col-md-3 image_col">
                                              <div className="view_img position-relative">
                                                <img src={huts} alt="" />
                                              </div>
                                            </div>{" "}
                                            <div className="col-lg-6 col-md-3 image_col">
                                              <div className="view_img position-relative">
                                                <img src={huts} alt="" />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        {/* {post.data} */}
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Chat;
