import React, { useEffect, useState } from "react";
import LeftSide from "../../components/layouts/left_side";
import Container from "../../components/layouts/container";
import { memberDashboard } from "../../api/members";
import { storageData } from "../../components/helpers/helper";
import GeneralSpinner from "../../components/spiner/Spiner";
import { useTaskContextProvider } from "../../utils/TaskContextProvider";

function MoreTasks() {
  const storageInfo = storageData();
  const [membersdata, setMemberData] = useState([]);
  const { setLoading, loading } = useTaskContextProvider();

  const onSubmit = async () => {
    setLoading(true);
    try {
      const formData = {};
      const memberData = await memberDashboard(
        formData,
        storageInfo?.data?.token
      );
      setMemberData(memberData.data.data);
      setLoading(false);

      return false;
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    onSubmit();
  }, []);
  return (
    <div>
      <Container>
        <div className="dashboard_main">
          <div className="main_body">
            <LeftSide />
            <div className="right_side w-100">
              <div className="container-fluid">
                <div className="dashboard_inner col-11 m-auto">
                  <div className="row">
                    {!loading ? (
                      membersdata.map((post) => (
                        <div
                          className="col-lg-3 col-md-3 col-sm-6 col-12 mt-3"
                          key={post.id}
                        >
                          <div className="in_card">
                            <div className="card_heading">
                              <h6
                                className="c1"
                                style={{
                                  color: post.color_code,
                                  textTransform: "capitalize",
                                }}
                              >
                                {post.status_name} <br /> Tasks
                              </h6>
                            </div>
                            <div className="numbers">
                              <h6
                                className="c1"
                                style={{ color: post.color_code }}
                              >
                                {post.total_tasks}
                              </h6>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <GeneralSpinner />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default MoreTasks;
