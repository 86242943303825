import React, { useState } from "react";
import profile from "../../../assets/images/profile.png";
import { Modal, Spinner } from "react-bootstrap";
import { allRole, singleMember, updateMember } from "../../../api/members";
import { storageData } from "../../helpers/helper";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { getCompanyDepartment } from "../../../api/department";
import MobileSite from "./MobileSite";
import DropDown from "./DropDown";
import PassModal from "./PassModal";
import { useTaskContextProvider } from "../../../utils/TaskContextProvider";
import { editmemberValidationSchema } from "./memberValidation";
import { yupResolver } from "@hookform/resolvers/yup";
function MemberContent(props) {
  const storageInfo = storageData();

  const { setConfPassword, setPassword, isBtnLoading, setBtnLoading } =
    useTaskContextProvider();
  const baseURL = process.env.REACT_APP_URL;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(editmemberValidationSchema),
  });
  const onSubmit = async (data) => {};

  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow1 = () => setShow1(true);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow2 = () => setShow2(true);
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const [memberid, memberID] = useState("");
  const [udid, setUDID] = useState("");
  const [RoleID, setRoleID] = useState("");
  const [departid, setDID] = useState("");
  const [selectdepartid, setSelectDID] = useState("");
  const [cpompdepart, compDepartData] = useState([]);
  const [membername, setSingleMemberName] = useState("");
  const tokenid = storageInfo.data.token;
  const companyid = storageInfo.data.company_info.id;
  const handleClick = (event) => {
    const rid = event.currentTarget.id;
    memberID(rid);
    showMemberData(rid);
  };
  const handleName = (event) => {
    const rid = event.target.value;
    setSingleMemberName(rid);
  };
  const valueArr = [];
  cpompdepart.forEach((employee) => {
    valueArr.push({
      value: `${employee.user_department_id}`,
      label: `${employee.tmp_department_name}`,
      departId: `${employee.id}`,
    });
  });
  valueArr.unshift({ value: null, label: "None", departId: null });
  const options = valueArr;

  const selectedOption = options.find(
    (option) => option.value == selectdepartid
  );

  const [allrole, setAllRole] = useState([]);
  const roleArry = [];
  allrole.forEach((employee) => {
    roleArry.push({
      value: `${employee.role_id}`,
      label: `${employee.role_name}`,
    });
  });
  roleArry.unshift({ value: null, label: "None" });
  const roleoptions = roleArry;
  const roleselectedOption = roleoptions.find(
    (option) => option.value == RoleID
  );
  const handleCode2 = (event) => {
    setRoleID(event.value);
  };
  const showCompanyDeparts = async () => {
    try {
      const formData = {
        id: companyid,
      };
      const Token = tokenid;
      const response = await getCompanyDepartment(formData, Token);
      const data = response.data.data ? response.data.data : "";
      compDepartData(data);
      return false;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const showMemberData = async (MID) => {
    try {
      const formData = {
        id: MID,
      };
      const Token = tokenid;
      const response = await singleMember(formData, Token);
      const data = response.data.data ? response.data.data : "";
      setSingleMemberName(data.full_name);
      setUDID(data.u_did);
      setSelectDID(data.u_did);
      setDID(data.department_id);
      setRoleID(data.role_id);
      return false;
    } catch (error) {}
  };
  const showAllRole = async (departmentId) => {
    try {
      const formData = {
        dpt_id: departmentId,
      };
      const Token = tokenid;
      const response = await allRole(formData, Token);
      const data = response.data.data ? response.data.data : "";
      setAllRole(data);
      return false;
    } catch (error) {}
  };
  const showModal = (TUDID) => {
    showCompanyDeparts();
    handleShow();
    showAllRole(TUDID);
  };
  const handleDepart = (selectedOption) => {
    if (selectedOption) {
      const departmentId = selectedOption.departId;
      const userdepartmentId = selectedOption.value;
      setSelectDID(departmentId);
      setUDID(userdepartmentId);
      setDID(departmentId);
      showAllRole(departmentId);
    } else {
      showAllRole(null);
    }
  };

  const addMember = async () => {
    if (RoleID === null || roleselectedOption === undefined) {
      toast.error("Select a role");
      return false;
    }

    setBtnLoading(true);
    try {
      const formData = {
        full_name: membername,
        department_id: departid,
        role_id: RoleID,
        id: memberid,
        u_did: udid,
      };

      const Token = tokenid;
      const response = await updateMember(formData, Token);
      const updatedMember = response.data.data;

      const updatedMemberDetails = props.memberdetail.map((member) => {
        if (member.id === updatedMember.id) {
          return updatedMember;
        } else {
          return member;
        }
      });

      props.setMembers(updatedMemberDetails);

      handleClose();
      setBtnLoading(false);
      toast.success(response.data.message);
      return false;
    } catch (error) {
      setBtnLoading(false);
      toast.error(error.response.data.message);
    }
  };

  const updatepass = () => {
    setPassword("");
    setConfPassword("");
    handleShow1();
  };
  return (
    <div
      className={
        props.memberdetail.length >= 6
          ? "memberlist mt-3 list_height"
          : "memberlist mt-3"
      }
    >
      {props.memberdetail.map((post) => {
        return (
          <div className="depart_row">
            <div className="row detail_row">
              <div className="col-lg-2 col-3">
                <div className="image_name">
                  <img
                    src={
                      post.profile_image === null
                        ? profile
                        : `${baseURL}${post.profile_image}`
                    }
                    alt="User"
                  />
                  <p>{post.full_name}</p>
                </div>
              </div>
              <div className="col-lg-3 email_head">
                <div className="email">
                  <p>{post.email}</p>
                </div>
              </div>
              <div className="col-lg-2 col-3">
                <div className="level">
                  <p>{post.role_name}</p>
                </div>
              </div>
              <div className="col-lg-2 col-3 depart_name">
                <p>{post.tmp_department_name}</p>
              </div>
              <div className="col-2"></div>
              <DropDown
                assignedPermissions={props.assignedPermissions}
                post={post.id}
                DID={post.did}
                click={handleClick}
                model={showModal}
                show1={updatepass}
                show2={handleShow2}
              />
            </div>
          </div>
        );
      })}
      <MobileSite mobiledata={props.memberdetail} />
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <div className="model_heading">
            <h1 className="modal-title" id="staticBackdropLabel">
              Edit member
            </h1>
            <p>Here you can edit members</p>
          </div>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <div className="request_info">
              <div className="col-md-12">
                <label for="validationCustom01" className="form-label">
                  Name
                </label>
                <input
                  {...register("member_name")}
                  name="member_name"
                  type="text"
                  className="form-control"
                  placeholder="Your Name"
                  onChange={handleName}
                  value={membername}
                />
                {errors.member_name && (
                  <p className="error text-danger m-0">
                    {errors.member_name.message}
                  </p>
                )}
              </div>
              <div className="col-md-12 pt-2">
                <label for="validationCustom01" className="form-label">
                  Select Department
                </label>
                <Select
                  placeholder="Select Department"
                  options={options}
                  onChange={handleDepart}
                  required
                  value={selectedOption}
                />
              </div>
              <div className="col-md-12 pt-2">
                <label for="validationCustom01" className="form-label">
                  Select Role
                </label>
                <Select
                  placeholder="Select Role"
                  options={roleoptions}
                  onChange={handleCode2}
                  value={roleselectedOption}
                  required
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="justify-content-center mt-2">
            <button className="cancel_btn" onClick={handleClose}>
              Cancel
            </button>
            <button
              type="submit"
              className="request_btn apply_btn"
              onClick={handleSubmit(addMember)}
              disabled={isBtnLoading}
            >
              {!isBtnLoading ? (
                "Update"
              ) : (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
      <Modal
        show={show1}
        onHide={handleClose1}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <div className="model_heading">
            <h1 className="modal-title" id="staticBackdropLabel">
              Update Password
            </h1>
            <p>Here you can update password</p>
          </div>
        </Modal.Header>
        <PassModal handleClose1={handleClose1} memberid={memberid} />
      </Modal>
      <Modal
        show={show2}
        onHide={handleClose2}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <div className="assign_task_form d-flex justify-content-center align-baseline realy_want">
            <i className="fa-solid fa-triangle-exclamation"></i>
            <h6>Do you realy want to delete this member?</h6>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <button className="cancel_btn" onClick={handleClose2}>
            NO
          </button>
          <button
            type="button"
            className="request_btn apply_btn"
            // onClick={removeCard}
          >
            YES
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default MemberContent;
