import React from "react";
import MemberContent from "./MemberContent";
function TabContent(props) {
  return (
    <div>
      <div className="tab-content" id="v-pills-tabContent">
        <MemberContent
          assignedPermissions={props.assignedPermissions}
          memberdetail={props.tabdata}
          depID={props.depID}
          apifunction={props.apifunction}
          setMembers={props.setMembers}
        />
      </div>
    </div>
  );
}
export default TabContent;
