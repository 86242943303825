import React from "react";
import { ReactComponent as Dots } from "../../assets/images/dots.svg";

function DepartmentTable({ dataset, handleShow2, editCard, handleClick }) {
  return (
    <div className="departmentlist mt-3">
      {dataset.map((post, index) => {
        return (
          <div className="depart_row">
            <div className="row detail_row">
              <div className="col-8">
                <p>{post.name}</p>
              </div>
              <div className="col-4 text-center">
                <div className="drop_btn">
                  <button
                    className="dots_btn dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    onClick={handleClick}
                    id={post.id}
                  >
                    <Dots />
                  </button>
                  <ul className="dropdown-menu">
                    <li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={editCard}
                      >
                        Edit
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={handleShow2}
                      >
                        Remove Department
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default DepartmentTable;
