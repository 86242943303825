import axios from "axios";
const baseURL = process.env.REACT_APP_API_URL;
export const API = axios.create({
  baseURL: baseURL,
});

export const getMembers = async (reqData, token) => {
  const url = "member/allMember";
  return handleRequest(url, "post", reqData, token);
};
export const inviteMembers = async (reqData, token) => {
  const url = "member/invite";
  return handleRequest(url, "post", reqData, token);
};
export const allRole = async (reqData, token) => {
  const url = "member/all-roles";
  return handleRequest(url, "post", reqData, token);
};
export const singleMember = async (reqData, token) => {
  const url = "member/single-member";
  return handleRequest(url, "post", reqData, token);
};
export const memberDashboard = async (reqData, token) => {
  const url = "member/dashboard";
  return handleRequest(url, "post", reqData, token);
};
export const updateMember = async (reqData, token) => {
  const url = "member/update-member";
  return handleRequest(url, "post", reqData, token);
};
export const updatePassword = async (reqData, token) => {
  const url = "member/update-pswd";
  return handleRequest(url, "post", reqData, token);
};
const handleRequest = async (url, method, reqData = {}, token = null) => {
  try {
    return await API({
      method: method,
      url: url,
      data: reqData,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  } catch (error) {
    throw error;
  }
};
