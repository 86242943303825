import React from "react";
import { Link } from "react-router-dom";
function ProjectInsightBar() {
  return (
    <div className="board_header">
      <div className="main_heading">
        <h3>Insight</h3>
        <p>Let's see your project's health based on tasks, and your team
          progress.</p>
      </div>
      <div className="more_time">
        <Link className="btn request_btn w-100" to="/projects">
          Back to projects
        </Link>
      </div>
    </div>
  );
}
export default ProjectInsightBar;
