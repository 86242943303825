import React from "react";
import "./profile.css";
import LeftSide from "../../components/layouts/left_side";
import Container from "../../components/layouts/container";
import AdminProfile from "./adminprofile/AdminProfile";
import MemberProfile from "./memberprofile/MemberProfile";
import { storageData } from "../../components/helpers/helper";
function ProfileInfo() {
  const storageInfo = storageData();
  const userType = storageInfo.data.user_type;
  return (
    <div>
      <Container>
        <div className="dashboard_main">
          <div className="main_body">
            <LeftSide />
            <div className="right_side w-100">
              <div className="container-fluid">
                <div className="dashboard_inner col-11 m-auto">
                  <div className="">
                    <div className="main_heading">
                      <h3>Welcome To My Profile</h3>
                      <p>
                        Here you can edit your profile picture, your name and
                        your passwords
                      </p>
                    </div>
                    <div className="tabs_buttons">
                      <nav>
                        <div
                          className="nav nav-tabs border_line"
                          id="nav-tab"
                          role="tablist"
                        >
                          <button
                            className="nav-link profile_links active"
                            id="nav-home-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-home"
                            type="button"
                            role="tab"
                            aria-controls="nav-home"
                            aria-selected="true"
                          >
                            Personal Info
                          </button>
                          {/* <button
                            className="nav-link profile_links"
                            id="nav-profile-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-profile"
                            type="button"
                            role="tab"
                            aria-controls="nav-profile"
                            aria-selected="false"
                          >
                            About
                          </button> */}
                          <button
                            className="nav-link profile_links"
                            id="nav-contact-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-contact"
                            type="button"
                            role="tab"
                            aria-controls="nav-contact"
                            aria-selected="false"
                          >
                            Security
                          </button>
                        </div>
                      </nav>
                    </div>
                  </div>
                  {userType === "admin" ? <AdminProfile /> : <MemberProfile />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default ProfileInfo;
