import moment from "moment";
import React from "react";
function PlanHistoryContent(props) {
  return (
    <div>
      <div className="table_history">
        <div className="row">
          <div className="col-lg-3 col-3 text-center">
            <p>Plan Name</p>
          </div>
          <div className="col-lg-3 col-3 text-center">
            <p>Start Date</p>
          </div>
          <div className="col-lg-3 col-3 text-center">
            <p>End Date</p>
          </div>
          <div className="col-lg-3 col-3 text-center">
            <p>Status</p>
          </div>
        </div>
      </div>
      <div className="plan_history_content">
        {props.datahistory.map((post, index) => {
          return (
            <div className="header_contents">
              <div className="row detail_row">
                <div className="col-lg-3 col-3 text-center">
                  <p>{post.plan_name}</p>
                </div>
                <div className="col-lg-3 col-3 text-center">
                  <p className="history_date">
                    {moment(post.subscription_start_date).format("DD/MM/YYYY")}
                  </p>
                </div>
                <div className="col-lg-3 col-4 text-center">
                  <p className="history_date">
                    {moment(post.subscription_end_date).format("DD/MM/YYYY")}
                  </p>
                </div>
                <div className="col-lg-3 col-2 text-center">
                  <p>{post.status}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default PlanHistoryContent;
