import { toast } from "react-toastify";
import {
  createStatus,
  createTask,
  deleteTask,
  getAttachments,
  getChecklist,
  getComments,
  getTaskDetails,
} from "../../api/task";
import { formatDate, storageData } from "../../components/helpers/helper";

export const handleAction = async (action, data) => {
  const storageInfo = storageData();
  try {
    const response = await action(data, storageInfo.data.token);
    return response.data;
  } catch (error) {
    console.error("An error occurred in handleAction:", error);
    throw error;
  }
};

export const getTaskDetailsHelper = async (tid, pid) => {
  const data = {
    task_id: tid,
    project_id: pid,
  };
  return handleAction(getTaskDetails, data);
};

export const getChecklistHelper = async (taskId) => {
  const data = {
    task_id: taskId,
  };
  return handleAction(getChecklist, data);
};

export const getAttachmentsHelper = async (taskId) => {
  const data = {
    task_id: taskId,
  };
  return handleAction(getAttachments, data);
};

export const getCommentsHelper = async (taskId) => {
  const data = {
    id: taskId,
  };
  return handleAction(getComments, data);
};

export const createTaskHelper = async (formData) => {
  return handleAction(createTask, formData);
};

export const handlePrioritySelectHelper = async (
  priority,
  taskId,
  projectId,
  departIdasInt,
  createAction
) => {
  try {
    const formData = {
      id: taskId,
      project_id: projectId,
      department_id: departIdasInt,
      task_type: priority,
    };

    const response = await handleAction(createAction, formData);
    return response;
  } catch (error) {
    console.error("An error occurred in handlePrioritySelectHelper:", error);
    throw error;
  }
};

export const handleDatesHelper = async (
  dates,
  taskId,
  projectId,
  departIdasInt,
  isMember,
  setTaskId,
  handleShowRequestTimeModal
) => {
  try {
    const startDate = dates !== null && formatDate(dates[0]);
    const endDate = dates !== null && formatDate(dates[1]);

    const formData = {
      id: taskId,
      project_id: projectId,
      department_id: departIdasInt,
      from_date_report: startDate,
      to_date_report: endDate,
    };

    const response = await createTaskHelper(formData);
    toast.success(response.data.message);

    if (dates) {
      if (isMember !== null) {
        setTaskId(taskId);
        handleShowRequestTimeModal(formData);
      } else {
        const response = await createTaskHelper(formData);
        toast.success(response.data.message);
      }
    }
  } catch (error) {
    console.error("An error occurred in handleDatesHelper:", error);
  }
};

export const deleteTaskHelper = async (taskId) => {
  const data = {
    id: taskId,
  };
  return handleAction(deleteTask, data);
};

export const handleRemoveMemberHelper = async (
  id,
  taskId,
  projectId,
  membersData,
  setSelectValues,
  departIdasInt,
  setMembersData
) => {
  try {
    const removedMembersData = membersData.filter(
      (user) => user.user_id !== id
    );
    const userIdArray = removedMembersData.map((user) => user.user_id);
    setSelectValues(userIdArray);

    const formData = new FormData();
    formData.append("id", taskId);
    formData.append("project_id", projectId);
    formData.append("department_id", departIdasInt);
    formData.append("member", JSON.stringify(userIdArray));

    const response = await createTaskHelper(formData);
    setMembersData(userIdArray);
    toast.success(response.data.message);
  } catch (error) {
    console.error("An error occurred in handleRemoveMemberHelper:", error);
  }
};

export const handleNameEditHelper = async (
  taskId,
  projectId,
  taskName,
  departIdasInt,
  getBoardProjects,
  setIsEditTaskName
) => {
  try {
    const formData = {
      id: taskId,
      project_id: projectId,
      department_id: departIdasInt,
      name: taskName,
    };

    const response = await createTaskHelper(formData);
    setIsEditTaskName(false);
    getBoardProjects(taskId, projectId);
    toast.success(response.data.message);
  } catch (error) {
    console.error("An error occurred in handleEnterKeyHelper:", error);
  }
};

export const saveMemberHelper = async (
  tid,
  projectId,
  departIdasInt,
  selectValues,
  setMembersData
) => {
  try {
    const formData = {
      id: tid,
      project_id: projectId,
      department_id: departIdasInt,
      member: JSON.stringify(selectValues),
    };

    const response = await createTaskHelper(formData);

    const newMemberData = {
      user_id: response.data.u_id,
      user_name: response.data.full_name,
    };

    setMembersData([newMemberData]);
    toast.success(response.data.message);
  } catch (error) {
    console.error("An error occurred in saveMemberHelper:", error);
  }
};

export const addNewTaskHelper = async (
  projectIdParams,
  selectedProjectValue,
  departIdasInt,
  taskName,
  statusId,
  getBoardProjects,
  setDivs
) => {
  try {
    const formData = {
      project_id:
        projectIdParams === undefined
          ? parseInt(selectedProjectValue, 10)
          : projectIdParams,
      department_id: departIdasInt,
      name: taskName,
      task_status_id: statusId,
    };

    const response = await createTaskHelper(formData);
    toast.success(response.message);
    getBoardProjects();
    setDivs([]);
  } catch (error) {
    console.error("An error occurred in addNewTaskHelper:", error);
    throw error;
  }
};

export const createStatusHelper = async (formData) => {
  return handleAction(createStatus, formData);
};

export const handleCreateStatusHelper = async (
  e,
  data,
  projectId,
  selectedColor,
  newStatusName,
  setNewStatusName,
  getBoardProjects,
  setAddStatus
) => {
  try {
    if (e.key === "Enter" && newStatusName.trim() !== "") {
      const formData = {
        ...(data !== undefined && { id: data.status_id }),
        status_name: newStatusName,
        project_id: projectId,
        color_code: selectedColor,
      };

      const response = await createStatusHelper(formData);
      toast.success(response.message);
      setNewStatusName("");
      getBoardProjects();
      setAddStatus(false);
    }
  } catch (error) {
    console.error("An error occurred in handleEnterKeyHelper:", error);
  }
};
