import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import "../../pages/task/task.css";
import { toast } from "react-toastify";
import {
  GetMembersForTasks,
  GetProjectTasks,
  GetProjectsAndTasks,
  GetProjectsForTasks,
  GetTasksForBoard,
  GetTasksList,
  deleteTask,
} from "../../api/task";
import { AiOutlineSearch } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";
import ProjectTaskBar from "../../components/projects/tasks/ProjectsTasksBar";

import Container from "../../components/layouts/container";
import LeftSide from "../../components/layouts/left_side";
import { storageData } from "../../components/helpers/helper";
import { useNavigate } from "react-router";
import { accessDept } from "../../api/department";
import { useTaskContextProvider } from "../../utils/TaskContextProvider";
import { Modal } from "react-bootstrap";
import DeleteModal from "../../components/projects/DeleteModal";
import { useParams } from "react-router-dom";
import TaskListData from "../../components/task/TaskListData";
import DraggableComponent from "../../components/task/DraggableComponent";
import TaskModal from "../../components/task/taskmodals/TaskModal";

function Task() {
  const {
    allDeptUser,
    setUserDepId,
    setProjectlist,
    setTasklist,
    setProjectID,
    setActiveTab,
    departIdasInt,
    setProjectlistForTasks,
    setProjectlistAndTasks,
    setMemberlistForTasks,
    show2,
    handleClose2,
    taskId,
    handleClose1,
    show1,
    setTaskListBoard,
    setLoading,
    projectlistTask,
  } = useTaskContextProvider();
  const { projectId } = useParams();
  const [tabName, setTabName] = useState("list");

  const storageInfo = storageData();
  const navigate = useNavigate();

  const getProjects = async () => {
    setLoading(true);
    const formData = {
      department_id: JSON.parse(departIdasInt),
    };
    const response = await GetProjectsForTasks(
      formData,
      storageInfo.data.token
    );
    setProjectlistForTasks(response.data.data);
    setLoading(false);
  };

  const ProjectsandTasks = async () => {
    setLoading(true);
    const formData = {
      department_id: JSON.parse(departIdasInt),
      project_id: projectId,
    };
    const response = await GetProjectsAndTasks(
      formData,
      storageInfo.data.token
    );
    setProjectlistAndTasks(response.data);
    setLoading(false);
  };
  const getMembers = async () => {
    const formData = {
      department_id: JSON.parse(departIdasInt),
    };
    const response = await GetMembersForTasks(formData, storageInfo.data.token);
    setMemberlistForTasks(response.data.data);
  };

  const checkDeptAccess = async () => {
    try {
      const formData = {
        id: storageInfo.data.id,
        department_id: JSON.parse(departIdasInt),
        company_id: storageInfo.data.company_info.id,
      };
      const allowDepts = await accessDept(formData, storageInfo.data.token);
      if (allowDepts.data.success === false) {
        navigate("/404");
      } else {
        // localStorage.setItem("did", JSON.stringify(allowDepts.data.data.did));
        // localStorage.setItem("u_did", JSON.stringify(allowDepts.data.data.u_did));
        allDeptUser(storageInfo.data.userDepartments);
        setUserDepId(allowDepts.data.data.u_did);
        try {
          const projectTaskData = {
            department_id: allowDepts?.data?.data?.u_did,
            project_id: projectId,
          };

          const getProjectList = await GetProjectTasks(
            projectTaskData,
            storageInfo?.data?.token
          );
          if (getProjectList.data.success === true) {
            if (getProjectList?.data?.data?.length > 0) {
              setProjectlist(getProjectList?.data?.data);
            }
            const projectID = getProjectList?.data?.data[0]["id"];
            setActiveTab("to do");
            const taskData = {
              u_id: storageInfo?.data?.id,
              p_id: projectID,
              task_status: "to do",
              company_id: storageInfo?.data?.company_info?.id,
            };
            setProjectID(projectID);
            const getTasks = await GetTasksList(
              taskData,
              storageInfo?.data?.token
            );
            if (getTasks?.data?.data?.length > 0) {
              setTasklist(getTasks?.data?.data);
            }
          }
        } catch (error) {
          toast.error(error.response?.data?.message);
        }
      }
    } catch (error) {}
  };
  useEffect(() => {
    checkDeptAccess();
    ProjectsandTasks();
    getMembers();
    getProjects();
  }, []);

  const handleClickList = () => {
    setTabName("list");
    ProjectsandTasks();
  };

  const removeTask = async () => {
    try {
      const formData = {
        id: taskId,
      };
      const Token = storageInfo.data.token;
      const response = await deleteTask(formData, Token);
      checkDeptAccess();
      handleClose2();
      toast.success(response.data.message);
      return false;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  const [searchName, setSearchName] = useState("");
  const searchHandler = (e) => {
    setSearchName(e.target.value);
    GetSearchTaskProjects(e.target.value);
  };

  const GetSearchTaskProjects = async (name) => {
    const taskData = {
      department_id: JSON.parse(departIdasInt),
      project_id: projectId,
      search: name,
    };
    if (name.length >= 3) {
      if (tabName === "list") {
        const getTasks = await GetProjectsAndTasks(
          taskData,
          storageInfo.data.token
        );
        setProjectlistAndTasks(getTasks.data);
      } else {
        setLoading(true);
        const tasksListForBoard = await GetTasksForBoard(
          taskData,
          storageInfo?.data?.token
        );
        setTaskListBoard(tasksListForBoard.data.data);
        setLoading(false);
      }
      return false;
    } else if (name.length === 0) {
      if (tabName === "list") {
        const getTasks = await GetProjectsAndTasks(
          taskData,
          storageInfo.data.token
        );
        setProjectlistAndTasks(getTasks.data);
      } else {
        setLoading(true);
        const tasksListForBoard = await GetTasksForBoard(
          taskData,
          storageInfo?.data?.token
        );
        setTaskListBoard(tasksListForBoard.data.data);
        setLoading(false);
      }
    }
  };
  const removeInput = () => {
    setSearchName("");
    GetSearchTaskProjects("");
  };

  // ------------Board Projects------------
  const pathname = window.location.pathname;
  const parts = pathname.split("/");
  const getBoardProjects = async () => {
    setTabName("board");

    setLoading(true);
    try {
      const formData = {
        department_id: departIdasInt,
        project_id: parts[3] ? parts[3] : null,
      };
      const tasksListForBoard = await GetTasksForBoard(
        formData,
        storageInfo?.data?.token
      );
      setTaskListBoard(tasksListForBoard.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.message);
    }
  };
  return (
    <div>
      <Container>
        <div className="dashboard_main">
          <div className="main_body">
            <LeftSide />
            <div className="right_side w-100">
              <div className="container-fluid">
                <div className="dashboard_inner col-11 m-auto">
                  <ProjectTaskBar projectlistTask={projectlistTask} />
                  <div className="view_tabs mt-3">
                    <div className="task_head">
                      <div className="nav nav-tabs" id="myTab" role="tablist">
                        <button
                          className="nav-link task_links active"
                          id="profile-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#profile-tab-pane"
                          type="button"
                          role="tab"
                          aria-controls="profile-tab-pane"
                          aria-selected="false"
                          onClick={handleClickList}
                        >
                          List
                        </button>
                        <button
                          className="nav-link task_links "
                          id="home-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#home-tab-pane"
                          type="button"
                          role="tab"
                          aria-controls="home-tab-pane"
                          aria-selected="true"
                          onClick={getBoardProjects}
                        >
                          Board
                        </button>
                      </div>
                      <div className="filter_field">
                        <div className="search_filter_field">
                          <input
                            type="text"
                            className="search_input"
                            placeholder="Search List"
                            value={searchName}
                            onChange={searchHandler}
                          />
                          {searchName === "" ? (
                            <AiOutlineSearch />
                          ) : (
                            <RxCross2 onClick={removeInput} />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <TabsHead /> */}
                  <div className="tab-content" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="profile-tab-pane"
                      role="tabpanel"
                      aria-labelledby="profile-tab"
                      tabindex="0"
                    >
                      <TaskListData
                        ProjectsandTasks={ProjectsandTasks}
                        checkDeptAccess={checkDeptAccess}
                      />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="home-tab-pane"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                      tabindex="0"
                    >
                      <DraggableComponent getBoardProjects={getBoardProjects} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>

      <Modal
        show={show2}
        onHide={handleClose2}
        backdrop="static"
        keyboard={false}
      >
        <DeleteModal
          title="task"
          handleClose2={handleClose2}
          removeCard={removeTask}
        />
      </Modal>

      <Modal
        size="lg"
        show={show1}
        onHide={handleClose1}
        backdrop="static"
        keyboard={false}
      >
        <TaskModal checkDeptAccess={checkDeptAccess} />
      </Modal>
    </div>
  );
}

export default Task;
