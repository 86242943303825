import React, { useEffect, useState } from "react";
import "./Analytic.css";

import { Link } from "react-router-dom";
import SubmitTime from "./submittime/submitTime";
import RequestTime from "./request/requestTime";
import { Chart } from "react-google-charts";
import { ReactComponent as Arrow } from "../../assets/images/arrow.svg";
import { ReactComponent as Question } from "../../assets/images/question.svg";
import Timer from "../../components/timer/timer";
import Dropdown from "../../components/dropdown/dropdown";
import { storageData } from "../../components/helpers/helper";
import { memberDashboard } from "../../api/members";
import { Spinner } from "react-bootstrap";
function Analytic() {
  const storageInfo = storageData();
  const [membersdata, setMemberData] = useState([]);
  const [cardloading, setCarLoading] = useState(false);
  const companyData = storageInfo.data.company_info;
  const userType = storageInfo.data.user_type;
  const arr = [
    "1 day",
    "2 days",
    "3 days",
    "4 days",
    "5 days",
    "6 days",
    "7 days",
  ];
  const onSubmit = async (data) => {
    setCarLoading(true);
    try {
      const formData = {};
      const memberData = await memberDashboard(
        formData,
        storageInfo?.data?.token
      );
      setMemberData(memberData.data.data);
      setCarLoading(false);

      return false;
    } catch (error) {
      setCarLoading(false);
    }
  };
  useEffect(() => {
    onSubmit();
  }, []);

  return (
    <div>
      {userType === "superadmin" ? (
        ""
      ) : (
        <div className="board_header">
          <div className="main_heading">
            <h3>Welcome to {companyData.name}</h3>
            <p>Here you can manage and see your work performance </p>
          </div>
        </div>
      )}
      <div className="board_parts_main">
        <div className="days_drop">
          <Dropdown options={arr} />
        </div>
        <div className="cards">
          <div className="row">
            {!cardloading ? (
              membersdata.length > 3 ? (
                <>
                  {membersdata.slice(0, 3).map((post) => {
                    return (
                      <div
                        className="col-lg-3 col-md-3 col-sm-6 col-12"
                        key={post.id}
                      >
                        <div className="in_card">
                          <div className="card_heading">
                            <h6
                              className="c1"
                              style={{
                                color: post.color_code,
                                textTransform: "capitalize",
                              }}
                            >
                              {post.status_name} <br /> Tasks
                            </h6>
                            {/* <p>Last 7 days</p> */}
                          </div>
                          <div className="numbers">
                            <h6
                              className="c1"
                              style={{
                                color: post.color_code,
                              }}
                            >
                              {post.total_tasks}
                            </h6>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                    <Link to="/more-tasks">
                      <div className="in_card">
                        <div className="card_heading">
                          <h6 className="c2">
                            Show More
                            <br /> Tasks
                          </h6>
                        </div>
                        <div className="numbers">
                          <h6 className="c2">{membersdata.length - 3}</h6>
                        </div>
                      </div>
                    </Link>
                  </div>
                </>
              ) : (
                membersdata.map((post) => (
                  <div
                    className={
                      membersdata.length === 3
                        ? "col-lg-4 col-md-4 col-sm-6 col-12"
                        : "col-lg-3 col-md-3 col-sm-6 col-12"
                    }
                    key={post.id}
                  >
                    <div className="in_card">
                      <div className="card_heading">
                        <h6
                          className="c1"
                          style={{
                            color: post.color_code,
                            textTransform: "capitalize",
                          }}
                        >
                          {post.status_name} <br /> Tasks
                        </h6>
                      </div>
                      <div className="numbers">
                        <h6 className="c1" style={{ color: post.color_code }}>
                          {post.total_tasks}
                        </h6>
                      </div>
                    </div>
                  </div>
                ))
              )
            ) : (
              <div className="d-flex justify-content-center">
                <Spinner
                  as="span"
                  animation="grow"
                  role="status"
                  aria-hidden="true"
                />
              </div>
            )}
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-lg-4 col-md-6">
            <div className="graph_part">
              <div className="graph_top">
                <h1>My Analytics</h1>
                <Dropdown options={arr} />
              </div>
              <div>
                <Chart
                  chartType="LineChart"
                  data={[
                    ["date", "Position:"],
                    ["", 0],
                    ["", 11],
                    ["", 35],
                    ["", 21],
                    ["", 8],
                    ["", 7],
                  ]}
                  options={{
                    curveType: "function",
                    colors: ["red"],
                    legend: { position: "none" },
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="task_card">
              <div className="task_heading">
                <h1>My Task</h1>
              </div>
              <div className="task_main">
                <div className="task_details mb-2">
                  <p>
                    Social followers dashboard <br /> development
                  </p>
                  <div className="red_dot"></div>
                </div>
                <div className="name_date">
                  <div className="by_name">
                    <p>By: Numan</p>
                  </div>
                  <div className="by_name">
                    <Timer />
                  </div>
                </div>
              </div>
              <div className="task_main">
                <div className="task_details_two mb-2">
                  <p>
                    Followerscart.uk buy likes <br />
                    not working
                  </p>
                  <div className="orange_dot"></div>
                </div>
                <div className="name_date">
                  <div className="by_name">
                    <p>By: Numan</p>
                  </div>
                  <div className="by_name">
                    <p>2 days left</p>
                  </div>
                </div>
              </div>
              <div className="view_all">
                <Link>
                  View All
                  <Arrow />
                </Link>
              </div>
            </div>
            <div className="priority_card ">
              <div className="priority_heading d-flex mb-3">
                <h3>Task Priority:</h3>
                <Question />
              </div>
              <div className="colors_priority pb-3">
                <div className="dots">
                  <div className="red_dot"></div>
                  <p>High</p>
                </div>
                <div className="dots">
                  <div className="orange_dot"></div>
                  <p>Medium</p>
                </div>
                <div className="dots">
                  <div className="green_dot"></div>
                  <p>Low</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <SubmitTime />
            <RequestTime />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Analytic;
