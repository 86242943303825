import React from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { useTaskContextProvider } from "../../../utils/TaskContextProvider";

function PriorityRow({ task, handlePrioritySelect }) {
  const { assignedPermissions } = useTaskContextProvider();
  return (
    <div>
      <div className="row">
        <div className="col-2 p-0">
          {assignedPermissions.createTask ? (
            <DropdownButton
              key="down"
              drop="down"
              title={
                <i
                  className={
                    task.task_type
                      ? task.task_type === "medium"
                        ? "gray_medium fa-solid fa-flag"
                        : task.task_type === "high"
                        ? "red_high fa-solid fa-flag"
                        : "green_low fa-solid fa-flag"
                      : ""
                  }
                ></i>
              }
              className="down_btn"
              menuVariant="dark"
            >
              <Dropdown.Item
                onClick={() =>
                  handlePrioritySelect("low", task.id, task.pid, task.task_name)
                }
              >
                <i className="green_low fa-solid fa-flag"></i> Low
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  handlePrioritySelect(
                    "medium",
                    task.id,
                    task.pid,
                    task.task_name
                  )
                }
              >
                <i className="gray_medium fa-solid fa-flag"></i> Medium
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  handlePrioritySelect(
                    "high",
                    task.id,
                    task.pid,
                    task.task_name
                  )
                }
              >
                <i className="red_high fa-solid fa-flag"></i> High
              </Dropdown.Item>
            </DropdownButton>
          ) : (
            <i
              className={
                task.task_type
                  ? task.task_type === "medium"
                    ? "gray_medium fa-solid fa-flag"
                    : task.task_type === "high"
                    ? "red_high fa-solid fa-flag"
                    : "green_low fa-solid fa-flag"
                  : ""
              }
            ></i>
          )}
        </div>
        <div className="col-10">
          <p
            className="m-0 d-flex align-items-baseline"
            style={{ textTransform: "capitalize" }}
          >
            {task.task_type}
          </p>
        </div>
      </div>
    </div>
  );
}

export default PriorityRow;
