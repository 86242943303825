import React from "react";
import { ReactComponent as Dots } from "../../assets/images/dots.svg";

function PlanTable({ plandata, handleClick, handleShow3, planEdit }) {
  return (
    <div className="departmentlist mt-3">
      {plandata.map((post) => {
        return (
          <div className="depart_row">
            <div className="row">
              <div className="col-lg-2 col-md-2 col-sm-2 plan_col">
                <p>{post.name}</p>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-2 plan_col">
                <p>{post.dept_limit}</p>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-2 plan_col">
                <p>{post.user_limit}</p>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-2 plan_col">
                <p>{post.duration} Month</p>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-2 plan_col">
                <p>
                  {post.currency}
                  {post.price}
                </p>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-2">
                <div className="drop_btn">
                  <button
                    className="dots_btn dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    onClick={handleClick}
                    id={post.id}
                  >
                    <Dots />
                  </button>
                  <ul className="dropdown-menu">
                    <li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={planEdit}
                      >
                        Edit
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={handleShow3}
                      >
                        Remove Project
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default PlanTable;
