import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import DeleteTaskModal from "./taskmodals/DeleteModal";
import { deleteTaskStatus } from "../../api/task";
import { storageData } from "../helpers/helper";
import { toast } from "react-toastify";
import AddStatus from "./AddStatus";
import { useTaskContextProvider } from "../../utils/TaskContextProvider";

const StatusBoard = ({
  data,
  divs,
  handleAddButtonClick,
  getBoardProjects,
  projectId,
}) => {
  const { assignedPermissions } = useTaskContextProvider();
  const storageInfo = storageData();
  const [isEdit, setIsEdit] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);
  const handleShowDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const removeTaskStatus = async () => {
    try {
      const formData = {
        id: data.status_id,
      };
      const response = await deleteTaskStatus(formData, storageInfo.data.token);
      getBoardProjects();
      handleCloseDeleteModal();
      toast.success(response.data.message);
    } catch (error) {
      toast.error(error.data.message);
    }
  };
  const capitalizedStatus = data.status_name
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  return (
    <>
      {!isEdit ? (
        <div
          className="todo_task_card"
          style={{
            borderTopColor: data.color_code,
            borderTop: `2px solid ${data.color_code}`,
          }}
        >
          <div className="todo_task_head">
            <p>{capitalizedStatus}</p>
          </div>

          <div className="edit_status">
            {divs.length === 0 && assignedPermissions.createTask && (
              <div
                className="grid_task"
                onClick={() =>
                  handleAddButtonClick(
                    data.status_id,
                    data.status_name,
                    data.project_id
                  )
                }
              >
                <i className="fa-solid fa-plus"></i>
              </div>
            )}
            {data.status_name !== "to do" &&
              data.status_name !== "in progress" &&
              data.status_name !== "completed" &&
              projectId !== undefined && (
                <div className="hover-icons">
                  {assignedPermissions.createStatus && (
                    <div className="grid_task" onClick={() => setIsEdit(true)}>
                      <i
                        style={{
                          padding: 0,
                          fontSize: "14px",
                        }}
                        className="fa-solid fa-pencil"
                      ></i>
                    </div>
                  )}

                  {assignedPermissions.deleteStatus && (
                    <div className="grid_task" onClick={handleShowDeleteModal}>
                      <i
                        style={{
                          padding: 0,
                          fontSize: "14px",
                          marginLeft: 0,
                        }}
                        className="fa-solid fa-trash"
                      ></i>
                    </div>
                  )}
                </div>
              )}
          </div>
        </div>
      ) : (
        <AddStatus
          getBoardProjects={getBoardProjects}
          projectId={projectId}
          setAddStatus={setIsEdit}
          data={data}
        />
      )}

      <Modal
        show={showDeleteModal}
        onHide={handleCloseDeleteModal}
        backdrop="static"
        keyboard={false}
      >
        <DeleteTaskModal
          title="status"
          handleCloseDeleteModal={handleCloseDeleteModal}
          removeTask={removeTaskStatus}
        />
      </Modal>
    </>
  );
};

export default StatusBoard;
