import React from "react";
import { Modal } from "react-bootstrap";
import { useTaskContextProvider } from "../../../utils/TaskContextProvider";
function AddDepartment({ departName, handleClose1, updateCard, addDeparts }) {
  const { setCardID, departname } = useTaskContextProvider();
  return (
    <div>
      <Modal.Header closeButton>
        <div className="model_heading">
          <h1 className="modal-title" id="staticBackdropLabel">
            {setCardID !== "" ? "Update Department" : "Add New Department"}
          </h1>
          {setCardID !== ""
            ? "You can update department"
            : "You can add a new department"}
          <p> </p>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="request_info">
          <form action="" className="row">
            <div className="col-md-12">
              <label
                for="validationCustom01"
                className="form-label keyword_label"
              >
                Name
              </label>
              <input
                type="text"
                className="form-control"
                id="validationCustom01"
                placeholder="Department Name"
                onChange={departName}
                value={departname}
                required
              />
            </div>
            <input value={setCardID} hidden />
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer className="sheet_footer">
        <button
          variant="secondary"
          className="cancel_btn"
          onClick={handleClose1}
        >
          Cancel
        </button>
        <button
          variant="primary"
          className="request_btn apply_btn"
          onClick={setCardID !== "" ? () => updateCard() : () => addDeparts()}
        >
          {setCardID !== "" ? "Update Department" : "Add Department"}
        </button>
      </Modal.Footer>
    </div>
  );
}

export default AddDepartment;
