/* eslint-disable react-hooks/exhaustive-deps */
import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import togglebtn from "../../assets/images/togglebtn.png";
import { ReactComponent as Logout } from "../../assets/images/Logout Icon.svg";
import MenuArr from "../layouts/module_array";
import { storageData } from "../helpers/helper";
import { useTaskContextProvider } from "../../utils/TaskContextProvider";

function LeftSide() {
  const {
    leftSideMenu,
    setLeftSideMenu,
    activePlan,
    assignedPermissions,
    setActivePlan,
  } = useTaskContextProvider();

  const storageInfo = storageData();
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const userType = storageInfo.data.user_type;
  const departmentName = storageInfo.data.userDepartments;

  const toggle = () => setIsOpen(!isOpen);
  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      width: "auto",
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  function Storage() {
    localStorage.clear();
  }

  const departIdasInt = JSON.parse(localStorage.getItem("did"));

  useEffect(() => {
    setActivePlan(JSON.parse(localStorage.getItem("activePlanStatus")));
  }, []);

  if (userType === "admin") {
    if (activePlan) {
      setLeftSideMenu(MenuArr.adminMenu);
    } else {
      setLeftSideMenu(MenuArr.filteredAdminMenu);
    }
  } else if (userType === "superadmin") {
    setLeftSideMenu(MenuArr.superadminMenu);
  } else if (userType === "member" || userType === "sub_admin") {
    if (departIdasInt === 1) {
      setLeftSideMenu(MenuArr.devMenu);
    } else if (departIdasInt === 2) {
      setLeftSideMenu(MenuArr.seoMenu);
    } else if (departIdasInt === 3) {
      setLeftSideMenu(MenuArr.writerMenu);
    }
  }

  return (
    <div className="lefti">
      <div className="left_main">
        <div className="left_menu">
          <motion.div
            animate={{ width: isOpen ? "45px" : "200px" }}
            className="main_motion"
          >
            <div className="toggle_button">
              <img src={togglebtn} alt="" onClick={toggle} />
            </div>

            <ul className="nav nav-tabs flex-column">
              {leftSideMenu.length !== 0 &&
                leftSideMenu?.map((post, index) => {
                  if (
                    post.name === "Members" &&
                    departmentName?.length === 0 &&
                    !assignedPermissions.members
                  ) {
                    return null;
                  }

                  return (
                    <li className="nav-item" key={index}>
                      <Link
                        className={
                          !isOpen
                            ? `nav-link  side_link ${
                                location.pathname === post.url
                                  ? "active_menu"
                                  : ""
                              }`
                            : "icon_color"
                        }
                        aria-current="page"
                        to={post.url}
                      >
                        {post.svg}
                        <AnimatePresence>
                          {!isOpen && (
                            <motion.p
                              variants={showAnimation}
                              initial="hidden"
                              animate="show"
                              exit="hidden"
                            >
                              {post.name}
                            </motion.p>
                          )}
                        </AnimatePresence>
                      </Link>
                    </li>
                  );
                })}
              <hr />

              <li className="nav-item">
                <Link
                  className={!isOpen ? "nav-link side_link " : "icon_color"}
                  to="/login"
                  type="button"
                  onClick={Storage}
                >
                  <Logout />
                  <AnimatePresence>
                    {!isOpen && (
                      <motion.p
                        variants={showAnimation}
                        initial="hidden"
                        animate="show"
                        exit="hidden"
                      >
                        Logout
                      </motion.p>
                    )}
                  </AnimatePresence>
                </Link>
              </li>
            </ul>
          </motion.div>
        </div>
      </div>
    </div>
  );
}

export default LeftSide;
