import React from "react";
import { Spinner } from "react-bootstrap";
import { storageData } from "../helpers/helper";
const GeneralSpinner = () => {
  const storageInfo = storageData();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "50vh",
      }}
    >
      {storageInfo === null && (
        <h4 className="check_info">Checking your profile info...</h4>
      )}
      <Spinner animation="grow" variant="dark" />
    </div>
  );
};

export default GeneralSpinner;
