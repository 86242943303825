import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { AiOutlineSearch } from "react-icons/ai";

const SelectedMemberModal = ({
  handleCloseModal,
  memoptions,
  handleCodeMember,
  taskId,
  selectValues,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(memoptions);

  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    const filteredData = memoptions.filter((option) =>
      option.email.toLowerCase().includes(term.toLowerCase())
    );
    setFilteredOptions(filteredData);
  };

  return (
    <>
      <Modal.Body>
        <div className="filter_top justify-content-between">
          {memoptions?.length !== 0 && (
            <div className="search_filter_field">
              <input
                type="text"
                className="search_input w-100"
                placeholder="Search List"
                value={searchTerm}
                onChange={handleSearch}
              />
              <AiOutlineSearch />
            </div>
          )}

          <div onClick={handleCloseModal}>
            <i className="fa-solid fa-xmark"></i>
          </div>
        </div>
        <div className="member_list">
          <ul>
            {filteredOptions.map((post, index) => (
              <li
                key={index}
                onClick={() => handleCodeMember(post.value, post.label, taskId)}
                className={
                  selectValues?.includes(post.value) ? "selected_class" : ""
                }
              >
                {post.label !== null ? post.email : post.label}
              </li>
            ))}
          </ul>
        </div>
      </Modal.Body>
    </>
  );
};

export default SelectedMemberModal;
