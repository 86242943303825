import React from "react";
import { ReactComponent as Dots } from "../../assets/images/dots.svg";

function PlanTableMobile({ plandata, handleClick, handleShow3, planEdit }) {
  return (
    <div>
      {plandata.map((post) => {
        return (
          <div className="insight_right_main mobile_view_insight pb-0">
            <div className="mobile_view_insight border border-white">
              <div
                className="row"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#M${post.id}`}
                aria-expanded="false"
                aria-controls={`M${post.id}`}
              >
                <div className="col-6">
                  <div className="">
                    <div className="header_contents text-center">
                      <p>Name</p>
                    </div>
                    <div className="collapse" id={`M${post.id}`}>
                      <div className="header_contents text-center">
                        <p className="">Department</p>
                        <p className="">User</p>
                        <p className="">Duration</p>
                        <p className="">Price</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6 position-relative">
                  <div className="header_contents">
                    <p>{post.name}</p>
                  </div>
                  <div className="drop_btn mobile_dropdown">
                    <button
                      className="dots_btn dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      onClick={handleClick}
                      id={post.id}
                    >
                      <Dots />
                    </button>
                    <ul className="dropdown-menu">
                      <li>
                        <button
                          className="dropdown-item"
                          type="button"
                          onClick={planEdit}
                        >
                          Edit
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item"
                          type="button"
                          onClick={handleShow3}
                        >
                          Remove Project
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className="collapse" id={`M${post.id}`}>
                    <div className="">
                      <p className="">{post.dept_limit}</p>
                      <p className="">{post.user_limit}</p>
                      <p className="">{post.duration} Month</p>
                      <p className="">
                        {post.currency}
                        {post.price}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default PlanTableMobile;
