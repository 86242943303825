import React from "react";

function PlansTabs({ showDeparts }) {
  return (
    <div className="col-lg-3 col-md-3">
      <div className="tabs_btn_part">
        <div className="list_heading">
          <h1>List</h1>
        </div>
        <div className="">
          <div
            className="nav nav-pills row"
            id="v-pills-tab"
            role="tablist"
            aria-orientation="vertical"
          >
            <button
              className="tabs_btn col-lg-12 col-md-12 col-6 active"
              id="v-pills-home-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-home"
              type="button"
              role="tab"
              aria-controls="v-pills-home"
              aria-selected="true"
            >
              Plans
            </button>
            <button
              className="tabs_btn col-lg-12 col-md-12 col-6"
              id="v-pills-profile-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-profile"
              type="button"
              role="tab"
              aria-controls="v-pills-profile"
              aria-selected="false"
              onClick={showDeparts}
            >
              Department
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlansTabs;
