import React, { useEffect, useState } from "react";
import "./login.css";
import { ToastContainer, toast } from "react-toastify";
import Gbutton from "./gbutton";
import LoginHeader from "./login_header";
import { userVerificationSchema } from "./auth_validation";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams } from "react-router-dom";
import { useTaskContextProvider } from "../../utils/TaskContextProvider";
import { userVerificationData, userVerifyInvite } from "../../api/login";
import { Spinner } from "react-bootstrap";

function SetPassword() {
  const { setBtnLoading, isBtnLoading } = useTaskContextProvider();
  const navigate = useNavigate();

  const { token } = useParams();

  const staticResponse = token;
  const [passwordType, setPasswordType] = useState("password");
  const [passwordTypeA, setPasswordTypeA] = useState("password");
  const [userData, setUserData] = useState(null);

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    } else {
      setPasswordType("password");
    }
  };
  const togglePasswordA = () => {
    if (passwordTypeA === "password") {
      setPasswordTypeA("text");
      return;
    } else {
      setPasswordTypeA("password");
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(userVerificationSchema),
  });

  const onSubmited = async (data) => {
    const formData = {
      rem_token: staticResponse,
      u_name: data.name,
      pswd: data.recover_password,
      c_pswd: data.recover_conf_password,
    };

    try {
      const response = await userVerifyInvite(formData);

      toast.success(response.data.message);
      setBtnLoading(false);
      navigate("/login");
    } catch (error) {
      setBtnLoading(false);
      toast.error(error.response.data.message);
      navigate("/login");
    }
  };

  const fetchUserVerificationData = async () => {
    try {
      const formData = {
        rem_token: staticResponse,
      };

      const response = await userVerificationData(formData);
      setUserData(response.data.data);
    } catch (error) {}
  };

  useEffect(() => {
    fetchUserVerificationData();
  }, []);

  return (
    <div>
      <div className="mian_body pb-4">
        <LoginHeader />
        <ToastContainer />
        <div className="container">
          <div className="sign_header col-lg-10 m-auto">
            <div className="heading_">
              <h3>Reset Password</h3>
            </div>
          </div>
          <div className="col-lg-6 m-auto">
            <div className="signup_card mt-4">
              <div className="fields_area">
                <h5 className="department_name">{userData?.department_name}</h5>

                <form className="row g-3" onSubmit={handleSubmit(onSubmited)}>
                  <div className="position-relative col-md-12 passwords">
                    <label className="form-label">Name</label>
                    <input
                      {...register("name")}
                      name="name"
                      type="name"
                      placeholder="Name"
                      className="form-control"
                    />
                    {errors.name && (
                      <p className="error text-danger m-0">
                        {errors.name.message}
                      </p>
                    )}
                  </div>
                  <div className="position-relative col-md-12 ">
                    <label className="form-label">Email</label>
                    <input
                      // {...register("email")}
                      name="email"
                      type="email"
                      value={userData?.email}
                      className="form-control"
                      disabled
                    />
                    {/* {errors.email && (
                        <p className="error text-danger m-0">
                          {errors.email.message}
                        </p>
                      )} */}
                  </div>
                  <div className="position-relative col-md-12 passwords">
                    <label className="form-label">Password</label>
                    <input
                      {...register("recover_password")}
                      name="recover_password"
                      type={passwordType}
                      placeholder="Password"
                      className="form-control"
                    />
                    {errors.recover_password && (
                      <p className="error text-danger m-0">
                        {errors.recover_password.message}
                      </p>
                    )}
                    <div className="input-group-btn pass_icon">
                      <span onClick={togglePassword}>
                        {passwordType === "password" ? (
                          <i className="fa-sharp fa-solid fa-eye-slash"></i>
                        ) : (
                          <i className="fa-solid fa-eye"></i>
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="position-relative col-md-12 passwords">
                    <label className="form-label">Confirm Password</label>
                    <input
                      {...register("recover_conf_password")}
                      name="recover_conf_password"
                      type={passwordTypeA}
                      className="form-control"
                      placeholder="Confirm Password"
                    />
                    {errors.recover_conf_password && (
                      <p className="error text-danger m-0">
                        {errors.recover_conf_password.message}
                      </p>
                    )}
                    <div className="input-group-btn pass_icon">
                      <span onClick={togglePasswordA}>
                        {passwordTypeA === "password" ? (
                          <i className="fa-sharp fa-solid fa-eye-slash"></i>
                        ) : (
                          <i className="fa-solid fa-eye"></i>
                        )}
                      </span>
                    </div>
                  </div>
                  <Gbutton
                    name={
                      !isBtnLoading ? (
                        "Set Password"
                      ) : (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )
                    }
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SetPassword;
