import React, { useEffect, useRef, useState } from "react";
import DynamicTaskCard from "./DynamicTaskCard";
import NewTaskCard from "./NewTaskCard";
import TaskCard from "./TaskCard";
import { Modal } from "react-bootstrap";
import { useTaskContextProvider } from "../../utils/TaskContextProvider";
import {
  deleteTaskHelper,
  handleDatesHelper,
  handlePrioritySelectHelper,
} from "../../components/helpers/TaskHelpers";
import { createTask } from "../../api/task";
import RequestMoreTimeModal from "./taskmodals/RequestMoreTimeModal";
import GeneralSpinner from "../spiner/Spiner";
import { toast } from "react-toastify";
import DeleteTaskModal from "./taskmodals/DeleteModal";
import StatusBoard from "./StatusBoard";
import { useParams } from "react-router-dom";
import AddStatus from "./AddStatus";

const TaskList = ({ getBoardProjects }) => {
  const {
    memberlistForTasks,
    taskListBoard,
    departIdasInt,
    loading,
    projectlistTask,
  } = useTaskContextProvider();

  const { projectId } = useParams();
  const [selectedDates, setSelectedDates] = useState({});
  const [selectedPriority, setSelectedPriority] = useState("Set Priority");
  const [divs, setDivs] = useState([]);
  const [showRequestTimeModal, setShowRequestTimeModal] = useState(false);
  const [addStatus, setAddStatus] = useState(false);
  const [isMember, setIsMember] = useState(null);
  const [statusName, setStatusName] = useState("");
  const [taskId, setTaskId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [formData, setFormData] = useState("");
  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  const handleShowDeleteModal = (taskId) => {
    setShowDeleteModal(true);
    setTaskId(taskId);
  };

  const projectStatus = taskListBoard?.project_status;

  const allTasks =
    taskListBoard?.length !== 0 && taskListBoard?.board_tasks != null
      ? Object.values(taskListBoard?.board_tasks).reduce((acc, statusTasks) => {
          return acc.concat(statusTasks);
        }, [])
      : [];

  const handleAddStatus = () => {
    setAddStatus(true);
  };

  const handleShowRequestTimeModal = (formData) => {
    setShowRequestTimeModal(true);
    setFormData(formData);
  };

  const handleCloseRequestTimeModal = () => {
    setShowRequestTimeModal(false);
  };

  const handlePrioritySelect = async (priority, taskId, projectId) => {
    try {
      setSelectedPriority((prevPriorities) => ({
        ...prevPriorities,
        [taskId]: priority,
      }));

      const response = await handlePrioritySelectHelper(
        priority,
        taskId,
        projectId,
        departIdasInt,
        createTask
      );

      toast.success(response.message);
    } catch (error) {
      console.error("An error occurred in handlePrioritySelect:", error);
    }
  };

  const handleDates = async (dates, taskId, projectId) => {
    try {
      setSelectedDates((prevDates) => ({
        ...prevDates,
        [taskId]: dates,
      }));

      await handleDatesHelper(
        dates,
        taskId,
        projectId,
        departIdasInt,
        isMember,
        setTaskId,
        handleShowRequestTimeModal
      );
    } catch (error) {
      console.error("An error occurred in handleDates:", error);
    }
  };

  const removeTask = async () => {
    try {
      const response = await deleteTaskHelper(taskId);
      getBoardProjects();
      handleCloseDeleteModal();
      toast.success(response.message);
    } catch (error) {
      console.error("An error occurred in removeTask:", error);
    }
  };

  const renderTaskCards = (tasks) => {
    return tasks.map((task) => (
      <>
        <DynamicTaskCard
          selectedPriority={selectedPriority}
          handlePrioritySelect={(priority) =>
            handlePrioritySelect(priority, task.id, task.pid)
          }
          task={task}
          selectedDates={selectedDates[task.id] || null}
          handleDates={(dates) => handleDates(dates, task.id, task.pid)}
          handleShowDeleteModal={handleShowDeleteModal}
          memoptions={memoptions}
          handleShowRequestTimeModal={handleShowRequestTimeModal}
          setIsMember={setIsMember}
          getBoardProjects={getBoardProjects}
        />
      </>
    ));
  };

  const handleAddButtonClick = (id, statusName, projectId) => {
    setStatusName(statusName);
    setDivs([
      ...divs,
      <NewTaskCard
        statusId={id}
        getBoardProjects={getBoardProjects}
        setDivs={setDivs}
        projectIdTask={projectId}
      />,
    ]);
  };

  const memArr = [];
  memberlistForTasks.forEach((mem) => {
    memArr.push({
      value: `${mem.id}`,
      label: `${mem.full_name}`,
      email: `${mem.email}`,
      selected: false,
    });
  });
  const memoptions = memArr;

  const uniqueProjectStatus =
    (taskListBoard.length !== 0 || projectStatus !== undefined) &&
    projectStatus.reduce((statusName, currentValue) => {
      const existingObject = statusName.find(
        (obj) => obj.status_name === currentValue.status_name
      );

      if (!existingObject) {
        statusName.push(currentValue);
      }

      return statusName;
    }, []);

  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      const handleDragOver = (e) => {
        e.preventDefault();
        const container = containerRef.current;
        const mouseX = e.clientX;
        const containerRect = container.getBoundingClientRect();
        const scrollSpeed = 30;

        if (mouseX < containerRect.left + 50 && container.scrollLeft > 0) {
          container.scrollLeft -= scrollSpeed;
        } else if (
          mouseX > containerRect.right - 50 &&
          container.scrollLeft < container.scrollWidth - container.clientWidth
        ) {
          container.scrollLeft += scrollSpeed;
        }
      };

      containerRef.current.addEventListener("dragover", handleDragOver);
    }
  }, []);

  return (
    <>
      {!loading ? (
        projectlistTask?.data?.length === 0 ? (
          ""
        ) : (
          <div className="task_dtails mt-4">
            <div className="btn_card_board mb-3">
              <div ref={containerRef} className="card-container">
                <>
                  {uniqueProjectStatus &&
                    uniqueProjectStatus?.map((data, index) => {
                      return (
                        <div key={index}>
                          <StatusBoard
                            data={data}
                            divs={divs}
                            handleAddButtonClick={handleAddButtonClick}
                            getBoardProjects={getBoardProjects}
                            projectId={projectId}
                          />
                          <TaskCard
                            tasksArray={allTasks}
                            renderTaskCards={renderTaskCards}
                            status={data.status_name}
                            divs={divs}
                            statusName={statusName}
                            statusId={data.status_id}
                            projectStatus={projectStatus}
                            tasksArray_={taskListBoard?.board_tasks}
                          />
                        </div>
                      );
                    })}

                  {projectId !== undefined &&
                    (addStatus ? (
                      <AddStatus
                        setAddStatus={setAddStatus}
                        getBoardProjects={getBoardProjects}
                        projectId={projectId}
                      />
                    ) : (
                      <button className="add_status" onClick={handleAddStatus}>
                        <div className="btn_status">
                          <i className="fa-solid fa-plus"></i>
                          <p>Add Status</p>
                        </div>
                      </button>
                    ))}
                </>
              </div>
            </div>
          </div>
        )
      ) : (
        <GeneralSpinner />
      )}

      <Modal
        show={showRequestTimeModal}
        onHide={handleCloseRequestTimeModal}
        size="md"
        backdrop="static"
        keyboard={false}
      >
        <RequestMoreTimeModal
          formDataRT={formData}
          taskId={taskId}
          handleDates={handleDates}
          handleCloseRequestTimeModal={handleCloseRequestTimeModal}
        />
      </Modal>
      <Modal
        show={showDeleteModal}
        onHide={handleCloseDeleteModal}
        backdrop="static"
        keyboard={false}
      >
        <DeleteTaskModal
          handleCloseDeleteModal={handleCloseDeleteModal}
          removeTask={removeTask}
        />
      </Modal>
    </>
  );
};

export default TaskList;
