/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import tick from "../../assets/images/tick.png";
import redtick from "../../assets/images/redtick.png";
import { useLocation } from "react-router-dom";
import "../../components/subscription/subscription.css";
import { storageData } from "../../components/helpers/helper";
import { getPlans, subscribePlan } from "../../api/plan";
import SubscriptionHeader from "./subscription_header";
import { useTaskContextProvider } from "../../utils/TaskContextProvider";
import GeneralSpinner from "../spiner/Spiner";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";

function SubscriptionModule({ fetchActivePlan }) {
  const currentURL = useLocation();
  const storageInfo = storageData();
  const userType = storageInfo.data.user_type;
  const { setLoading, loading, activePlanId } = useTaskContextProvider();
  const [plandata, setPlan] = useState([]);
  const [loadingStates, setLoadingStates] = useState({});
  const buyPlan = async (id) => {
    setLoadingStates((prevState) => ({
      ...prevState,
      [id]: true,
    }));
    try {
      const formmData = {
        plan_id: parseInt(id, 10),
      };
      const response = await subscribePlan(formmData, storageInfo.data.token);
      if (response.data.success) {
        localStorage.setItem("c_info", JSON.stringify(storageInfo));
        fetchActivePlan();
        setLoadingStates((prevState) => ({
          ...prevState,
          [id]: false,
        }));
        toast.success(response.data.message);
      }
      return false;
    } catch (error) {
      setLoadingStates((prevState) => ({
        ...prevState,
        [id]: false,
      }));
      toast.error(error.data.message);
    }
  };

  const subscribePlanID = (event) => {
    const targetID = event.target.id;
    buyPlan(targetID);
  };

  const showPlan = async () => {
    setLoading(true);
    try {
      const formData = {};
      const response = await getPlans(formData);
      const data = response.data.data ? response.data.data : "";
      if (data.length > 0) {
        setPlan(data);
        setLoading(false);
      }

      return false;
    } catch (error) {}
  };

  useEffect(() => {
    showPlan();
    // fetchActivePlan();
  }, []);
  // const planLimit =()=>{
  //   if()
  // }
  return (
    <div>
      {!loading ? (
        <>
          {currentURL.pathname === "/plan-history" ? (
            ""
          ) : (
            <SubscriptionHeader />
          )}
          <div className="mt-3">
            <div className="upgrade_main">
              <div className="row">
                {plandata.map((post, index) => {
                  const features =
                    post?.description && typeof post?.description === "string"
                      ? post.description.split("\n")
                      : "".split("\n");
                  return (
                    <>
                      {index === 1 ? (
                        <div className="col-lg-4 position-relative">
                          <div className="">
                            <div className="most_tag col-8 m-auto">
                              <h2>Most Popular</h2>
                            </div>
                            <div className="upgrade_priemium">
                              <div className="card_price">
                                <h3>{post.name}</h3>
                                <h4>
                                  <span>{post.currency}</span>
                                  {post.price}
                                  <span>/mo</span>
                                </h4>
                                <p>
                                  <i className="fa-solid fa-circle-info me-2"></i>
                                  organization / month / billed monthly
                                </p>

                                <div className="basic_btn col-8 m-auto">
                                  {userType === "member" ? (
                                    ""
                                  ) : !loadingStates[post.id] ? (
                                    <div className="basic_btn col-8 m-auto">
                                      <button
                                        className="btn request_btn w-100"
                                        onClick={subscribePlanID}
                                        id={post.id}
                                        disabled={activePlanId === post.id}
                                      >
                                        {activePlanId === post.id
                                          ? "Currently"
                                          : "Buy"}
                                      </button>
                                    </div>
                                  ) : (
                                    <Spinner
                                      as="span"
                                      animation="border"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    />
                                  )}
                                </div>
                              </div>
                              <hr />
                              <div className="basic_list">
                                <ul>
                                  {features.length &&
                                    features?.map((f, i) => (
                                      <li key={i}>
                                        <img src={redtick} alt="" />
                                        {f}
                                      </li>
                                    ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="col-lg-4">
                          <div className="upgrade_card">
                            <div className="upgrade_basic">
                              <div className="card_price">
                                <h3>{post.name}</h3>
                                <h4>
                                  <span>{post.currency}</span>
                                  {post.price}
                                  <span>/mo</span>
                                </h4>
                                <p>
                                  <i className="fa-solid fa-circle-info me-2"></i>
                                  organization / month / billed monthly
                                </p>
                                <div className="basic_btn col-8 m-auto">
                                  {userType === "member" ? (
                                    ""
                                  ) : !loadingStates[post.id] ? (
                                    <div className="basic_btn col-8 m-auto">
                                      <button
                                        className="btn buy_btn"
                                        onClick={subscribePlanID}
                                        id={post.id}
                                        disabled={activePlanId === post.id}
                                      >
                                        {activePlanId === post.id
                                          ? "Currently"
                                          : "Buy"}
                                      </button>
                                    </div>
                                  ) : (
                                    <Spinner
                                      as="span"
                                      animation="border"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    />
                                  )}
                                </div>
                              </div>
                              <hr />
                              <div className="basic_list">
                                <ul>
                                  {features.length &&
                                    features?.map((f, i) => (
                                      <li key={i}>
                                        <img src={tick} alt="" />
                                        {f}
                                      </li>
                                    ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      ) : (
        <GeneralSpinner />
      )}
    </div>
  );
}

export default SubscriptionModule;
