import React from "react";
import { ReactComponent as PDFIcon } from "../../assets/images/PDF Icon.svg";
import { ReactComponent as CrossIcon } from "../../assets/images/Cross Icon.svg";
import moment from "moment";
import { useDropzone } from "react-dropzone";
import { Link } from "react-router-dom";
import { useTaskContextProvider } from "../../utils/TaskContextProvider";
import { toast } from "react-toastify";

const Attachments = ({ handleFileChange, attachments, removeAttachment }) => {
  const baseURL = process.env.REACT_APP_URL;

  const { assignedPermissions } = useTaskContextProvider();

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      if (assignedPermissions.saveAttachments) {
        if (acceptedFiles && acceptedFiles.length > 0) {
          handleFileChange({
            target: {
              files: acceptedFiles,
            },
          });
        } else {
          toast.error(
            "You don't have permission for this action. Please contact your admin!"
          );
        }
      }
    },
    multiple: false,
  });

  return (
    <>
      <div className="detail_discription">
        <h6>Attachments</h6>

        <div
          {...getRootProps()}
          className="drag-drop-area drop-zone mb-2 w-100"
        >
          <input {...getInputProps()} />
          Drag &amp; Drop Files Here
        </div>

        {attachments && attachments.length > 0 ? (
          <div className="attachments-row">
            {attachments.map((attachment) => (
              <div key={attachment.id}>
                <div className="file-preview-div">
                  <div className="image-container">
                    {attachment.file_path.includes("pdf") ? (
                      <PDFIcon className="file-preview-icon" />
                    ) : (
                      <img
                        src={`${baseURL}${attachment.file_path}`}
                        alt="File Preview"
                        className="file-preview-img"
                      />
                    )}

                    {assignedPermissions.deleteAttachments && (
                      <span
                        className="remove-icon"
                        onClick={() => removeAttachment(attachment.id)}
                      >
                        <CrossIcon style={{ width: "15px", height: "15px" }} />
                      </span>
                    )}
                  </div>
                  <div className="attachment-info-container">
                    <div className="attachment-info">
                      <Link
                        target="_blank"
                        to={`${baseURL}${attachment.file_path}`}
                        className="attachment-name"
                      >
                        {attachment.file_name.slice(0, 15)}
                        {attachment.file_name.length > 15 && "..."}
                      </Link>
                      <p className="attachment-time">
                        {moment(attachment.created_at).format("h:mm a")}
                      </p>
                    </div>
                    {/* <div className="user-information">
                      <img
                        src={`${baseURL}${attachment.profile_image}`}
                        alt="profile"
                      />
                    </div> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p
            style={{
              fontSize: "14px",
              color: "red",
            }}
          >
            No Attachment Added
          </p>
        )}
      </div>
    </>
  );
};

export default Attachments;
