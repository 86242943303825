import React from "react";
import "./dropdown.css";
function Dropdown({ options }) {
  return (
    <div>
      <div className="dropdown_main">
        <select
          className="form-select select_option"
          aria-label="Default select example"
        >
          {options.map((post, index) => {
            return (
              <option value="1" key={index}>
                {post}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
}

export default Dropdown;
