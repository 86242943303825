import React, { useState } from "react";
import { useTaskContextProvider } from "../../utils/TaskContextProvider";
import NewTask from "./NewTask";
import DynamicTaskRow from "./DynamicTaskRow";
import { useNavigate } from "react-router-dom";
import { storageData } from "../helpers/helper";
import api from "../helpers/AccessHelper";
import GeneralSpinner from "../spiner/Spiner";
import { toast } from "react-toastify";

function TaskListData({ ProjectsandTasks, checkDeptAccess }) {
  const {
    projectlistTask,
    setTaskProjectID,
    setStatusID,
    taskprojectID,
    taskStatusID,
    departIdasInt,
    loading,
    assignedPermissions,
  } = useTaskContextProvider();
  const [divs, setDivs] = useState([]);
  const storageInfo = storageData();
  const navigate = useNavigate();

  const [newname, setNewName] = useState("");
  const handleNameValue = (id, statusID, name) => {
    setNewName(name);
    setTaskProjectID(id);
    setStatusID(statusID);
  };
  const handleInputBlur = (e) => {
    if (e.key === "Enter") {
      if (newname) {
        onSubmit(newname);
      }
    }
  };
  const handBlur = () => {
    if (newname) {
      onSubmit(newname);
    }
  };
  const handleAddButtonClick = (projectid, statusID, name) => {
    if (assignedPermissions.createTask) {
      setNewName(name);
      setTaskProjectID(projectid);
      setStatusID(statusID);
      setDivs([
        ...divs,
        <NewTask
          ProjectsandTasks={ProjectsandTasks}
          setDivs={setDivs}
          projectid={projectid}
          statusID={statusID}
        />,
      ]);
    } else {
      toast.error(
        "You don't have permission for this action. Please contact your admin!"
      );
    }
  };
  const onSubmit = async (name) => {
    try {
      const formData = new FormData();
      formData.append("department_id", JSON.parse(departIdasInt));
      formData.append("project_id", taskprojectID);
      formData.append("task_status_id", taskStatusID);
      formData.append("name", name);
      await api.post("task/create-task", formData, {
        headers: {
          Authorization: `Bearer ${storageInfo?.data?.token}`,
        },
      });
      ProjectsandTasks();
      setNewName("");
    } catch (error) {
      if (error?.response?.data?.data === "permission") {
        navigate("/404");
      }
      if (error?.response?.data?.data === "plan") {
        navigate("/dashboard");
      }
    }
  };
  const [showTaskBtn, setShowTask] = useState(false);
  const showAddtask = () => {
    setShowTask(true);
  };
  return (
    <div>
      <div className="task_dtails mt-4">
        <div className="row">
          <div
            className={
              projectlistTask?.data?.length === 0 ? "no_color" : "btn_card mb-3"
            }
          >
            {!loading ? (
              projectlistTask?.length === 0 ? (
                ""
              ) : (
                projectlistTask.success &&
                Object.entries(projectlistTask.data).map(
                  ([projectName, projectData]) => (
                    <div className="border_div" key={projectName}>
                      <button
                        className="toggle_project"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#collap${projectData.project_id}`}
                        aria-expanded="false"
                        aria-controls={`collap${projectData.project_id}`}
                      >
                        <i className="fa-solid fa-caret-down"></i>

                        <p>{projectName}</p>
                        <div className="notice_number ms-2">
                          <p>{projectData.total_card_count}</p>
                        </div>
                      </button>
                      {Object.entries(projectData).map(([status, tasks]) =>
                        status !== "total_card_count" &&
                        status !== "project_id" &&
                        status !== "status_id" ? (
                          <div>
                            <div
                              className="collapse"
                              id={`collap${projectData.project_id}`}
                            >
                              <div className="card_body">
                                <div>
                                  <div className="div_btn">
                                    <button
                                      className="toggle_project"
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target={`#collaps${tasks[0].id}`}
                                      aria-expanded="false"
                                      aria-controls={`collaps${tasks[0].id}`}
                                      onClick={showAddtask}
                                    >
                                      <i className="fa-solid fa-caret-down"></i>

                                      <p
                                        style={{
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {status}
                                      </p>
                                      <div className="notice_number ms-2">
                                        <p>
                                          {projectData.total_card_count === 0
                                            ? 0
                                            : tasks.length}
                                        </p>
                                      </div>
                                    </button>
                                    {showTaskBtn === true &&
                                      divs.length === 0 &&
                                      projectData.total_card_count !== 0 && (
                                        <div
                                          className="add_task"
                                          onClick={() =>
                                            handleAddButtonClick(
                                              tasks[0].pid,
                                              tasks[0].status_id,
                                              tasks[0].status_name
                                            )
                                          }
                                        >
                                          <i className="fa-solid fa-plus"></i>
                                          <p>Add Task</p>
                                        </div>
                                      )}
                                  </div>
                                  <div
                                    className="collapse scroll_class"
                                    id={`collaps${tasks[0].id}`}
                                  >
                                    <div className="card_body width_overflow">
                                      <div className="tasktable_head">
                                        <div className="row">
                                          <div className="col-3">
                                            <p>Name</p>
                                          </div>
                                          <div className="col-3">
                                            <p>Assign</p>
                                          </div>
                                          <div className="col-3">
                                            <p>Due Date</p>
                                          </div>
                                          <div className="col-2">
                                            <p>Priority</p>
                                          </div>
                                        </div>
                                      </div>
                                      {projectData.total_card_count === 0 ? (
                                        <div className="col-3 mt-2">
                                          <input
                                            className="form-control"
                                            value={newname}
                                            placeholder="Add new task"
                                            onChange={(e) =>
                                              handleNameValue(
                                                projectData.project_id,
                                                projectData.status_id,
                                                e.target.value
                                              )
                                            }
                                            onKeyDown={handleInputBlur}
                                            onBlur={handBlur}
                                          />
                                        </div>
                                      ) : Array.isArray(tasks) &&
                                        tasks.length > 0 ? (
                                        (() => {
                                          const firstTask = tasks[0];
                                          if (
                                            Object.keys(firstTask).length !== 0
                                          ) {
                                            return (
                                              <DynamicTaskRow
                                                ProjectsandTasks={
                                                  ProjectsandTasks
                                                }
                                                divs={divs}
                                                tasksname={tasks}
                                                projectData={projectData}
                                                checkDeptAccess={
                                                  checkDeptAccess
                                                }
                                                tasknumber={tasks.length}
                                              />
                                            );
                                          } else {
                                            return null;
                                          }
                                        })()
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null
                      )}
                    </div>
                  )
                )
              )
            ) : (
              <GeneralSpinner />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TaskListData;
