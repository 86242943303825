import React from "react";
import "./login.css";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { authForget } from "../../api/login";
import { forgetValidationSchema } from "./auth_validation";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import LoginHeader from "./login_header";
import Gbutton from "./gbutton";
import { useTaskContextProvider } from "../../utils/TaskContextProvider";
import { Spinner } from "react-bootstrap";

function Forget() {
  const navigate = useNavigate();
  const { isBtnLoading, setBtnLoading } = useTaskContextProvider();

  let emailCheck = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/i;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(forgetValidationSchema),
  });
  const onSubmited = async (data) => {
    if (!data.forget_email.match(emailCheck)) {
      toast.error("Enter a valid email");
      return false;
    }
    setBtnLoading(true);

    try {
      const formData = {
        email: data.forget_email,
      };
      const response = await authForget(formData);
      setBtnLoading(false);

      toast.success(response.data.message);
      navigate("/recover-password");
    } catch (error) {
      setBtnLoading(false);

      toast.error(error.response.data.message);
    }
  };
  return (
    <div>
      <div className="mian_body pb-4">
        <LoginHeader />
        <div className="container">
          <ToastContainer />
          <div className="sign_header col-lg-10 m-auto">
            <div className="heading_">
              <h3>Forget Password</h3>
            </div>
            <div className="col-lg-6 m-auto">
              <div className="signup_card mt-4">
                <div className="fields_area">
                  <form
                    className="row g-3"
                    onSubmit={handleSubmit(onSubmited)}
                    // onSubmit={handleSubmit}
                  >
                    <div className="col-md-12">
                      <label className="form-label">Email</label>
                      <input
                        {...register("forget_email")}
                        name="forget_email"
                        type="text"
                        placeholder="yourname@domain.com"
                        className="form-control"
                      />
                      {errors.forget_email && (
                        <p className="error text-danger m-0">
                          {errors.forget_email.message}
                        </p>
                      )}
                    </div>
                    <Gbutton
                      name={
                        !isBtnLoading ? (
                          "Send Link"
                        ) : (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        )
                      }
                    />
                  </form>
                  <div className="terms text-center">
                    <p>
                      Back to{" "}
                      <Link to="/login">
                        <span>Sign in</span>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Forget;
