import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { ReactComponent as Detail } from "../../../assets/images/details.svg";
import Checklist from "../Checklist";
import Attachments from "../Attachments";
import TaskDetails from "../Details";
import Activity from "../Activity";
import BigTimer from "../../timer/bigtimer";
import { useTaskContextProvider } from "../../../utils/TaskContextProvider";
import { storageData } from "../../helpers/helper";
import { API, activeLog, deleteAttachments } from "../../../api/task";
import { toast } from "react-toastify";
import {
  getAttachmentsHelper,
  getChecklistHelper,
  getCommentsHelper,
  getTaskDetailsHelper,
  handleNameEditHelper,
} from "../../helpers/TaskHelpers";
import { Link, useLocation, useParams } from "react-router-dom";

const TaskDetailScreen = () => {
  const [isEditTaskName, setIsEditTaskName] = useState(false);
  const {
    attachments,
    setTaskDetails,
    taskDetails,
    departIdasInt,
    setLoading,
    setCheckLists,
    setComments,
    setAttachments,
    setActiveLog,
    assignedPermissions,
  } = useTaskContextProvider();
  const storageInfo = storageData();
  const { taskId } = useParams();

  const location = useLocation();
  const projectId = location.state.projectId;

  const [taskName, setTaskName] = useState("");

  const handleEnterKey = async (e) => {
    if (e.key === "Enter") {
      try {
        await handleNameEditHelper(
          taskDetails.id,
          taskDetails.pid,
          taskName,
          departIdasInt,
          getTaskDetailsHelper,
          setIsEditTaskName
        );
      } catch (error) {
        console.error("An error occurred in handleEnterKey:", error);
      }
    }
  };

  const handleFileChange = async (event) => {
    const newSelectedFile = event.target.files[0];

    const formData = new FormData();
    formData.append("department_id", departIdasInt);
    formData.append("task_id", taskDetails.id);
    formData.append("project_id", taskDetails.pid);
    formData.append("file", newSelectedFile);

    try {
      const response = await API.post("task/attachment", formData, {
        headers: {
          Authorization: `Bearer ${storageInfo?.data?.token}`,
        },
      });

      if (response.status === 200) {
        setAttachments((prevAttachments) => [
          ...prevAttachments,
          {
            id: response.data.data.id,
            file_name: newSelectedFile.name,
            created_at: new Date().toISOString(),
            file_path: response.data.data.file_path,
            full_name: response.data.data.full_name,
            profile_image: response.data.data.profile_image,
          },
        ]);
        toast.success("File uploaded successfully");
      } else {
        console.error("File upload failed");
      }
    } catch (error) {
      console.error("Error uploading file", error);
    }
  };

  const removeAttachment = async (id) => {
    const formData = {
      id: id,
    };

    try {
      const confirmed = window.confirm(
        "Are you sure you want to delete this attachment?"
      );

      if (confirmed) {
        const response = await deleteAttachments(
          formData,
          storageInfo.data.token
        );

        if (response.status === 200) {
          setAttachments((prevAttachments) =>
            prevAttachments.filter((attachment) => attachment.id !== id)
          );
          toast.success("Attachment removed successfully");
        } else {
          toast.error("Attachment removal failed");
        }
      }
    } catch (error) {
      console.error("Error removing attachment", error);
    }
  };

  const handleShowDetails = async () => {
    if (assignedPermissions.taskDetails) {
      const formData = {
        task_id: taskId,
        project_id: projectId,
      };
      try {
        const taskDetailsPromise = getTaskDetailsHelper(taskId, projectId);
        const checkListsPromise = getChecklistHelper(taskId).catch((error) => {
          console.error("Error occurred while fetching checklist:", error);
          return { data: [] };
        });
        const attachmentsPromise = getAttachmentsHelper(taskId).catch(
          (error) => {
            console.error("Error occurred while fetching attachments:", error);
            return { data: [] };
          }
        );
        const commentsPromise = getCommentsHelper(taskId).catch((error) => {
          console.error("Error occurred while fetching comments:", error);
          return { data: [] };
        });
        const activeLogPromise = activeLog(
          formData,
          storageInfo.data.token
        ).catch((error) => {
          console.error("Error occurred while fetching active log:", error);
          return { data: {} };
        });

        const [
          taskDetailsData,
          checkLists,
          attachments,
          comments,
          activeDetails,
        ] = await Promise.all([
          taskDetailsPromise,
          checkListsPromise,
          attachmentsPromise,
          commentsPromise,
          activeLogPromise,
        ]);

        setTaskDetails(taskDetailsData);
        setCheckLists(checkLists.data);
        setAttachments(attachments.data);
        setComments(comments.data);
        setActiveLog(activeDetails.data.data);

        setLoading(false);
      } catch (error) {
        console.error("Error occurred while fetching data:", error);
      }
    }
  };

  useEffect(() => {
    setTaskName(taskDetails.task_name);
  }, [taskDetails]);

  useEffect(() => {
    handleShowDetails();
  }, []);

  return (
    <div style={{ padding: "20px" }}>
      <div className="header_container ">
        <div className="model_heading">
          {isEditTaskName ? (
            <input
              className="form-control"
              placeholder="Task Name"
              value={taskName}
              onChange={(e) => setTaskName(e.target.value)}
              onKeyDown={handleEnterKey}
            />
          ) : (
            <div
              className="time"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <h1 className="modal-title" id="staticBackdropLabel">
                {taskName}
              </h1>
              {assignedPermissions.createTask && (
                <i
                  className="fa-solid fa-pencil"
                  onClick={() => setIsEditTaskName(true)}
                ></i>
              )}
            </div>
          )}
        </div>
        <div className="timer_container">
          <div className="task_timer">
            <BigTimer toDateReport={taskDetails?.to_date_report} />
          </div>
          <Link
            style={{
              border: "1px solid var(--red) !important",
              padding: "10px",
              borderRadius: "6px",
              background: "var(--red)",
              color: "var(--white)",
              transition: "all 0.3s ease",
              fontSize: "16px",
            }}
            to="/task"
          >
            Back to Tasks
          </Link>
        </div>
      </div>
      <div className="">
        <div className="row g-0 needs-validation" noValidate>
          <div className="modal_tabs_btn">
            <nav>
              <div
                className="nav nav-tabs model_tabs_align"
                id="nav-tab"
                role="tablist"
              >
                <button
                  className={`nav-link task_links ${"active"}`}
                  id="nav-detail-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-detail"
                  type="button"
                  role="tab"
                  aria-controls="nav-detail"
                  aria-selected="true"
                >
                  <Detail />
                  Details
                </button>
              </div>
            </nav>
          </div>
          <div className="col-lg-8">
            <div className="modal_tabs_content">
              <div className="tab-content" id="nav-tabContent">
                <div
                  className={`tab-pane m-0 fade  show ${"active"}`}
                  id="nav-detail"
                  role="tabpanel"
                  aria-labelledby="nav-detail-tab"
                  tabIndex="0"
                >
                  <div className="detail_tabs_main">
                    <TaskDetails taskDetails={taskDetails} />
                    <hr />
                    {assignedPermissions.checkLists && (
                      <>
                        <div className="col-lg-6">
                          <Checklist taskId={taskDetails.id} />
                        </div>
                        <hr />
                      </>
                    )}

                    {assignedPermissions.attachmentsList && (
                      <div className="col-lg-12">
                        <Attachments
                          handleFileChange={handleFileChange}
                          attachments={attachments}
                          removeAttachment={removeAttachment}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 model_right">
            <Activity taskId={taskDetails.id} projectId={taskDetails.pid} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskDetailScreen;
