import { toast } from "react-toastify";
import { GetTasksForBoard, createTask } from "../../api/task";

export const onDragStart = (evt) => {
  let element = evt.currentTarget;
  element.classList.add("dragged");
  evt.dataTransfer.setData("text/plain", evt.currentTarget.id);
  evt.dataTransfer.effectAllowed = "move";
};

export const onDragEnd = (evt) => {
  evt.currentTarget.classList.remove("dragged");
};

export const onDragEnter = (evt) => {
  evt.preventDefault();
  let element = evt.currentTarget;
  element.classList.add("dragged-over");
  evt.dataTransfer.dropEffect = "move";
};

export const onDragLeave = (evt) => {
  let currentTarget = evt.currentTarget;
  let newTarget = evt.relatedTarget;
  if (newTarget.parentNode === currentTarget || newTarget === currentTarget)
    return;
  evt.preventDefault();
  let element = evt.currentTarget;
  element.classList.remove("dragged-over");
};

export const onDragOver = (evt) => {
  evt.preventDefault();
  evt.dataTransfer.dropEffect = "move";
};

export const onDrop = async (
  evt,
  value,
  status,
  tasksArray,
  setUpdatedTaskArray,
  departIdasInt,
  storageInfo,
  statusId,
  setTaskListBoard,
  projectStatus,
  projectSelected
) => {
  evt.preventDefault();
  evt.currentTarget.classList.remove("dragged-over");
  let data = evt.dataTransfer.getData("text/plain");

  const updatedTasks = await Promise.all(
    tasksArray.map(async (task) => {
      if (task.id.toString() === data.toString()) {
        const projectId = task.pid;

        const isProjectIdMatched = projectStatus.some(
          (project) =>
            project.project_id === projectId || project.project_id === null
        );

        if (isProjectIdMatched) {
          task.status_name = status;
          task.task_status_id = statusId;

          const formData = {
            id: task.id,
            project_id: projectId,
            department_id: departIdasInt,
            task_status_id: statusId,
          };

          await createTask(formData, storageInfo.data.token);
        } else {
          toast.error(
            `Cannot move task as this status is not associated with this Project`
          );
          return task;
        }
      }
      return task;
    })
  );

  setUpdatedTaskArray(updatedTasks);
};
