import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { storageData } from "../components/helpers/helper";

function PrivateRoute({ children, allowedRoles }) {
  const { pathname } = useLocation();
  const storageInfo = storageData();

  if (!storageInfo) {
    return <Navigate to="/login" replace />;
  }

  const userRole = storageInfo.data.user_type;
  const isAllowed = allowedRoles.includes(userRole);
  const token = storageInfo.data.token;

  if (token && pathname === "/login") {
    return <Navigate to="/dashboard" replace />;
  }

  if (!isAllowed) {
    return <Navigate to="/404" replace />;
  }

  if (token === undefined) {
    return <Navigate to="/404" replace />;
  }

  return token ? children : <Navigate to="/login" replace />;
}

export default PrivateRoute;
