import axios from "axios";
const baseURL = process.env.REACT_APP_API_URL;
export const API = axios.create({
  baseURL: baseURL,
});

export const getPlans = async (reqData) => {
  const c_info = JSON.parse(localStorage.getItem("c_info"));
  const url = "plan/list";
  return handleRequest(url, "post", reqData, c_info.data.token);
};
export const addPlans = async (reqData, token) => {
  const url = "plan/create";
  return handleRequest(url, "post", reqData, token);
};
export const editPlans = async (reqData, token) => {
  const url = "plan/edit";
  return handleRequest(url, "post", reqData, token);
};
export const dellPlans = async (reqData, token) => {
  const url = "plan/delete";
  return handleRequest(url, "post", reqData, token);
};
export const updatePlans = async (reqData, token) => {
  const url = "plan/update";
  return handleRequest(url, "post", reqData, token);
};
export const getActivePlan = async (reqData, token) => {
  const url = "plan/active-plan";
  return handleRequest(url, "post", reqData, token);
};
export const getPlanHistory = async (reqData, token) => {
  const url = "subscribe/get-history";
  return handleRequest(url, "post", reqData, token);
};
export const subscribePlan = async (reqData, token) => {
  const url = "subscribe/package";
  return handleRequest(url, "post", reqData, token);
};
const handleRequest = async (url, method, reqData = {}, token = null) => {
  try {
    return await API({
      method: method,
      url: url,
      data: reqData,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  } catch (error) {
    throw error;
  }
};
