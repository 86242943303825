import React, { useEffect, useRef, useState } from "react";
import Container from "../../components/layouts/container";
import LeftSide from "../../components/layouts/left_side";
import "../projects/projects.css";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite-no-reset.min.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AiOutlineSearch } from "react-icons/ai";
import { getProjects, editProject } from "../../api/project";
import { storageData } from "../../components/helpers/helper";
import ProjectHead from "../../components/projects/ProjectHead";
import LoadMore from "../../components/projects/LoadMore";
import { useTaskContextProvider } from "../../utils/TaskContextProvider";
import ProjectList from "../../components/projects/ProjectList";
import { RxCross2 } from "react-icons/rx";
import api from "../../components/helpers/AccessHelper";
import rolePermissionsHelper from "../../components/helpers/rolePermissionHelper";
import ProjectListHead from "../../components/projects/ProjectListHead";
import ImageFunction from "../../components/department/ImageFunction";

function ProjectsList() {
  const {
    userDept,
    userDepId,
    listdataset,
    loading,
    setLoading,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    searchQuery,
    pageNumber,
    setPageNumber,
    setCardID,
    setName,
    setOwner,
    setDiscrption,
    setimage,
    setOldImage,
    setProjectID,
    handleShow,
    departIdasInt,
    resetForm,
    setAssignedPermissions,
    setBtnLoading,
    setListData,
    assignedPermissions,
    setprojectid,
  } = useTaskContextProvider();
  const storageInfo = storageData();
  const userRole = storageInfo.data.user_type;
  const tokenid = storageInfo.data.token;
  const navigate = useNavigate();

  const dept_id = localStorage.getItem("u_did");
  const role_id = localStorage.getItem("role_id");
  const userType = storageInfo.data.user_type;
  const token = storageInfo.data.token;

  const checkDeptAccess = async () => {
    setLoading(true);
    try {
      const proejctFormData = {
        department_id: JSON.parse(departIdasInt),
      };

      const projectData = await api.post("project/get-list", proejctFormData, {
        headers: {
          Authorization: `Bearer ${storageInfo?.data?.token}`,
        },
      });
      const resultProjects = projectData?.data?.data;
      setListData(resultProjects);
      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        navigate("/permission-denied");
      }
      if (error?.response?.data?.data === "permission") {
        navigate("/404");
      }
      if (error?.response?.data?.data === "plan") {
        navigate("/dashboard");
      }
    }
  };
  useEffect(() => {
    checkDeptAccess();
  }, []);

  const valueArr = [];
  userDept.forEach((employee) => {
    valueArr.push({
      value: `${employee.user_department_id}`,
      label: `${employee.tmp_department_name}`,
    });
  });
  valueArr.unshift({ value: null, label: "None" });
  const editvalueArr = [];
  userDept.forEach((employee) => {
    editvalueArr.push({
      value: `${employee.user_department_id}`,
      label: `${employee.tmp_department_name}`,
    });
  });
  editvalueArr.unshift({ value: null, label: "None" });

  const handleDates = (event) => {
    setListData([]);
    if (event !== null) {
      const originalStartDate = new Date(event[0]);
      const origingalEndDate = new Date(event[1]);

      const startYear = originalStartDate.getFullYear();
      const startMonth = (originalStartDate.getMonth() + 1)
        .toString()
        .padStart(2, "0");
      const startDay = originalStartDate.getDate().toString().padStart(2, "0");
      const formattedStartDate = `${startYear}-${startMonth}-${startDay}`;

      const endYear = origingalEndDate.getFullYear();
      const endMonth = (origingalEndDate.getMonth() + 1)
        .toString()
        .padStart(2, "0");
      const endDay = origingalEndDate.getDate().toString().padStart(2, "0");
      const formattedEndDate = `${endYear}-${endMonth}-${endDay}`;

      setStartDate(formattedStartDate);
      setEndDate(formattedEndDate);
      getFilterProjects(
        userDepId,
        searchQuery,
        formattedStartDate,
        formattedEndDate
      );
    } else {
      getFilterProjects(userDepId, searchQuery, null, null);
    }
  };
  const [searchName, setSearchName] = useState("");
  const searchHandler = (e) => {
    setSearchName(e.target.value);
    getFilterProjects(userDepId, e.target.value, startDate, endDate);
  };

  const removeInput = () => {
    setSearchName("");
    getFilterProjects(null, "", null, null);
  };

  const handleLoadMore = async () => {
    try {
      const formData = {
        company_id: storageInfo.data.company_info.id,
        department_id: userDepId,
        start_date: startDate,
        end_date: endDate,
        search: searchQuery,
        page_no: pageNumber,
      };
      const response = await getProjects(formData, storageInfo.data.token);
      const data = response.data.data;
      if (data.length > 0) {
        setListData((prevData) => [...prevData, ...data]);
        setPageNumber((prevPage) => prevPage + 1);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const getFilterProjects = async (
    depId,
    searchQuery,
    startDate,
    endDate,
    pageNumber
  ) => {
    setLoading(true);
    try {
      const formData = {
        department_id: depId,
        start_date: startDate,
        end_date: endDate,
        search: searchQuery,
        page_no: pageNumber,
      };
      if (searchQuery.length >= 3) {
        const response = await getProjects(formData, storageInfo.data.token);
        const data = response.data.data;
        if (data.length >= 0) {
          setListData(data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } else if (searchQuery.length === 0) {
        const response = await getProjects(formData, storageInfo.data.token);
        const data = response.data.data;
        if (data.length >= 0) {
          setListData(data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  const editCard = async () => {
    try {
      const formData = {
        id: setCardID,
      };
      const Token = tokenid;
      const response = await editProject(formData, Token);
      setName(response.data.data.name);
      setOwner(response.data.data.project_owner);
      setProjectID(response.data.data.department_id);
      setDiscrption(response.data.data.project_description);
      setOldImage(response.data.data.project_logo);
      setimage("");
      handleShow();
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    if (userType === "member") {
      rolePermissionsHelper(
        dept_id,
        role_id,
        token,
        setAssignedPermissions,
        setBtnLoading
      );
    }
  }, []);
  const [buttonVisible, setButtonVisible] = useState(true);
  const [divVisible, setDivVisible] = useState(false);
  const [newname, setNewName] = useState("");
  async function listofProject() {
    try {
      const proejctFormData = {
        department_id: JSON.parse(departIdasInt),
      };
      const projectData = await getProjects(
        proejctFormData,
        storageInfo.data.token
      );
      const resultProjects = projectData?.data?.data;
      setListData(resultProjects);
    } catch (error) {}
  }
  const handleButtonClick = () => {
    if (userRole === "admin") {
      toast.error("Please select a department first!");
    } else {
      if (assignedPermissions.createProject) {
        setprojectid("");
        setButtonVisible(false);
        setDivVisible(true);
      } else {
        toast.error(
          "You don't have permission for this action. Please contact your admin!"
        );
      }
    }
  };
  const handBlur = async () => {
    setButtonVisible(true);
    setDivVisible(false);
    try {
      const formData = new FormData();
      formData.append("department_id", departIdasInt);
      formData.append("name", newname);
      const response = await api.post("project/create-project", formData, {
        headers: {
          Authorization: `Bearer ${storageInfo?.data?.token}`,
        },
      });
      const newProject = response.data.data;
      setListData((prevListData) => [newProject, ...prevListData]);
      toast.success(response.data.message);
      setNewName("");
      return false;
    } catch (error) {
      if (error?.response?.data?.data === "permission") {
        navigate("/404");
      }
      if (error?.response?.data?.data === "plan") {
        navigate("/dashboard");
      }
    }
  };
  const handleInputBlur = (e) => {
    if (e.key === "Enter") {
      handBlur();
    }
  };
  const myInputRef = useRef(null);
  useEffect(() => {
    if (divVisible && myInputRef.current) {
      myInputRef.current.focus();
    }
  }, [divVisible]);
  return (
    <div>
      <Container>
        <div className="dashboard_main">
          <div className="main_body">
            <LeftSide />
            <div className="right_side w-100">
              <div className="container-fluid">
                <div className="dashboard_inner col-11 m-auto">
                  <ProjectHead resetForm={resetForm} />
                  <div className="filter_part">
                    <div className="date_filter">
                      <label className="form-label pe-2 m-0">By Date</label>
                      <DateRangePicker
                        appearance="default"
                        placeholder="Filter by Date"
                        id="get_range"
                        style={{ width: 230 }}
                        onChange={handleDates}
                      />
                    </div>

                    <div className="filter_field">
                      <div className="search_filter_field">
                        <input
                          type="text"
                          className="search_input"
                          placeholder="Search List"
                          value={searchName}
                          onChange={searchHandler}
                        />
                        {searchName === "" ? (
                          <AiOutlineSearch />
                        ) : (
                          <RxCross2 onClick={removeInput} />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="scroll_class">
                    <div className="width_overflow">
                      <ProjectListHead />

                      <div className="mainlistdiv">
                        <div className="d-flex">
                          {buttonVisible && (
                            <div
                              className="add_project"
                              onClick={handleButtonClick}
                            >
                              <i className="fa-solid fa-plus"></i>
                              <p>Add Project</p>
                            </div>
                          )}
                          {divVisible && (
                            <input
                              className="form-control w-auto mt-2 mb-2"
                              placeholder="Add new project"
                              ref={myInputRef}
                              value={newname}
                              onKeyDown={handleInputBlur}
                              onChange={(e) => setNewName(e.target.value)}
                              onBlur={handBlur}
                            />
                          )}
                        </div>
                        <div
                          className={
                            listdataset?.length >= 5 ? "projectlist_main" : ""
                          }
                        >
                          {listdataset?.length === 0 ? (
                            <ImageFunction
                              name="Project"
                              line="Try to create more projects to your employees or"
                            />
                          ) : (
                            listdataset.map((post) => (
                              <ProjectList
                                post={post}
                                listdataset={listdataset}
                                editCard={editCard}
                              />
                            ))
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {listdataset?.length > 10 && (
                    <LoadMore
                      loading={loading}
                      handleLoadMore={handleLoadMore}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default ProjectsList;
