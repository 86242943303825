import axios from "axios";
import { accessDept } from "../../api/department";
import { storageData } from "./helper";

const baseURL = process.env.REACT_APP_API_URL;
const api = axios.create({
  baseURL: baseURL,
});

api.interceptors.request.use(
  async (config) => {
    try {
      const storageInfo = storageData();
      if (storageInfo) {
        const formData = {
          department_id: localStorage.getItem("u_did"),
        };
        const allowDepts = await accessDept(formData, storageInfo?.data?.token);
        if (allowDepts.status !== 200 || !allowDepts.data.success) {
          throw new Error("Access denied");
        } else {
          localStorage.setItem(
            "did",
            JSON.stringify(allowDepts?.data?.data?.did)
          );
          localStorage.setItem(
            "u_did",
            JSON.stringify(allowDepts?.data?.data?.u_did)
          );
        }
      }
    } catch (error) {
      throw error;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
