import React from "react";
import { Modal } from "react-bootstrap";
import { useTaskContextProvider } from "../../../utils/TaskContextProvider";

function AddPlan({
  planDescrition,
  planDuration,
  planPrice,
  departLimit,
  userLimit,
  planName,
  PlansUpdate,
  handleClose,
  PlansAdd,
}) {
  const {
    setCardID,
    planname,
    userlimit,
    departlimit,
    plandescrition,
    planduration,
    planprice,
  } = useTaskContextProvider();
  return (
    <div>
      <Modal.Header closeButton>
        <div className="model_heading">
          <h1 className="modal-title" id="staticBackdropLabel">
            {setCardID !== "" ? "Update Plan" : " Add New Plan  "}
          </h1>
          <p>
            {setCardID !== ""
              ? "You can update Plan, user limit and plan duration also add Price."
              : "You can add a Plan, user limit and plan duration also add Price."}
          </p>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="request_info">
          <form action="" className="row">
            <div className="col-md-6">
              <label
                for="validationCustom01"
                className="form-label keyword_label"
              >
                Name
              </label>
              <input
                type="text"
                className="form-control"
                id="validationCustom01"
                placeholder="Plan Name"
                onChange={planName}
                value={planname}
                required
              />
            </div>
            <div className="col-md-6">
              <label
                for="validationCustom01"
                className="form-label keyword_label"
              >
                User Limit
              </label>
              <input
                type="text"
                className="form-control"
                id="validationCustom01"
                placeholder="Max 25"
                value={userlimit}
                onChange={userLimit}
                required
              />
            </div>
            <div className="col-md-6">
              <label
                for="validationCustom01"
                className="form-label keyword_label"
              >
                Department Limit
              </label>
              <input
                type="text"
                className="form-control"
                id="validationCustom01"
                placeholder="Max 2"
                onChange={departLimit}
                value={departlimit}
                required
              />
            </div>
            <div className="col-md-6">
              <label
                for="validationCustom01"
                className="form-label keyword_label"
              >
                Duration
              </label>
              <input
                type="text"
                className="form-control"
                id="validationCustom01"
                placeholder="Enter duration"
                onChange={planDuration}
                value={planduration}
                required
              />
            </div>
            <div className="col-md-6">
              <label
                for="validationCustom01"
                className="form-label keyword_label"
              >
                Price
              </label>
              <input
                type="text"
                className="form-control"
                id="validationCustom01"
                placeholder="Enter Price"
                onChange={planPrice}
                value={planprice}
                required
              />
            </div>
            <div className="col-lg-6 col-md-6 p-2">
              <input type="text" value={setCardID} name="id" hidden />
            </div>
            <div className="col-md-12">
              <label
                for="validationCustom01"
                className="form-label keyword_label"
              >
                Add description
              </label>
              <textarea
                rows="5"
                cols="50"
                className="text_box"
                onChange={planDescrition}
                placeholder="Write Something here"
                value={plandescrition}
              />
            </div>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer className="sheet_footer">
        <button
          variant="secondary"
          className="cancel_btn"
          onClick={handleClose}
        >
          Cancel
        </button>
        <button
          variant="primary"
          className="request_btn apply_btn"
          onClick={setCardID !== "" ? () => PlansUpdate() : () => PlansAdd()}
        >
          Save
        </button>
      </Modal.Footer>
    </div>
  );
}

export default AddPlan;
