import React, { useState } from "react";
import { ChromePicker } from "react-color";
import { handleCreateStatusHelper } from "../helpers/TaskHelpers";

const AddStatus = ({ setAddStatus, projectId, getBoardProjects, data }) => {
  const [newStatusName, setNewStatusName] = useState(
    data !== undefined ? data.status_name : ""
  );
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [selectedColor, setSelectedColor] = useState(
    data !== undefined ? data.color_code : "#000000"
  );

  const handleNewStatusNameChange = (e) => {
    setNewStatusName(e.target.value);
  };

  const projectIdInt = parseInt(projectId, 10);

  const handleEnterKey = async (e) => {
    try {
      await handleCreateStatusHelper(
        e,
        data,
        projectIdInt,
        selectedColor,
        newStatusName,
        setNewStatusName,
        getBoardProjects,
        setAddStatus
      );
    } catch (error) {
      console.error("An error occurred in handleEnterKey:", error);
    }
  };

  return (
    <div className="add_status_container">
      <input
        onMouseEnter={() => setShowColorPicker(true)}
        className="form-control add_status_field"
        placeholder="Status Name"
        value={newStatusName}
        onChange={handleNewStatusNameChange}
        onKeyDown={handleEnterKey}
        style={{
          borderTopColor: selectedColor,
          borderTop: `2px solid ${selectedColor}`,
        }}
      />
      {showColorPicker && (
        <ChromePicker
          color={selectedColor}
          onChange={(color) => setSelectedColor(color.hex)}
        />
      )}
    </div>
  );
};

export default AddStatus;
