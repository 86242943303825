import React, { useEffect, useState } from "react";
import Container from "../../components/layouts/container";
import LeftSide from "../../components/layouts/left_side";
import ListHead from "../../components/members/ListHead";
import TaskMemberData from "../../components/members/TaskMemberData";
import { useTaskContextProvider } from "../../utils/TaskContextProvider";
import { AiOutlineSearch } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";
import { getChecklist, getTaskDetails, memberTaskList } from "../../api/task";
import { storageData } from "../../components/helpers/helper";
import { DateRangePicker } from "rsuite";
import LoadMore from "../../components/projects/LoadMore";

function MemberTaskList() {
  const storageInfo = storageData();
  const {
    memtasklist,
    setMemTaskList,
    setTaskDetails,
    setCheckLists,
    loading,
    setLoading,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    searchQuery,
    pageNumber,
    setPageNumber,
  } = useTaskContextProvider();
  const pathname = window.location.pathname;
  const parts = pathname.split("/");
  const ShowMemberTask = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("mid", parts[2]);
      const checkLists = await memberTaskList(formData, storageInfo.data.token);
      const data = checkLists.data.data;
      if (data.length > 0) {
        setMemTaskList(data);

        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {}
  };
  const ShowFilterMemberTask = async (mid, searchText, startdate, enddate) => {
    try {
      const formData = {
        mid: mid ? mid : parts[2],
        start_date: startdate ? startdate : "",
        end_date: enddate ? enddate : "",
        search: searchText ? searchText : "",
        // page_no: pageNumber,
      };
      if (searchText.length >= 3) {
        const checkLists = await memberTaskList(
          formData,
          storageInfo.data.token
        );
        setMemTaskList(checkLists.data.data);
        setStartDate("");
        setEndDate("");
        setShowAddModal(true);
      } else if (searchText.length === 0) {
        const checkLists = await memberTaskList(
          formData,
          storageInfo.data.token
        );
        setMemTaskList(checkLists.data.data);
        setStartDate("");
        setEndDate("");
        setShowAddModal(true);
      }
    } catch (error) {}
  };
  useEffect(() => {
    ShowMemberTask();
  }, []);
  const [showTaskDetails, setShowTaskDetails] = useState(false);
  const handleCloseTaskDetails = () => {
    setShowTaskDetails(false);
  };
  const handleShowDetails = async (tid, pid) => {
    setShowTaskDetails(true);
    setLoading(true);
    try {
      const formData = {
        task_id: tid,
        project_id: pid,
      };
      const taskDetails = await getTaskDetails(
        formData,
        storageInfo.data.token
      );

      const formData_ = {
        task_id: tid,
      };
      const checkLists = await getChecklist(formData_, storageInfo.data.token);
      setCheckLists(checkLists.data.data);
      setTaskDetails(taskDetails.data);
      setLoading(false);
    } catch (error) {}
  };
  const handleDates = (event) => {
    if (event !== null) {
      const originalStartDate = new Date(event[0]);
      const origingalEndDate = new Date(event[1]);

      const startYear = originalStartDate.getFullYear();
      const startMonth = (originalStartDate.getMonth() + 1)
        .toString()
        .padStart(2, "0");
      const startDay = originalStartDate.getDate().toString().padStart(2, "0");
      const formattedStartDate = `${startYear}-${startMonth}-${startDay}`;

      const endYear = origingalEndDate.getFullYear();
      const endMonth = (origingalEndDate.getMonth() + 1)
        .toString()
        .padStart(2, "0");
      const endDay = origingalEndDate.getDate().toString().padStart(2, "0");
      const formattedEndDate = `${endYear}-${endMonth}-${endDay}`;

      setStartDate(formattedStartDate);
      setEndDate(formattedEndDate);
      ShowFilterMemberTask(
        parts[2],
        searchQuery,
        formattedStartDate,
        formattedEndDate
      );
    } else {
      ShowFilterMemberTask(parts[2], searchQuery, null, null);
    }
  };
  const [searchName, setSearchName] = useState("");
  const searchHandler = (e) => {
    setSearchName(e.target.value);
    ShowFilterMemberTask(parts[2], e.target.value, startDate, endDate);
  };

  const removeInput = () => {
    setSearchName("");
    ShowFilterMemberTask(null, "", null, null);
    setShowAddModal(true);
  };
  const [showAddModal, setShowAddModal] = useState(true);
  const handleLoadMore = async () => {
    try {
      const formData = {
        mid: parts[2],
        start_date: startDate,
        end_date: endDate,
        search: searchQuery,
        page_no: pageNumber,
      };
      const response = await memberTaskList(formData, storageInfo.data.token);
      const data = response.data.data;
      if (data.length > 0) {
        setMemTaskList((prevData) => [...prevData, ...data]);
        setPageNumber((prevPage) => prevPage + 1);
        setLoading(false);
      } else {
        setShowAddModal(false);
        setLoading(false);
      }
    } catch (error) {}
  };
  return (
    <div>
      <Container>
        <div className="dashboard_main">
          <div className="main_body">
            <LeftSide />
            <div className="right_side w-100">
              <div className="container-fluid">
                <div className="dashboard_inner col-11 m-auto">
                  <div className="board_header">
                    <div className="main_heading">
                      <h3>{memtasklist[0]?.member_name}</h3>
                    </div>
                  </div>
                  <div className="filter_part">
                    <div className="date_filter">
                      <label className="form-label pe-2 m-0">By Date</label>
                      <DateRangePicker
                        appearance="default"
                        placeholder="Default"
                        id="get_range"
                        style={{ width: 230 }}
                        onChange={handleDates}
                      />
                    </div>
                    <div className="filter_field">
                      <div className="search_filter_field">
                        <input
                          type="text"
                          className="search_input"
                          placeholder="Search List"
                          value={searchName}
                          onChange={searchHandler}
                        />
                        {searchName === "" ? (
                          <AiOutlineSearch />
                        ) : (
                          <RxCross2 onClick={removeInput} />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="scroll_class">
                    <div className="width_overflow">
                      <ListHead />
                      <div className="mainlistdiv">
                        <div
                          className={
                            memtasklist.length >= 5 ? "projectlist_main" : ""
                          }
                        >
                          <TaskMemberData
                            handleCloseTaskDetails={handleCloseTaskDetails}
                            handleShowDetails={handleShowDetails}
                            showTaskDetails={showTaskDetails}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-3">
                    {showAddModal === true
                      ? memtasklist?.length >= 10 && (
                          <LoadMore
                            loading={loading}
                            handleLoadMore={handleLoadMore}
                          />
                        )
                      : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default MemberTaskList;
