import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { addCompany } from "../../api/profile";
import { useTaskContextProvider } from "../../utils/TaskContextProvider";
import { Spinner } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import LoginHeader from "../auth/login_header";
import { companyProfileValidationSchema } from "../../components/profile/ProfileValidation";
import GeneralSpinner from "../../components/spiner/Spiner";
import { getActivePlan } from "../../api/plan";
import rolePermissionsHelper from "../../components/helpers/rolePermissionHelper";
import axios from "axios";

function GoogleCallback() {
  const [token, setUserToken] = useState();
  const baseURL = process.env.REACT_APP_API_URL;
  const location = useLocation();
  const navigate = useNavigate();

  const {
    loading,
    setLoading,
    setActivePlan,
    setAssignedPermissions,
    setActivePlanId,
    isBtnLoading,
    setBtnLoading,
  } = useTaskContextProvider();

  const handleGoogleCallback = async () => {
    try {
      const code = new URLSearchParams(location.search).get("code");

      const formData = new FormData();
      formData.append("code", code);

      const response = await axios.post(
        `${baseURL}auth/google/callback`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error("Error:", error);
      navigate("/login");
    }
  };

  const onGoogle = async () => {
    setLoading(true);
    try {
      const response = await handleGoogleCallback();
      if (!response) throw new Error("No response from Google callback");

      setUserToken(response.data.token);

      if (response.data.company_info !== null) {
        localStorage.setItem("c_info", JSON.stringify(response));
        navigate("/dashboard");

        if (response.data.userDepartments.length !== 0) {
          if (response.data.user_type === "member") {
            localStorage.setItem(
              "did",
              JSON.stringify(response.data.userDepartments[0].id)
            );
            localStorage.setItem(
              "role_id",
              JSON.stringify(response.data.userDepartments[0].role_id)
            );
            localStorage.setItem(
              "u_did",
              JSON.stringify(
                response.data.userDepartments[0].user_department_id
              )
            );
          }
        }

        if (["admin", "member"].includes(response.data.user_type)) {
          try {
            const activeResponse = await getActivePlan("", response.data.token);
            localStorage.setItem(
              "activePlanStatus",
              activeResponse.data.success
            );
            setActivePlanId(activeResponse.data.basic_info.id);
            setActivePlan(true);
          } catch {
            localStorage.setItem("activePlanStatus", false);
            setActivePlan(false);
          }
          navigate("/dashboard");
        }

        const dept_id = response.data.userDepartments[0]?.user_department_id;
        const role_id = response.data.userDepartments[0]?.role_id;

        if (response.data.user_type === "member") {
          rolePermissionsHelper(
            dept_id,
            role_id,
            response.data.token,
            setAssignedPermissions,
            setBtnLoading
          );
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error in onGoogle:", error);
    }
  };

  useEffect(() => {
    onGoogle();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(companyProfileValidationSchema),
  });

  const onSubmit = async (data) => {
    setBtnLoading(true);
    try {
      const formData = new FormData();
      formData.append("name", data.company_name);
      const response = await addCompany(formData, token);
      localStorage.setItem("c_info", JSON.stringify(response.data));
      toast.success(response.data.message);
      if (response.data.success) {
        navigate("/dashboard");
      }
    } catch (error) {
      console.error("Error in onSubmit:", error);
    } finally {
      setBtnLoading(false);
    }
  };

  return (
    <>
      {!loading ? (
        <div className="main_body pb-4">
          <LoginHeader />
          <div className="container">
            <div className="sign_header col-lg-10 m-auto">
              <div className="heading_">
                <h3>Company Information</h3>
              </div>
            </div>
            <ToastContainer />
            <div className="col-lg-6 m-auto">
              <div className="signup_card mt-4">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="personal_info">
                    <div className="personal_form row g-3">
                      <div className="personal_col col-12">
                        <label>Company Name</label>
                        <input
                          {...register("company_name")}
                          name="company_name"
                          type="text"
                          className="form-control"
                          placeholder="Company Name"
                        />
                        {errors.company_name && (
                          <p className="error text-danger m-0">
                            {errors.company_name.message}
                          </p>
                        )}
                      </div>

                      <div className="sign_btn justify-content-center">
                        <button
                          type="submit"
                          className="request_btn apply_btn"
                          disabled={isBtnLoading}
                        >
                          {!isBtnLoading ? (
                            "Save"
                          ) : (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <GeneralSpinner />
      )}
    </>
  );
}

export default GoogleCallback;
