import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { ChromePicker } from "react-color";
import { AiOutlineSearch } from "react-icons/ai";
import { useTaskContextProvider } from "../../../utils/TaskContextProvider";

function StatusModal({
  handleSearch,
  task,
  setSmShow,
  addStatus,
  newStatusName,
  handleNewStatusNameChange,
  handleEnterKey,
  selectedColor,
  CreateStatusList,
  setSelectedColor,
  handleAddStatus,
  filteredOptions,
  editedStatus,
  handleStatusChange,
  updateStatus,
  statusModalShow,
  deleteStatus,
  editStatus,
}) {
  const { searchTerm, assignedPermissions, isBtnLoading } =
    useTaskContextProvider();
  const [showColorPicker, setShowColorPicker] = useState(false);
  return (
    <div>
      <Modal.Body className="status_modal">
        <div className="filter_top justify-content-between">
          <div className="search_filter_field">
            <input
              type="text"
              className="search_input w-100"
              placeholder="Search List"
              value={searchTerm}
              onChange={(e) => handleSearch(e.target.value, task.pid)}
            />
            <AiOutlineSearch />
          </div>
          <div onClick={() => setSmShow(false)}>
            <i className="fa-solid fa-xmark"></i>
          </div>
        </div>
        <div className="my-3">
          {addStatus ? (
            <div className="add_status_container  position-relative">
              <input
                onMouseEnter={() => setShowColorPicker(true)}
                className="form-control "
                placeholder="Status Name"
                value={newStatusName}
                onChange={handleNewStatusNameChange}
                onKeyDown={handleEnterKey}
                style={{
                  borderTopColor: selectedColor,
                  borderTop: `2px solid ${selectedColor}`,
                }}
              />
              <button
                className="save_color"
                onClick={() => CreateStatusList(task.pid)}
                disabled={isBtnLoading}
              >
                {!isBtnLoading ? (
                  "Save"
                ) : (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </button>
              {showColorPicker && (
                <ChromePicker
                  color={selectedColor}
                  onChange={(color) => setSelectedColor(color.hex)}
                />
              )}
            </div>
          ) : (
            <div
              className="d-flex justify-content-end"
              onClick={handleAddStatus}
            >
              <div className=" add_status_icon toggle_project">
                <i className="fa-solid fa-plus"></i>
              </div>
            </div>
          )}
        </div>
        <div className="status_list">
          <ul>
            {filteredOptions.map((post) => (
              <li
                style={{
                  textTransform: "capitalize",
                  borderTopColor: post.color_code,
                  borderTop: `2px solid ${post.color_code}`,
                }}
              >
                {editedStatus[post.status_id] ? (
                  <div className="status_input position-relative w-100">
                    <input
                      className="form-control"
                      type="text"
                      value={editedStatus[post.status_id] || ""}
                      onChange={(e) =>
                        handleStatusChange(e.target.value, post.status_id)
                      }
                    />
                    <button
                      className="save_color"
                      onClick={() =>
                        updateStatus(
                          post.project_id,
                          editedStatus[post.status_id],
                          post.status_id
                        )
                      }
                    >
                      Save
                    </button>
                  </div>
                ) : post.project_id === null ? (
                  <p
                    className="m-0"
                    onClick={() => statusModalShow(post.status_id)}
                  >
                    {post.status_name}
                  </p>
                ) : (
                  <>
                    <p
                      className="m-0"
                      onClick={() => statusModalShow(post.status_id)}
                    >
                      {post.status_name}
                    </p>
                    <div className="edit-delet">
                      {assignedPermissions.createStatus && (
                        <span
                          onClick={() =>
                            editStatus(post.status_name, post.status_id)
                          }
                        >
                          <i className="fa-solid fa-pencil"></i>
                        </span>
                      )}

                      {assignedPermissions.deleteStatus && (
                        <span onClick={() => deleteStatus(post.status_id)}>
                          <i className="green_clr fa-solid fa-trash"></i>
                        </span>
                      )}
                    </div>
                  </>
                )}
              </li>
            ))}
          </ul>
        </div>
      </Modal.Body>
    </div>
  );
}

export default StatusModal;
