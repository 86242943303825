import * as yup from "yup";

export const authValidationSchema = yup.object({
  comp_name: yup.string().required("Company name is required"),
  comp_email: yup.string().required("Email is required"),
  password: yup.string().required("Password is required"),
  c_password: yup.string().required("Confirm Password is required"),
});
export const loginValidationSchema = yup.object({
  login_email: yup.string().required("Email is required"),
  login_password: yup.string().required("Password is required"),
});
export const forgetValidationSchema = yup.object({
  forget_email: yup.string().required("Email is required"),
});
export const recoverValidationSchema = yup.object({
  recover_email: yup.string().required("Email is required"),
  recover_code: yup.string().required("Code is required"),
  recover_password: yup.string().required("Password is required"),
  recover_conf_password: yup.string().required("Confirm Password is required"),
});

export const userVerificationSchema = yup.object({
  name: yup.string().required("Name is required"),
  recover_password: yup.string().required("Password is required"),
  recover_conf_password: yup.string().required("Confirm Password is required"),
});
