import React from "react";

function SubscriptionHeader() {
  return (
    <div>
      <div className="board_header">
        <div className="main_heading">
          <h3>Subscription</h3>
          <p>
            Check your plan, billing Info, purchase history and upgrade your
            plan
          </p>
        </div>
      </div>
    </div>
  );
}

export default SubscriptionHeader;
