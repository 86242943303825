import React, { useState, useEffect } from "react";
import "../auth/login.css";
import "react-toastify/dist/ReactToastify.css";
import google from "../../assets/images/google.png";
import line from "../../assets/images/line.png";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LoginHeader from "../auth/login_header";
import Gbutton from "../auth/gbutton";
import Auth from "../../api/login";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginValidationSchema } from "./auth_validation";
import { useTaskContextProvider } from "../../utils/TaskContextProvider";
import Spinner from "react-bootstrap/Spinner";
import { getActivePlan } from "../../api/plan";
import { GoogleAuth } from "../../api/social";
import rolePermissionsHelper from "../../components/helpers/rolePermissionHelper";

function Login() {
  const navigate = useNavigate();
  const {
    setActivePlan,
    setAssignedPermissions,
    isBtnLoading,
    setBtnLoading,
    setActivePlanId,
  } = useTaskContextProvider();

  const [passwordType, setPasswordType] = useState("password");
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    } else {
      setPasswordType("password");
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginValidationSchema),
  });
  let emailCheck = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/i;

  const onSubmit = async (data) => {
    setBtnLoading(true);
    if (!data.login_email.match(emailCheck)) {
      toast.error("Enter a valid email");
      return false;
    }

    const formData = {
      email: data.login_email,
      password: data.login_password,
    };
    try {
      const response = await Auth(formData);
      toast.success(response.data.message);

      localStorage.setItem("c_info", JSON.stringify(response.data));
      if (response.data.data.user_type === "superadmin") {
        navigate("/superadmin");
      } else {
        navigate("/dashboard");
      }
      if (response.data.data.user_type !== "superadmin") {
        if (response.data.data.userDepartments.length !== 0) {
          if (response.data.data.user_type === "member") {
            localStorage.setItem(
              "did",
              JSON.stringify(response?.data?.data?.userDepartments[0]?.id)
            );
            localStorage.setItem(
              "role_id",
              JSON.stringify(response?.data?.data?.userDepartments[0]?.role_id)
            );
            localStorage.setItem(
              "u_did",
              JSON.stringify(
                response?.data?.data?.userDepartments[0]?.user_department_id
              )
            );
          }
        }
      }

      const token = response?.data?.data?.token;

      if (
        response.data.data.user_type === "admin" ||
        response.data.data.user_type === "member"
      ) {
        try {
          const activeResponse = await getActivePlan("", token);
          localStorage.setItem("activePlanStatus", activeResponse.data.success);
          setActivePlanId(activeResponse.data.data.basic_info.id);
          setActivePlan(true);
          navigate("/dashboard");
        } catch (error) {
          localStorage.setItem("activePlanStatus", false);
          setActivePlan(false);
          navigate("/dashboard");
        }
      }
      const dept_id =
        response?.data?.data?.userDepartments[0]?.user_department_id;
      const role_id = response?.data?.data?.userDepartments[0]?.role_id;

      if (response.data.data.user_type === "member") {
        rolePermissionsHelper(
          dept_id,
          role_id,
          token,
          setAssignedPermissions,
          setBtnLoading
        );
      }

      setBtnLoading(false);
    } catch (error) {
      setBtnLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };
  const [loginUrl, setLoginUrl] = useState(null);
  // const [FBloginUrl, setFBLoginUrl] = useState(null);

  console.log("=====loginUrl=====", loginUrl);

  const handleGoogleLogin = async () => {
    try {
      const response = await GoogleAuth();
      setLoginUrl(response.data.url);
    } catch (error) {
      console.error(error.response.data);
    }
  };

  // const handleFacebookLogin = async () => {
  //   try {
  //     const response = await FacebookAuth();
  //     setFBLoginUrl(response.data.url);
  //   } catch (error) {
  //     console.error(error.response.data);
  //   }
  // };

  useEffect(() => {
    // handleFacebookLogin();
    handleGoogleLogin();
    const token = localStorage.getItem("c_info");
    if (token) {
      navigate("/dashboard");
    }
  }, [navigate]);

  return (
    <div>
      <div className="mian_body pb-4">
        <LoginHeader />
        <div className="container">
          <div className="sign_header col-lg-10 m-auto">
            <div className="heading_">
              <h3>Login</h3>
            </div>
          </div>
          <div className="col-lg-6 m-auto">
            <div className="signup_card mt-4">
              <h3>Login Your Account</h3>
              <div className="social_icons d-flex justify-content-center pt-3 pb-3">
                <Link to={loginUrl}>
                  <div className="google_icon">
                    <img src={google} alt="GOOGLE" />
                  </div>
                </Link>
                {/* <div className="face_icon">
                  <Link to={FBloginUrl}>
                    <img src={facebook} alt="FACEBOOK" />
                  </Link>
                </div> */}
              </div>
              <div className="line my-2">
                <img src={line} alt="line" />
              </div>
              <div className="fields_area">
                <form
                  className="row g-3"
                  onSubmit={handleSubmit(onSubmit)}
                  autoComplete="off"
                >
                  <div className="col-md-12">
                    <label className="form-label">Email Address</label>
                    <input
                      {...register("login_email")}
                      name="login_email"
                      type="text"
                      placeholder="yourname@domain.com"
                      className="form-control"
                    ></input>
                    {errors.login_email && (
                      <p className="error text-danger m-0">
                        {errors.login_email.message}
                      </p>
                    )}
                  </div>
                  <div className="col-md-12 position-relative">
                    <label className="form-label">Password</label>
                    <input
                      {...register("login_password")}
                      type={passwordType}
                      name="login_password"
                      placeholder="Password"
                      className="form-control"
                    ></input>
                    {errors.login_password && (
                      <p className="error text-danger m-0">
                        {errors.login_password.message}
                      </p>
                    )}
                    <div className="input-group-btn pass_icon">
                      <span onClick={togglePassword}>
                        {passwordType === "password" ? (
                          <i className="fa-sharp fa-solid fa-eye-slash"></i>
                        ) : (
                          <i className="fa-solid fa-eye"></i>
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="remember_forget">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        Remember Me
                      </label>
                    </div>
                    <div className="forget">
                      <Link to="/forget-password">Forgot Password</Link>
                    </div>
                  </div>

                  <Gbutton
                    name={
                      !isBtnLoading ? (
                        "Login"
                      ) : (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )
                    }
                  />
                </form>
              </div>
              <div className="terms">
                <p>
                  By clicking signup you agree to our
                  <span> Terms and Condition</span> and{" "}
                  <span>Privacy Policy</span> of using this app.
                </p>
              </div>
              <hr />
              <div className="terms text-center">
                <p>
                  Don't have an account?{" "}
                  <Link to="/signup">
                    <span>Sign Up</span>
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
