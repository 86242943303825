/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "./plans.css";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import Container from "../../components/layouts/container";
import LeftSide from "../../components/layouts/left_side";
import {
  addPlans,
  dellPlans,
  editPlans,
  getPlans,
  updatePlans,
} from "../../api/plan";
import allDepartments, {
  addDepartments,
  dellDepartment,
  editDepartment,
  updateDepartment,
} from "../../api/department";
import { storageData } from "../../components/helpers/helper";
import PlansTabs from "../../components/plans/PlansTabs";
import { useTaskContextProvider } from "../../utils/TaskContextProvider";
import GeneralSpinner from "../../components/spiner/Spiner";
import ImageFunction from "../../components/department/ImageFunction";
import DepartmentTable from "../../components/plans/DepartmentTable";
import PlanTableMobile from "../../components/plans/PlanTableMobile";
import PlanTable from "../../components/plans/PlanTable";
import AddPlan from "../../components/plans/modals/AddPlan";
import AddDepartment from "../../components/plans/modals/AddDepartment";
import DeleteModal from "../../components/projects/DeleteModal";
function Plans() {
  const storageInfo = storageData();
  const tokenid = storageInfo.data.token;
  const {
    loading,
    setLoading,
    departname,
    planname,
    userlimit,
    departlimit,
    plandescrition,
    planduration,
    planprice,
    setplanPrice,
    setplanDuration,
    setplanDescrition,
    setdepartLimit,
    setuserLimit,
    setplanName,
    setDepartName,
    setCardID,
    setID,
  } = useTaskContextProvider();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const [dataset, setData] = useState([]);
  const [plandata, setPlan] = useState([]);

  const departName = (event) => {
    setDepartName(event.target.value);
  };
  const planName = (event) => {
    setplanName(event.target.value);
  };
  const userLimit = (event) => {
    setuserLimit(event.target.value);
  };
  const departLimit = (event) => {
    setdepartLimit(event.target.value);
  };
  const planPrice = (event) => {
    setplanPrice(event.target.value);
  };
  const planDuration = (event) => {
    setplanDuration(event.target.value);
  };
  const planDescrition = (event) => {
    setplanDescrition(event.target.value);
  };
  const showPlan = async () => {
    setLoading(true);
    try {
      const formData = {};
      const Token = tokenid;
      const response = await getPlans(formData, Token);
      const data = response.data.data ? response.data.data : "";
      setPlan(data);
      setLoading(false);
      return false;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  useEffect(() => {
    showPlan();
  }, []);

  const PlansAdd = async () => {
    if (planname === "") {
      toast.error("Please enter plan name");
      return false;
    }
    if (departlimit === "") {
      toast.error("Please enter department limit");
      return false;
    } else if (userlimit === "") {
      toast.error("Please enter user limit");
      return false;
    } else if (planduration === "") {
      toast.error("Please enter plan duration");
      return false;
    } else if (planprice === "") {
      toast.error("Please enter plan price");
      return false;
    } else if (plandescrition === "") {
      toast.error("Please enter plan description");
      return false;
    }
    try {
      const formData = {
        name: planname,
        dept_limit: departlimit,
        user_limit: userlimit,
        duration: planduration,
        price: planprice,
        description: plandescrition,
        id: setCardID ? setCardID : "",
      };
      const Token = tokenid;
      const response = await addPlans(formData, Token);
      handleClose();
      showPlan();
      toast.success(response.data.message);
      return false;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  const PlansUpdate = async () => {
    try {
      const formData = {
        name: planname,
        dept_limit: departlimit,
        user_limit: userlimit,
        duration: planduration,
        price: planprice,
        description: plandescrition,
        id: setCardID,
      };
      const Token = tokenid;
      const response = await updatePlans(formData, Token);
      handleClose();
      showPlan();
      toast.success(response.data.message);
      return false;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  const planEdit = async () => {
    try {
      const formData = {
        id: setCardID,
      };
      const Token = tokenid;
      const response = await editPlans(formData, Token);
      setplanName(response.data.data.name);
      setuserLimit(response.data.data.user_limit);
      setdepartLimit(response.data.data.dept_limit);
      setplanPrice(response.data.data.price);
      setplanDuration(response.data.data.duration);
      setplanDescrition(response.data.data.description);
      handleShow();
      toast.success(response.data.message);
      return false;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  const removePlan = async () => {
    try {
      const formData = {
        id: setCardID,
      };
      const Token = tokenid;
      const response = await dellPlans(formData, Token);
      showPlan();
      handleClose3();
      toast.success(response.data.message);
      return false;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  const showDeparts = async () => {
    setLoading(true);
    try {
      const formData = {};
      const Token = tokenid;
      const response = await allDepartments(formData, Token);
      const data = response.data.data ? response.data.data : "";
      setData(data);
      setLoading(false);
      return false;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const addDeparts = async () => {
    if (departname === "") {
      toast.error("Please enter department name");
      return false;
    }
    try {
      const formData = { name: departname };
      const Token = tokenid;
      const response = await addDepartments(formData, Token);
      showDeparts();
      handleClose1();
      toast.success(response.data.message);
      return false;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  const removeCard = async () => {
    try {
      const formData = {
        id: setCardID,
      };
      const Token = tokenid;
      const response = await dellDepartment(formData, Token);
      showDeparts();
      handleClose2();
      toast.success(response.data.message);
      return false;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  const editCard = async () => {
    try {
      const formData = {
        id: setCardID,
      };
      const Token = tokenid;
      const response = await editDepartment(formData, Token);
      setDepartName(response.data.data.name);
      handleShow1();
      toast.success(response.data.message);
      return false;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  const updateCard = async () => {
    try {
      const formData = {
        name: departname,
        id: setCardID,
      };
      const Token = tokenid;
      const response = await updateDepartment(formData, Token);
      handleClose1();
      showDeparts();
      toast.success(response.data.message);
      return false;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  const handleClick = (event) => {
    const cardID = event.currentTarget.id;
    setID(cardID);
  };
  const resetForm = () => {
    setID("");
    setplanName("");
    setuserLimit("");
    setdepartLimit("");
    setplanPrice("");
    setplanDuration("");
    setDepartName("");
    setplanDescrition("");
    handleShow();
  };
  const depresetForm = () => {
    setID("");
    setDepartName("");
    handleShow1();
  };
  return (
    <div>
      <Container>
        <div className="dashboard_main">
          <div className="main_body">
            <LeftSide />
            <div className="right_side w-100">
              <div className="container-fluid">
                <div className="dashboard_inner col-11 m-auto">
                  <div className="board_header">
                    <div className="main_heading">
                      <h3>Plans</h3>
                      <p>
                        You can see here your plans, user limit and plan
                        duration also add new plan here.
                      </p>
                    </div>
                  </div>
                  <div className="tabs_part">
                    <div className="row">
                      <PlansTabs showDeparts={showDeparts} />
                      <div className="col-lg-9 col-md-9">
                        <div className="tab-content" id="v-pills-tabContent">
                          <div
                            className="tab-pane m-0 fade active show"
                            id="v-pills-home"
                            role="tabpanel"
                            aria-labelledby="v-pills-home-tab"
                            tabIndex="0"
                          >
                            <div className="add_plan_btn">
                              <button
                                className="btn request_btn"
                                onClick={() => resetForm()}
                              >
                                Add New Plan
                              </button>
                            </div>
                            <div className="desktop_view_insight">
                              <div className="table_section mt-3">
                                <div className="table_head head_margin">
                                  <div className="row">
                                    <div className="col-lg-2 col-md-2 col-sm-2 plan_col">
                                      <p>Name</p>
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-2 plan_col">
                                      <p>Department</p>
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-2 plan_col">
                                      <p>User</p>
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-2 plan_col">
                                      <p>Duration</p>
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-2 plan_col">
                                      <p>Price</p>
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-2 plan_col"></div>
                                  </div>
                                </div>

                                {!loading ? (
                                  plandata.length === 0 ? (
                                    <ImageFunction
                                      name="Plan"
                                      line="Try to add more plans"
                                    />
                                  ) : (
                                    <PlanTable
                                      plandata={plandata}
                                      planEdit={planEdit}
                                      handleShow3={handleShow3}
                                      handleClick={handleClick}
                                    />
                                  )
                                ) : (
                                  <GeneralSpinner />
                                )}
                              </div>
                            </div>
                            {!loading ? (
                              plandata.length === 0 ? (
                                <ImageFunction
                                  name="Plan"
                                  line="Try to add more plans"
                                />
                              ) : (
                                <PlanTableMobile
                                  plandata={plandata}
                                  planEdit={planEdit}
                                  handleShow3={handleShow3}
                                  handleClick={handleClick}
                                />
                              )
                            ) : (
                              <GeneralSpinner />
                            )}
                          </div>
                          <div
                            className="tab-pane m-0 fade"
                            id="v-pills-profile"
                            role="tabpanel"
                            aria-labelledby="v-pills-profile-tab"
                            tabIndex="0"
                          >
                            <div className="add_plan_btn">
                              <button
                                className="btn request_btn"
                                onClick={() => depresetForm()}
                              >
                                Add New Department
                              </button>
                            </div>
                            <div className="table_section mt-3">
                              <div className="table_head head_margin">
                                <div className="row">
                                  <div className=" col-8">
                                    <p>Department Name</p>
                                  </div>
                                </div>
                              </div>
                              {!loading ? (
                                dataset.length === 0 ? (
                                  <ImageFunction
                                    name="Department"
                                    line="Try to add more Departments"
                                  />
                                ) : (
                                  <DepartmentTable
                                    dataset={dataset}
                                    handleShow2={handleShow2}
                                    editCard={editCard}
                                    handleClick={handleClick}
                                  />
                                )
                              ) : (
                                <GeneralSpinner />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={show}
          onHide={handleClose}
          size="lg"
          backdrop="static"
          keyboard={false}
        >
          <AddPlan
            planDescrition={planDescrition}
            planDuration={planDuration}
            planPrice={planPrice}
            departLimit={departLimit}
            userLimit={userLimit}
            planName={planName}
            PlansUpdate={PlansUpdate}
            handleClose={handleClose}
            PlansAdd={PlansAdd}
          />
        </Modal>
        <Modal
          show={show1}
          onHide={handleClose1}
          backdrop="static"
          keyboard={false}
        >
          <AddDepartment
            departName={departName}
            handleClose1={handleClose1}
            updateCard={updateCard}
            addDeparts={addDeparts}
          />
        </Modal>
        <Modal
          show={show2}
          onHide={handleClose2}
          backdrop="static"
          keyboard={false}
        >
          <DeleteModal
            title="department"
            handleClose2={handleClose2}
            removeCard={removeCard}
          />
        </Modal>
        <Modal
          show={show3}
          onHide={handleClose3}
          backdrop="static"
          keyboard={false}
        >
          <DeleteModal
            title="plan"
            handleClose2={handleClose3}
            removeCard={removePlan}
          />
        </Modal>
      </Container>
    </div>
  );
}
export default Plans;
