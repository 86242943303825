import React from "react";
import logo from "../../assets/images/logo1.webp";
import bell from "../../assets/images/bell.png";
import user_logo from "../../assets/images/1.webp";
import menu from "../../assets/images/menu2.png";
import profile from "../../assets/images/profile.png";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as Logout } from "../../assets/images/Logout Icon.svg";
import { useTaskContextProvider } from "../../utils/TaskContextProvider";
import { storageData } from "../helpers/helper";
function Header() {
  const currentURL = useLocation();
  const storageInfo = storageData();
  const companyData = storageInfo.data.company_info;
  const departmentName = storageInfo.data.userDepartments;
  const { leftSideMenu, assignedPermissions } = useTaskContextProvider();

  const userType = storageInfo.data.user_type;
  const userPic = storageInfo.data.profile_image;
  const companyPic = storageInfo?.data.company_info?.company_logo;
  const baseURL = process.env.REACT_APP_URL;

  function Storage() {
    localStorage.clear();
  }
  return (
    <div>
      <div className="main_header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 col-6">
              <div className="logo p-3">
                <Link to="/dashboard">
                  <img src={logo} alt="LOGO" />
                </Link>
              </div>
            </div>
            <div className="col-lg-5 head_col">
              <div className="header_area w-100">
                <div className="heading_col">
                  <div className="role_heading">
                    {userType === "member" && departmentName?.length === 0 ? (
                      ""
                    ) : (
                      <h3>
                        {userType === "member" ? "I Belong to" : "Welcome"}{" "}
                        <span>
                          {userType === "superadmin"
                            ? storageInfo.data.full_name || companyData.name
                            : userType === "admin"
                            ? companyData.name
                            : userType === "member"
                            ? departmentName[0].tmp_department_name
                            : ""}
                        </span>
                      </h3>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-6 profile_col">
              <div className="profile_side ">
                {/* <div className="bell_icon position-relative">
                  <div className="nav-item dropdown">
                    <button
                      className="notice_btn"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img src={bell} alt="bell" />
                      <div className="bell_num">
                        <p>10</p>
                      </div>
                    </button>
                    <ul className="dropdown-menu drop_show">
                      <li>
                        <div className="notice_heading">
                          <h4>Activity</h4>
                          <h5>All</h5>
                        </div>
                      </li>
                      <li>
                        <Link className="dropdown-item notice_items">
                          <div className="image_name">
                            <img src={user_logo} alt="" />

                            <div className="notice_name">
                              <p className="notice_user">John Doe</p>
                              <p className="notice_assign">
                                Assigned a new task to you
                              </p>
                            </div>
                            <div className="notice_time">
                              <p>1 hours ago</p>
                            </div>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item notice_items">
                          <div className="image_name">
                            <img src={user_logo} alt="" />
                            <div className="notice_name">
                              <p className="notice_user">John Doe</p>
                              <p className="notice_assign">
                                Assigned a new task to you
                              </p>
                            </div>
                            <div className="notice_time">
                              <p>1 hours ago</p>
                            </div>
                          </div>
                        </Link>
                      </li>

                      <li>
                        <Link className="dropdown-item notice_items">
                          <div className="image_name">
                            <img src={user_logo} alt="" />
                            <div className="notice_name">
                              <p className="notice_user">John Doe</p>
                              <p className="notice_assign">
                                Assigned a new task to you
                              </p>
                            </div>
                            <div className="notice_time">
                              <p>1 hours ago</p>
                            </div>
                          </div>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div> */}
                <Link to="/profile-info">
                  <div className="profile_name">
                    {userType === "admin" ? (
                      <img
                        src={
                          companyPic === null
                            ? profile
                            : `${baseURL}${companyPic}`
                        }
                        alt="Company Logo"
                      />
                    ) : (
                      <img
                        src={
                          userPic === null ? profile : `${baseURL}${userPic}`
                        }
                        alt="User"
                      />
                    )}
                    <p>
                      {userType === "superadmin"
                        ? storageInfo.data.full_name || companyData.name
                        : userType === "member"
                        ? storageInfo.data.full_name
                        : ""}
                    </p>
                  </div>
                </Link>
              </div>
              <div className="togale">
                <button
                  className="btn menu_btn"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasRight"
                  aria-controls="offcanvasRight"
                >
                  <img src={menu} alt="menu" />
                </button>
                <div
                  className="offcanvas offcanvas-end"
                  tabIndex="-1"
                  id="offcanvasRight"
                  aria-labelledby="offcanvasRightLabel"
                >
                  <div className="offcanvas-header">
                    <Link to="/profile-info">
                      <div className="profile_name_menu">
                        {userType === "admin" ? (
                          <img
                            src={
                              companyPic === null
                                ? profile
                                : `${baseURL}${companyPic}`
                            }
                            alt="Company Logo"
                          />
                        ) : (
                          <img
                            src={
                              userPic === null
                                ? profile
                                : `${baseURL}${userPic}`
                            }
                            alt="user"
                          />
                        )}
                        <p>
                          {storageInfo.data.full_name === null
                            ? storageInfo.data.company_info.name
                            : storageInfo.data.full_name !== null
                            ? storageInfo.data.full_name
                            : companyData.name}
                        </p>
                      </div>
                    </Link>

                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="offcanvas-body">
                    <div className="role_heading">
                      {userType === "member" && departmentName?.length === 0 ? (
                        ""
                      ) : (
                        <h3>
                          {userType === "member" ? "I Belong to" : "Welcome"}{" "}
                          <span>
                            {userType === "superadmin"
                              ? storageInfo.data.full_name || companyData.name
                              : userType === "admin"
                              ? companyData.name
                              : userType === "member"
                              ? departmentName[0].tmp_department_name
                              : ""}
                          </span>
                        </h3>
                      )}
                    </div>
                    <div className="left_menu">
                      <div className="main_canvas">
                        <ul className="nav nav-tabs flex-column">
                          {leftSideMenu.length !== 0 &&
                            leftSideMenu?.map((post, index) => {
                              if (
                                post.name === "Members" &&
                                departmentName?.length === 0 &&
                                !assignedPermissions.members
                              ) {
                                return null;
                              }
                              if (
                                post.name === "Projects" &&
                                !assignedPermissions.projectList
                              ) {
                                return null;
                              }
                              if (
                                post.name === "My Task" &&
                                !assignedPermissions.taskList &&
                                !assignedPermissions.taskBoard
                              ) {
                                return null;
                              }
                              return (
                                <li className="nav-item" key={index}>
                                  <Link
                                    className={`nav-link side_link ${
                                      currentURL.pathname === post.url
                                        ? "active_menu"
                                        : ""
                                    }`}
                                    aria-current="page"
                                    to={post.url}
                                  >
                                    {post.svg}
                                    <p>{post.name}</p>
                                  </Link>
                                </li>
                              );
                            })}
                          {/* <hr />
                          {commonmenu?.map((post) => {
                            return (
                              <li className="nav-item">
                                <Link
                                  className={`nav-link side_link ${
                                    currentURL.pathname === post.url
                                      ? "active_menu"
                                      : ""
                                  }`}
                                  aria-current="page"
                                  to={post.url}
                                >
                                  {post.svg}
                                  <p>{post.name}</p>
                                </Link>
                              </li>
                            );
                          })} */}

                          <hr />
                          <li className="nav-item">
                            <Link
                              className="nav-link side_link "
                              aria-current="page"
                              to="/login"
                              type="button"
                              onClick={Storage}
                            >
                              <Logout />
                              <p>Logout</p>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
