import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { createTask, extendTime } from "../../../api/task";
import { storageData } from "../../helpers/helper";
import { toast } from "react-toastify";

const RequestMoreTimeModal = ({
  handleCloseRequestTimeModal,
  taskId,
  formDataRT,
  ProjectsandTasks,
}) => {
  const [extendedNote, setExtendedNote] = useState("");

  const storageInfo = storageData();
  const handleNoteChange = (event) => {
    const newNote = event.target.value;
    setExtendedNote(newNote);
  };

  const handleSaveChanges = async () => {
    try {
      const formData = {
        task_id: taskId,
        note: extendedNote,
      };

      handleCloseRequestTimeModal();
      await extendTime(formData, storageInfo.data.token);
      const response = await createTask(formDataRT, storageInfo.data.token);
      ProjectsandTasks();
      toast.success(response.data.data.message);
    } catch (error) {}
  };

  return (
    <>
      <Modal.Header closeButton>
        <div className="model_heading">
          <h1 className="modal-title" id="staticBackdropLabel">
            Fill In The Form To Extend Your Deadline
          </h1>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="request_info">
          <form action="">
            <div className="col-md-12 mt-3">
              <label htmlFor="validationCustom01" className="form-label">
                Tell Us Why You Need More Time.
              </label>
              <textarea
                rows="10"
                cols="50"
                className="text_box"
                placeholder="Give a descriptive reason"
                value={extendedNote}
                onChange={handleNoteChange}
              />
            </div>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          variant="secondary"
          className="cancel_btn"
          onClick={handleCloseRequestTimeModal}
        >
          Close
        </button>
        <button
          variant="primary"
          className="request_btn apply_btn"
          onClick={handleSaveChanges}
        >
          Save Changes
        </button>
      </Modal.Footer>
    </>
  );
};

export default RequestMoreTimeModal;
