import React, { useEffect, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import { getActivePlan, getPlanHistory, getPlans } from "../../api/plan";
import Container from "../../components/layouts/container";
import LeftSide from "../../components/layouts/left_side";
import PlanHistoryContent from "../../components/planhistory/PlanHistoryContent";
import SubscriptionModule from "../../components/subscription/subscription";
import SubsTabs from "../../components/subscriptiontabs/SubsTabs";
import { storageData } from "../../components/helpers/helper";
import MyPlanContent from "../../components/myplan/MyPlanContent";
import "./planhistory.css";
import GeneralSpinner from "../../components/spiner/Spiner";
import { useTaskContextProvider } from "../../utils/TaskContextProvider";
import ImageFunction from "../../components/department/ImageFunction";
function PlanHistory() {
  const storageInfo = storageData();
  const tokenid = storageInfo.data.token;
  const [historydata, setHistory] = useState([]);
  const [plandata, setPlan] = useState([]);
  const { setLoading, loading } = useTaskContextProvider();

  const showPlanHistory = async () => {
    setLoading(true);
    try {
      const formData = {};
      const Token = tokenid;
      const response = await getPlanHistory(formData, Token);
      const historyData = response.data.data;
      setHistory(historyData);
      setLoading(false);

      return false;
    } catch (error) {}
  };
  const showPlan = async () => {
    try {
      const formData = {};
      const response = await getPlans(formData);
      const data = response.data.data ? response.data.data : "";
      setPlan(data);
      return false;
    } catch (error) {}
  };

  const userType = storageInfo.data.user_type;
  const { setActivePlan, setActivePlanId, setActivePlanLimit } =
    useTaskContextProvider();
  const token = storageInfo.data.token;

  const fetchActivePlan = async () => {
    if (userType === "admin" || userType === "member") {
      try {
        const activeResponse = await getActivePlan("", token);
        setActivePlanId(activeResponse.data.data.basic_info.id);
        setActivePlanLimit(activeResponse.data.data.basic_info.dept_limit);

        localStorage.setItem("activePlanStatus", activeResponse.data.success);
        setActivePlan(true);
      } catch (error) {
        localStorage.setItem("activePlanStatus", false);
        setActivePlan(false);
      }
    }
  };

  useEffect(() => {
    const storedActivePlanStatus = localStorage.getItem("activePlanStatus");
    if (storedActivePlanStatus !== null) {
      setActivePlan(JSON.parse(storedActivePlanStatus));
    }
  }, [setActivePlan]);

  return (
    <div>
      <Container>
        <div className="dashboard_main">
          <div className="main_body">
            <LeftSide />
            <div className="right_side w-100">
              <div className="container-fluid">
                <div className="dashboard_inner col-11 m-auto">
                  <div className="board_header">
                    <div className="main_heading">
                      <h3>Subscription</h3>
                      <p>
                        Check your plan, billing Info, purchase history and
                        upgrade your plan
                      </p>
                    </div>
                  </div>
                  <SubsTabs
                    plandata={plandata}
                    historydata={historydata}
                    planshow={showPlan}
                    historyshow={showPlanHistory}
                  />
                  <div className="tabs_data mt-4">
                    <div className="tab-content" id="nav-tabContent">
                      <div
                        className="tab-pane m-0 fade show active"
                        id="nav-home"
                        role="tabpanel"
                        aria-labelledby="nav-home-tab"
                        tabIndex="0"
                      >
                        <MyPlanContent />
                      </div>
                      <div
                        className="tab-pane m-0 fade"
                        id="nav-profile"
                        role="tabpanel"
                        aria-labelledby="nav-profile-tab"
                        tabIndex="0"
                      >
                        <SubscriptionModule fetchActivePlan={fetchActivePlan} />
                      </div>
                      <div
                        className="tab-pane m-0 fade"
                        id="nav-contact"
                        role="tabpanel"
                        aria-labelledby="nav-contact-tab"
                        tabIndex="0"
                      >
                        <ImageFunction
                          name="Bill Info"
                          line="Billing info is not found"
                        />
                      </div>
                      <div
                        className="tab-pane m-0 fade"
                        id="nav-history"
                        role="tabpanel"
                        aria-labelledby="nav-history-tab"
                        tabIndex="0"
                      >
                        {!loading ? (
                          <PlanHistoryContent datahistory={historydata} />
                        ) : (
                          <GeneralSpinner />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
export default PlanHistory;
