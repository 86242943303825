import React, { useEffect } from "react";
import { useTaskContextProvider } from "../../utils/TaskContextProvider";
import { storageData } from "../helpers/helper";
import {
  onDragEnd,
  onDragEnter,
  onDragLeave,
  onDragOver,
  onDrop,
} from "../helpers/DragHelpers";

const TaskCard = ({
  renderTaskCards,
  status,
  divs,
  tasksArray,
  statusId,
  statusName,
  projectStatus,
}) => {
  const {
    departIdasInt,
    setUpdatedTaskArray,
    updatedTaskArray,
    setTaskListBoard,
  } = useTaskContextProvider();
  const storageInfo = storageData();

  useEffect(() => {
    setUpdatedTaskArray(tasksArray);
  }, []);

  const pathname = window.location.pathname;
  const parts = pathname.split("/");

  return (
    <div
      className="done small-box"
      onDragLeave={(e) => onDragLeave(e)}
      onDragEnter={(e) => onDragEnter(e)}
      onDragEnd={(e) => onDragEnd(e)}
      onDragOver={(e) => onDragOver(e)}
      onDrop={(e) =>
        onDrop(
          e,
          true,
          status,
          updatedTaskArray,
          setUpdatedTaskArray,
          departIdasInt,
          storageInfo,
          statusId,
          setTaskListBoard,
          projectStatus,
          parts[3]
        )
      }
    >
      {divs && statusName === status && <>{divs}</>}
      <section className="drag_container">
        <div className="container">
          <div className="drag_column">
            <div className="drag_row">
              {renderTaskCards(
                updatedTaskArray.filter((data) => data.status_name === status)
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TaskCard;
