// App.js
import React from "react";
import AppRoutes from "./config/Routes";
import { ToastContainer } from "react-toastify";
import { TaskContextProvider } from "./utils/TaskContextProvider";

const App = () => {
  return (
    <TaskContextProvider>
      <ToastContainer />
      <AppRoutes />
      {/* <Login /> */}
    </TaskContextProvider>
  );
};

export default App;
