export const storageData = () => {
  const user_info = JSON.parse(localStorage.getItem("c_info"));
  return user_info;
};

export const formatDate = (timestamp) => {
  const dateObject = new Date(timestamp);
  const options = {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  };

  return dateObject.toLocaleDateString("en-US", options);
};

export const capitalizeFirstLetter = (str) => {
  return str
    ?.split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const findModuleValue = (moduleName, value, array) => {
  for (let item of array) {
    if (item.module_name === moduleName && item.value === value) {
      return true;
    }
  }
  return false;
};
