// MyContext.js
import { createContext, useContext, useState } from "react";

const TaskContext = createContext();

export function useTaskContextProvider() {
  const state = useContext(TaskContext);
  if (!state) {
    throw new Error(
      "useTaskContextProvider must be used within TaskContextProvider"
    );
  }

  return state;
}

const TaskContextProvider = ({ children }) => {
  const localdata = JSON.parse(localStorage.getItem("u_did"));
  const departIdasInt = JSON.parse(localStorage.getItem("u_did"));
  const [userDept, allDeptUser] = useState([]);
  const [userDepId, setUserDepId] = useState(localdata ? localdata : "");
  const [projectlist, setProjectlist] = useState([]);
  const [taskList, setTasklist] = useState([]);
  const [pageNumber, setPageNumber] = useState(2);
  const [projectID, setProjectID] = useState(localdata ? localdata : "");
  const [activeTab, setActiveTab] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [leftSideMenu, setLeftSideMenu] = useState([]);
  const [listdataset, setListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading_1, setLoading_1] = useState(true);

  const [setCardID, setID] = useState("");
  const [proName, setName] = useState("");
  const [projectlistForTasks, setProjectlistForTasks] = useState([]);
  const [projectlistTask, setProjectlistAndTasks] = useState([]);
  const [cpompdepart, compDepartData] = useState([]);
  const [memberlistForTasks, setMemberlistForTasks] = useState([]);
  const [userInfo, setUserInfo] = useState("");
  const [userImage, setUserImage] = useState("");
  const [owner, setOwner] = useState("");
  const [discription, setDiscrption] = useState("");
  const [image, setimage] = useState("");
  const [oldimage, setOldImage] = useState("");
  const [taskprojectID, setTaskProjectID] = useState("");
  const [taskStatusID, setStatusID] = useState("");
  const [statusid, setStatusid] = useState("");
  const [statuslist, setStatusList] = useState([]);
  const [show, setShow] = useState(false);
  const [loadMore, setLoadMore] = useState(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [insightdata, setInsightProject] = useState("");
  const [taskId, setTaskId] = useState(null);
  const [SelectedDates, setSelectedDates] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [taskListBoard, setTaskListBoard] = useState([]);
  const [updatedTaskArray, setUpdatedTaskArray] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectMembers, setSelectMemberList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [checkLists, setCheckLists] = useState([]);
  const [comments, setComments] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [replies, setReplies] = useState([]);
  const [projectid, setprojectid] = useState("");
  const [projectName, setProjectName] = useState("");
  const [planuser, setActivePlanUser] = useState("");
  const [memtasklist, setMemTaskList] = useState([]);
  const [activelogs, setActiveLog] = useState([]);

  const [permissions, setPermissions] = useState(null);
  const [rolePermissions, setRolePermissions] = useState(null);
  const [roleID, setRoleID] = useState(null);
  const [inviteID, setInviteId] = useState(null);
  const [selectedPriority, setSelectedPriority] = useState("");
  const [allcompdepartments, setcompDepartments] = useState([]);

  const [activePlanId, setActivePlanId] = useState(null);
  const [activePlanLimit, setActivePlanLimit] = useState(null);

  const [assignedPermissions, setAssignedPermissions] = useState({
    //members
    members: true,
    edit: true,
    updatePw: true,
    remove: true,
    create: true,

    //projects
    projectList: true,
    createProject: true,
    projectInsights: true,
    removeProject: true,

    //tasks
    taskList: true,
    taskBoard: true,
    createTask: true,
    removeTask: true,

    //taskDetails
    taskDetails: true,
    taskStatusList: true,
    createStatus: true,
    deleteStatus: true,

    //comments
    commentList: true,
    saveComments: true,
    replyComments: true,
    deleteComments: true,

    //attachments
    attachmentsList: true,
    saveAttachments: true,
    deleteAttachments: true,

    //checklists
    checkLists: true,
    createCheckList: true,
    deleteCheckList: true,
  });

  const [show1, setShow1] = useState(false);
  const handleShow1 = () => setShow1(true);
  const handleClose1 = () => setShow1(false);

  const [taskDetails, setTaskDetails] = useState("");
  const [text, setText] = useState("");
  const [type, setType] = useState("");
  const [newpassword, setPassword] = useState("");
  const [confirmPass, setConfPassword] = useState("");
  const [departname, setDepartName] = useState("");
  const [planname, setplanName] = useState("");
  const [userlimit, setuserLimit] = useState("");
  const [departlimit, setdepartLimit] = useState("");
  const [planprice, setplanPrice] = useState("");
  const [planduration, setplanDuration] = useState("");
  const [plandescrition, setplanDescrition] = useState("");
  const [activePlan, setActivePlan] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const [isBtnLoading, setBtnLoading] = useState(false);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setimage(file);
  };
  const handleClick = (event) => {
    const cardID = event.currentTarget.id;
    setID(cardID);
  };
  const handleCode = (event) => {
    setName(event.target.value);
  };
  const handleCode1 = (event) => {
    const selectID = event.target.value;
    setProjectID(selectID);
  };
  const handleCode3 = (event) => {
    setOwner(event.target.value);
  };
  const handleCode5 = (event) => {
    setDiscrption(event.target.value);
  };

  const [selectedDepartmentId, setSelectedDepartmentId] = useState(null);

  const resetForm = () => {
    setName("");
    setimage("");
    setDiscrption("");
    setOwner("");
    setID("");
    setProjectID([]);
    handleShow();
    setOldImage("");
  };

  const providerValue = {
    userDept,
    userDepId,
    projectlist,
    taskList,
    projectID,
    activeTab,
    searchQuery,
    leftSideMenu,
    listdataset,
    loading,
    loading_1,
    startDate,
    SelectedDates,
    endDate,
    pageNumber,
    setCardID,
    proName,
    owner,
    discription,
    image,
    projectlistTask,
    oldimage,
    show,
    loadMore,
    show2,
    projectlistForTasks,
    memberlistForTasks,
    userInfo,
    userImage,
    insightdata,
    taskId,
    show1,
    taskDetails,
    type,
    taskListBoard,
    text,
    taskprojectID,
    taskStatusID,
    updatedTaskArray,
    selectedIds,
    selectMembers,
    checkLists,
    comments,
    statusid,
    statuslist,
    searchTerm,
    attachments,
    replies,
    projectid,
    projectName,
    memtasklist,
    activelogs,
    permissions,
    roleID,
    inviteID,
    departname,
    planname,
    userlimit,
    departlimit,
    plandescrition,
    planduration,
    planprice,
    activePlan,
    rolePermissions,
    assignedPermissions,
    selectedPriority,
    newpassword,
    confirmPass,
    showModal,
    isEditing,
    isBtnLoading,
    cpompdepart,
    activePlanId,
    selectedDepartmentId,
    activePlanLimit,
    planuser,
    allcompdepartments,

    setcompDepartments,
    setActivePlanUser,
    setActivePlanLimit,
    compDepartData,
    setBtnLoading,
    setEditing,
    setShowModal,
    setConfPassword,
    setPassword,
    setSelectedPriority,
    setAssignedPermissions,
    setActivePlan,
    setplanPrice,
    setplanDuration,
    setplanDescrition,
    setdepartLimit,
    setuserLimit,
    setplanName,
    setDepartName,
    setActiveLog,
    setMemTaskList,
    setprojectid,
    setSearchTerm,
    setStatusList,
    allDeptUser,
    setUserDepId,
    setProjectlist,
    setTasklist,
    setProjectID,
    setActiveTab,
    setSearchQuery,
    setLeftSideMenu,
    setListData,
    setLoading,
    setLoading_1,
    setStartDate,
    setEndDate,
    setSelectedDates,
    setPageNumber,
    setID,
    setName,
    setOwner,
    setDiscrption,
    setimage,
    setOldImage,
    setShow,
    setLoadMore,
    setShow2,
    setProjectlistForTasks,
    setProjectlistAndTasks,
    setMemberlistForTasks,
    setUserInfo,
    setUserImage,
    setInsightProject,
    setTaskId,
    setShow1,
    setTaskDetails,
    setType,
    setTaskListBoard,
    setText,
    setTaskProjectID,
    setStatusID,
    setUpdatedTaskArray,
    setSelectMemberList,
    setCheckLists,
    setComments,
    setStatusid,
    setAttachments,
    setReplies,
    setProjectName,
    setPermissions,
    setRoleID,
    setInviteId,
    setRolePermissions,
    setActivePlanId,
    setSelectedDepartmentId,

    handleClose,
    handleShow,
    handleClose2,
    handleShow2,
    handleImageChange,
    handleClick,
    handleCode,
    handleCode1,
    handleCode3,
    handleCode5,
    resetForm,
    handleShow1,
    handleClose1,
    setSelectedIds,

    departIdasInt,
  };
  return (
    <TaskContext.Provider value={providerValue}>
      {children}
    </TaskContext.Provider>
  );
};

export { TaskContext, TaskContextProvider };
