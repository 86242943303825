import React from "react";
import { ReactComponent as Dashboard } from "../../assets/images/dashboard.svg";
import { ReactComponent as Task } from "../../assets/images/task.svg";
import { ReactComponent as Project } from "../../assets/images/Project Icon - 3.svg";
// import { ReactComponent as Team } from "../../assets/images/team.svg";
import { ReactComponent as Sheet } from "../../assets/images/sheet.svg";
import { ReactComponent as Keyword } from "../../assets/images/keywords.svg";
import { ReactComponent as Member } from "../../assets/images/Member icon.svg";
import { ReactComponent as PlanHistory } from "../../assets/images/Plan.svg";
import { ReactComponent as Permission } from "../../assets/images/Permission.svg";

const adminMenu = [
  {
    name: "Dashboard",
    url: "/dashboard",
    svg: <Dashboard />,
  },

  // {
  //   name: "Projects",
  //   url: "/projects",
  //   svg: <Project />,
  // },
  // {
  //   name: "Tasks",
  //   url: "/task",
  //   svg: <Task />,
  // },

  {
    name: "Members",
    url: "/members",
    svg: <Member />,
  },
  {
    name: "Plan History",
    url: "/plan-history",
    svg: <PlanHistory />,
  },
  {
    name: "Permissions",
    url: "/permissions",
    svg: <Permission />,
  },

  // {
  //   name: "Support",
  //   url: "/chat",
  //   svg: <Support />,
  // },
  // {
  //   name: "Chat",
  //   url: "/chat",
  //   svg: <Chat />,
  // },
];
const filteredAdminMenu = [
  {
    name: "Dashboard",
    url: "/dashboard",
    svg: <Dashboard />,
  },
];
const desginerMenu = [
  {
    name: "Dashboard",
    url: "/dashboard",
    svg: <Dashboard />,
  },
  {
    name: "Projects",
    url: "/projects",
    svg: <Project />,
  },
  {
    name: "My Task",
    url: "/task",
    svg: <Task />,
  },
  {
    name: "Members",
    url: "/members",
    svg: <Member />,
  },
  // {
  //   name: "Team",
  //   url: "/team",
  //   svg: <Team />,
  // },
  // {
  //   name: "Chat",
  //   url: "/chat",
  //   svg: <Chat />,
  // },
];

const seoMenu = [
  {
    name: "Dashboard",
    url: "/dashboard",
    svg: <Dashboard />,
  },
  {
    name: "Projects",
    url: "/projects",
    svg: <Project />,
  },
  {
    name: "My Task",
    url: "/task",
    svg: <Task />,
  },
  {
    name: "Members",
    url: "/members",
    svg: <Member />,
  },
  // {
  //   name: "My Sheet",
  //   url: "/my-sheets",
  //   svg: <Sheet />,
  // },
  // {
  //   name: "My Keywords",
  //   url: "/my-keywords",
  //   svg: <Keyword />,
  // },
  // {
  //   name: "Team",
  //   url: "/team",
  //   svg: <Team />,
  // },
  // {
  //   name: "Chat",
  //   url: "/chat",
  //   svg: <Chat />,
  // },
];

const writerMenu = [
  {
    name: "Dashboard",
    url: "/dashboard",
    svg: <Dashboard />,
  },
  {
    name: "Projects",
    url: "/projects",
    svg: <Project />,
  },
  {
    name: "My Task",
    url: "/task",
    svg: <Task />,
  },
  {
    name: "Members",
    url: "/members",
    svg: <Member />,
  },
  // {
  //   name: "Team",
  //   url: "/team",
  //   svg: <Team />,
  // },
  // {
  //   name: "Chat",
  //   url: "/chat",
  //   svg: <Chat />,
  // },
];

const devMenu = [
  {
    name: "Dashboard",
    url: "/dashboard",
    svg: <Dashboard />,
  },
  {
    name: "Projects",
    url: "/projects",
    svg: <Project />,
  },
  {
    name: "My Task",
    url: "/task",
    svg: <Task />,
  },

  {
    name: "Members",
    url: "/members",
    svg: <Member />,
  },
  // {
  //   name: "Permissions",
  //   url: "/permissions",
  //   svg: <Permission />,
  // },
  // {
  //   name: "Team",
  //   url: "/team",
  //   svg: <Team />,
  // },
  // {
  //   name: "Chat",
  //   url: "/chat",
  //   svg: <Chat />,
  // },
];
const superadminMenu = [
  {
    name: "Dashboard",
    url: "/superadmin",
    svg: <Dashboard />,
  },
  {
    name: "Companies",
    url: "/",
    svg: <Member />,
  },
  {
    name: "Plans",
    url: "/plans",
    svg: <PlanHistory />,
  },
  // {
  //   name: "Chat",
  //   url: "/chat",
  //   svg: <Chat />,
  // },
];

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  devMenu,
  desginerMenu,
  writerMenu,
  seoMenu,
  adminMenu,
  superadminMenu,
  filteredAdminMenu,
};
