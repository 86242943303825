import React from "react";

function SubsTabs(props) {
  return (
    <div>
      <div className="task_head mt-3">
        <div className="tsk_tabs">
          <nav>
            <div className="nav nav-tabs" id="nav-tab" role="tablist">
              <button
                className="nav-link task_links active"
                id="nav-home-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-home"
                type="button"
                role="tab"
                aria-controls="nav-home"
                aria-selected="true"
              >
                My Plan
              </button>
              <button
                className="nav-link task_links"
                id="nav-profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-profile"
                type="button"
                role="tab"
                aria-controls="nav-profile"
                aria-selected="false"
                onClick={() => props.plandata.length === 0 && props.planshow()}
              >
                Upgrade
              </button>
              <button
                className="nav-link task_links"
                id="nav-contact-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-contact"
                type="button"
                role="tab"
                aria-controls="nav-contact"
                aria-selected="false"
              >
                Billing Info
              </button>
              <button
                className="nav-link task_links"
                id="nav-history-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-history"
                type="button"
                role="tab"
                aria-controls="nav-history"
                aria-selected="false"
                onClick={() =>
                  props.historydata.length === 0 && props.historyshow()
                }
              >
                Purchase History
              </button>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default SubsTabs;
