import React, { useEffect, useRef, useState } from "react";
import { DateRangePicker } from "rsuite";
import { Spinner } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTaskContextProvider } from "../../utils/TaskContextProvider";
import {
  DeleteTasksStatus,
  GetProjectStatusList,
  createTaskStatus,
} from "../../api/task";
import { storageData } from "../helpers/helper";
import DeleteTaskModal from "./taskmodals/DeleteModal";
import { toast } from "react-toastify";
import SelectedMemberModal from "./SelectMemberModal";
import StatusModal from "./taskmodals/StatusModal";
import PriorityRow from "./taskmodals/PriorityRow";
import DotsDropdown from "./taskmodals/DotsDropdown";
import moment from "moment";
import { Link } from "react-router-dom";
import RequestMoreTimeModal from "./taskmodals/RequestMoreTimeModal";
import {
  handleDatesHelper,
  handleNameEditHelper,
} from "../helpers/TaskHelpers";
import api from "../helpers/AccessHelper";
const TaskDataRow = ({
  task,
  selectedDates,
  setSelectedPriority,
  editedTasks,
  ProjectsandTasks,
  setEditedTasks,
}) => {
  const storageInfo = storageData();
  const navigate = useNavigate();
  const [isEditTaskName, setIsEditTaskName] = useState(false);
  const [taskName, setTaskName] = useState(task.task_name);
  const [smShow, setSmShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showStatusDelete, setShowStatusDelete] = useState(false);
  const [showStatusAdd, setShowStatusAdd] = useState(false);
  const [taskId, setTaskId] = useState(null);
  const [isMember, setIsMember] = useState(null);
  const [taskID, setID] = useState("");
  const [selectValues, setSelectValues] = useState("");
  const [newStatusName, setNewStatusName] = useState("");
  const [addStatus, setAddStatus] = useState(false);
  const [selectedColor, setSelectedColor] = useState("#000000");
  const [editedStatus, setEditedStatus] = useState({});
  const [showRequestTimeModal, setShowRequestTimeModal] = useState(false);
  const [formData, setFormData] = useState("");
  const [TID, setTId] = useState("");
  const [SID, setSId] = useState("");
  const [PID, setPId] = useState("");
  const [updatename, setUpdatename] = useState("");
  const [filteredOptions, setFilteredItems] = useState([]);

  const {
    setSelectedDates,
    taskprojectID,
    departIdasInt,
    taskStatusID,
    memberlistForTasks,
    setStatusid,
    statusid,
    setSearchTerm,
    assignedPermissions,
    setBtnLoading,
    isBtnLoading,
  } = useTaskContextProvider();

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = (taskId) => {
    if (assignedPermissions.createTask) {
      setShowModal(true);
      setTaskId(taskId);
      setSelectValues(selectVal);
    } else {
      toast.error(
        "You don't have permission for this action. Please contact your admin!"
      );
    }
  };

  const handleCloseDeleteModal = () => setShowDeleteModal(false);
  const handleShowDeleteModal = () => setShowDeleteModal(true);

  const handleCloseStatusDelete = () => setShowStatusDelete(false);
  const handleShowStatusDelete = () => setShowStatusDelete(true);

  const handleCloseStatusAdd = () => setShowStatusAdd(false);
  const handleShowStatusAdd = () => setShowStatusAdd(true);

  const handleCloseRequestTimeModal = () => setShowRequestTimeModal(false);

  const handleDates = async (dates, taskId, projectId) => {
    try {
      setSelectedDates((prevDates) => ({
        ...prevDates,
        [taskId]: dates,
      }));

      await handleDatesHelper(
        dates,
        taskId,
        projectId,
        departIdasInt,
        isMember,
        setTaskId,
        handleShowRequestTimeModal
      );
      ProjectsandTasks();
    } catch (error) {
      console.error("An error occurred in handleDates:", error);
    }
  };

  const handlePrioritySelect = (priority, taskId, pid, taskname) => {
    setSelectedPriority((prevPriorities) => ({
      ...prevPriorities,
      [taskId]: priority,
    }));

    onSubmit(null, null, taskId, pid, taskname, priority);
  };

  const removeTask = async () => {
    setBtnLoading(true);
    try {
      const formData = {
        id: taskID,
      };
      const response = await api.post("task/remove-task", formData, {
        headers: {
          Authorization: `Bearer ${storageInfo?.data?.token}`,
        },
      });
      toast.success(response.data.message);
      ProjectsandTasks();
      setBtnLoading(false);
      handleCloseDeleteModal();
      return false;
    } catch (error) {
      setBtnLoading(false);
    }
  };
  const [loadingTasks, setLoadingTasks] = useState({});

  const handleInputBlur = async (e) => {
    if (e.key === "Enter") {
      setLoadingTasks((prevLoadingTasks) => ({
        ...prevLoadingTasks,
        [task.id]: true,
      }));

      try {
        await handleNameEditHelper(
          task.id,
          task.pid,
          taskName,
          departIdasInt,
          "",
          setIsEditTaskName
        );
        setLoadingTasks((prevLoadingTasks) => ({
          ...prevLoadingTasks,
          [task.id]: false,
        }));
      } catch (error) {
        setLoadingTasks((prevLoadingTasks) => ({
          ...prevLoadingTasks,
          [task.id]: false,
        }));
        console.error("An error occurred in handleEnterKey:", error);
      }
    }
  };

  const handBlur = async () => {
    setLoadingTasks((prevLoadingTasks) => ({
      ...prevLoadingTasks,
      [task.id]: true,
    }));
    try {
      await handleNameEditHelper(
        task.id,
        task.pid,
        taskName,
        departIdasInt,
        ProjectsandTasks,
        setIsEditTaskName
      );
      setLoadingTasks((prevLoadingTasks) => ({
        ...prevLoadingTasks,
        [task.id]: false,
      }));
    } catch (error) {
      setLoadingTasks((prevLoadingTasks) => ({
        ...prevLoadingTasks,
        [task.id]: false,
      }));

      console.error("An error occurred in handleEnterKey:", error);
    }
  };

  const navigateToTaskDetail = (taskId) => {
    if (assignedPermissions.taskDetails) {
      navigate(`/task/${taskId}`, { state: { taskId, projectId: task.pid } });
    } else {
      toast.error(
        "You don't have permission for this action. Please contact your admin!"
      );
    }
  };

  const onSubmit = async (
    sdate,
    edate,
    taskId,
    pid,
    taskname,
    priority,
    array,
    status
  ) => {
    setEditedTasks({});
    setBtnLoading(true);
    setLoadingTasks((prevLoadingTasks) => ({
      ...prevLoadingTasks,
      [task.id]: true,
    }));
    try {
      const formData = new FormData();
      formData.append("department_id", JSON.parse(departIdasInt));
      formData.append("project_id", taskprojectID ? taskprojectID : pid);
      formData.append("id", taskId === undefined ? taskStatusID : taskId);
      if (editedTasks[task.id]) {
        formData.append("name", editedTasks[task.id]);
        formData.append("act", "updated");
        setEditedTasks({});
      } else if (status) {
        formData.append("task_status_id", status);
        formData.append("act", "updated");
      } else if (sdate && edate) {
        formData.append("from_date_report", sdate ? sdate : "");
        formData.append("to_date_report", edate ? edate : "");
        formData.append("act", "updated");
      } else if (priority) {
        formData.append("task_type", priority);
        formData.append("act", "updated");
      } else if (selectValues || array) {
        formData.append("member", JSON.stringify(array ? array : selectValues));
        if (array) {
          formData.append("act", "deleted");
        } else {
          formData.append("act", "updated");
        }
        setSelectValues("");
      }
      await api.post("task/create-task", formData, {
        headers: {
          Authorization: `Bearer ${storageInfo?.data?.token}`,
        },
      });
      ProjectsandTasks();
      handleCloseStatusAdd();
      setSmShow(false);
      setBtnLoading(false);
      setLoadingTasks((prevLoadingTasks) => ({
        ...prevLoadingTasks,
        [task.id]: false,
      }));
    } catch (error) {
      setBtnLoading(false);
      setLoadingTasks((prevLoadingTasks) => ({
        ...prevLoadingTasks,
        [task.id]: false,
      }));

      if (error?.response?.data?.data === "permission") {
        navigate("/404");
      }
      if (error?.response?.data?.data === "plan") {
        navigate("/dashboard");
      }
    }
  };

  const handelTaskID = (ID) => {
    setID(ID);
  };

  const navigateToMember = () => {
    navigate("/members");
  };
  let data = JSON.parse(task.assigned_users_json);
  const handleRemoveMember = (value, id, pid, taskId, taskname) => {
    data = data.filter((user) => user.user_id !== id);
    const userIdArray = data.map((user) => user.user_id);
    onSubmit(null, null, taskId, pid, taskname, null, userIdArray);
  };
  let selectVal = [];
  selectVal = data?.map((data) => data.user_id);
  const handleCodeMember = (memberid) => {
    setSelectValues([memberid]);
  };
  const memArr = [];
  memberlistForTasks.forEach((mem) => {
    memArr.push({
      value: `${mem.id}`,
      label: `${mem.full_name}`,
      email: `${mem.email}`,
      selected: false,
    });
  });
  const memoptions = memArr;

  const SaveMember = (taskname, proID, taskid) => {
    onSubmit(null, null, taskid, proID, taskname, null);
    handleCloseModal();
  };
  const handleNewStatusNameChange = (e) => {
    setNewStatusName(e.target.value);
  };
  const handleEnterKey = (e) => {
    if (e.key === "Enter") {
      if (newStatusName.trim() !== "") {
        const newStatus = {
          status: newStatusName.toLowerCase(),
          title: newStatusName,
          addTask: false,
          borderColor: selectedColor,
        };

        setNewStatusName(newStatus);
      }
      setAddStatus(false);
    }
  };
  const handleAddStatus = () => {
    if (assignedPermissions.createStatus) {
      setAddStatus(true);
    } else {
      toast.error(
        "You don't have permission for this action. Please contact your admin!"
      );
    }
  };

  const CreateStatusList = async (proid, name, statusid) => {
    setBtnLoading(true);
    try {
      const formData = new FormData();
      formData.append("status_name", newStatusName ? newStatusName : name);
      formData.append("project_id", proid);
      formData.append("color_code", selectedColor);
      if (statusid) {
        formData.append("id", statusid);
        formData.append("act", "updated status name");
      } else {
        formData.append("act", "add new status");
      }
      await createTaskStatus(formData, storageInfo.data.token);
      setNewStatusName("");
      setAddStatus(false);
      StatusList(proid);
      setNewStatusName("");
      setBtnLoading(false);
    } catch (error) {
      setBtnLoading(false);
    }
  };
  const statusUpdate = () => {
    onSubmit(null, null, TID, PID, updatename, null, null, SID);
  };
  const statusModalShow = (statusid) => {
    if (assignedPermissions.createStatus) {
      setSId(statusid);
      handleShowStatusAdd();
    } else {
      toast.error(
        "You don't have permission for this action. Please contact your admin!"
      );
    }
  };
  const ShowlistModal = (proid, tid, updatename) => {
    if (assignedPermissions.taskStatusList) {
      setTId(tid);
      setPId(proid);
      setSmShow(true);
      setUpdatename(updatename);
      StatusList(proid);
    } else {
      toast.error(
        "You don't have permission for this action. Please contact your admin!"
      );
    }
  };
  const handleSearch = (value, pid) => {
    setSearchTerm(value);
    const filteredList = filteredOptions.filter((option) =>
      option.status_name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredItems(filteredList);
    if (value === "") {
      StatusList(pid);
    }
  };
  const StatusList = async (proid) => {
    setSearchTerm("");
    try {
      const formData = new FormData();
      formData.append("department_id", JSON.parse(departIdasInt));

      const response = await GetProjectStatusList(
        formData,
        storageInfo.data.token
      );
      const setdata = response.data.data;
      const filteredItems = setdata.filter(
        (item) => item.project_id === null || item.project_id === proid
      );
      setFilteredItems(filteredItems);
      ProjectsandTasks();
    } catch (error) {}
  };
  const StatusDelete = async () => {
    setBtnLoading(true);
    try {
      const formData = new FormData();
      formData.append("id", statusid);
      await DeleteTasksStatus(formData, storageInfo.data.token);
      setBtnLoading(false);
      handleCloseStatusDelete();
      setSmShow(false);
      ProjectsandTasks();
    } catch (error) {
      setBtnLoading(false);
    }
  };
  const deleteStatus = (sid) => {
    setStatusid(sid);
    handleShowStatusDelete();
  };
  const editStatus = (value, taskId) => {
    setEditedStatus((prev) => ({
      ...prev,
      [taskId]: value,
    }));
    setStatusid(taskId);
  };
  const handleStatusChange = (value, taskId) => {
    setEditedStatus((prev) => ({ ...prev, [taskId]: value }));
  };
  const updateStatus = (pid, name, sid) => {
    CreateStatusList(pid, name, sid);
    setEditedStatus({});
  };

  const handleShowRequestTimeModal = (formData) => {
    setShowRequestTimeModal(true);
    setFormData(formData);
  };

  const myInputRef = useRef(null);

  useEffect(() => {
    if (isEditTaskName && myInputRef.current) {
      myInputRef.current.focus();
    }
  }, [isEditTaskName]);

  useEffect(() => {
    if (task.task_name) {
      setTaskName(task.task_name);
    }
  }, [task.task_name]);

  return (
    <div>
      {!loadingTasks[task.id] ? (
        <div className="row tasktable_data" key={task.id}>
          <div className="col-3 d-flex align-items-center position-relative">
            <div
              className="status_div"
              style={{ background: task.color_code }}
              onClick={() => ShowlistModal(task.pid, task.id, task.task_name)}
            ></div>
            {isEditTaskName ? (
              <div className="edit_hide">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Task Name"
                  ref={myInputRef}
                  value={taskName}
                  onChange={(e) => setTaskName(e.target.value)}
                  onKeyDown={handleInputBlur}
                  onBlur={handBlur}
                />
              </div>
            ) : (
              <>
                <h6
                  className="edit_show"
                  onClick={() => navigateToTaskDetail(task.id)}
                >
                  {taskName}
                </h6>
                {assignedPermissions.createTask && (
                  <div className="edit_options">
                    <div className="pencil_icon">
                      <i
                        className="fa-solid fa-pencil"
                        onClick={() => setIsEditTaskName(true)}
                        id={task.id}
                      ></i>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="col-3 d-flex align-items-baseline">
            <div className="row">
              <div className="col-10 padd_class">
                <div className="select_col ">
                  {data === null ? (
                    <div
                      className="user-icon"
                      onClick={() => handleShowModal(task.id)}
                    >
                      <i className="fa fa-user-plus" aria-hidden="true"></i>
                    </div>
                  ) : (
                    <div className="user-info">
                      <div
                        className="user-icon"
                        onClick={() => handleShowModal(task.id)}
                      >
                        <i className="fa fa-user-plus" aria-hidden="true"></i>
                      </div>
                      <div className="select_col">
                        {data[0].user_id !== "" &&
                          data?.map((post) => {
                            return (
                              <>
                                <div className="position-relative margen_set">
                                  <Link
                                    to={`/member-tasks/${btoa(post.user_id)}`}
                                  >
                                    <div className="initial-avatar">
                                      {post.user_name === ""
                                        ? "N/A"
                                        : post.user_name
                                            .charAt(0)
                                            .toUpperCase()}
                                    </div>
                                  </Link>
                                  <div
                                    className="cross_icon"
                                    onClick={() =>
                                      handleRemoveMember(
                                        post.user_name,
                                        post.user_id,
                                        task.pid,
                                        task.id,
                                        task.task_name
                                      )
                                    }
                                  >
                                    <i className="fa-regular fa-circle-xmark"></i>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="cross_hiden task_date col-3 d-flex justify-content-between align-items-baseline">
            <DateRangePicker
              onClick={() => setIsMember(task.assigned_users_json)}
              id={`dateRangePicker_${task.id}`}
              onChange={(dates) => handleDates(dates, task.id, task.pid)}
              value={
                selectedDates || (task.from_date_report && task.to_date_report)
                  ? [
                      moment(task.from_date_report).toDate(),
                      moment(task.to_date_report).toDate(),
                    ]
                  : null
              }
              disabled={!assignedPermissions.createTask}
            />
          </div>
          <div className="col-2 d-flex align-items-baseline">
            <PriorityRow
              task={task}
              handlePrioritySelect={handlePrioritySelect}
            />
          </div>
          {assignedPermissions.removeTask && (
            <div className="1 col-1">
              <DotsDropdown
                handleShowDeleteModal={handleShowDeleteModal}
                task={task}
                handelTaskID={handelTaskID}
                name="Task"
              />
            </div>
          )}
        </div>
      ) : (
        <div className="d-flex justify-content-center spin_pedding">
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        </div>
      )}

      <Modal
        show={showDeleteModal}
        onHide={handleCloseDeleteModal}
        backdrop="static"
        keyboard={false}
      >
        <DeleteTaskModal
          handleCloseDeleteModal={handleCloseDeleteModal}
          removeTask={removeTask}
          isBtnLoading={isBtnLoading}
          title="Task"
        />
      </Modal>
      <Modal
        show={showStatusDelete}
        onHide={handleCloseStatusDelete}
        backdrop="static"
        keyboard={false}
      >
        <DeleteTaskModal
          handleCloseDeleteModal={handleCloseStatusDelete}
          removeTask={StatusDelete}
          isBtnLoading={isBtnLoading}
          title="Status"
        />
      </Modal>
      <Modal
        show={showStatusAdd}
        onHide={handleCloseStatusAdd}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <div className="assign_task_form d-flex justify-content-center align-baseline realy_want">
            <i className="fa-solid fa-triangle-exclamation"></i>
            <h6>Do you realy want to change task status?</h6>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <button className="cancel_btn" onClick={handleCloseStatusAdd}>
            NO
          </button>
          <button
            type="button"
            className="request_btn apply_btn"
            onClick={statusUpdate}
            disabled={isBtnLoading}
          >
            {!isBtnLoading ? (
              "YES"
            ) : (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        backdrop="static"
        size="sm"
        backdropClassName="backdropclass"
      >
        <SelectedMemberModal
          memoptions={memoptions}
          handleCloseModal={handleCloseModal}
          taskId={taskId}
          handleCodeMember={(value, label) =>
            handleCodeMember(value, label, taskId)
          }
          selectValues={selectValues}
        />
        <div className="btn_divider d-flex justify-content-end">
          {memoptions.length !== 0 ? (
            <button
              className="request_btn apply_btn btn"
              onClick={() => SaveMember(task.task_name, task.pid, taskId)}
            >
              Save
            </button>
          ) : (
            <button
              style={{
                backgroundColor: "#38ce94",
                border: "1px solid #38ce94",
              }}
              className="request_btn apply_btn btn"
              onClick={navigateToMember}
            >
              Add Member
            </button>
          )}
        </div>
      </Modal>
      <Modal
        className="status_main_modal"
        backdrop="static"
        size="sm"
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <StatusModal
          handleSearch={handleSearch}
          task={task}
          setSmShow={setSmShow}
          addStatus={addStatus}
          newStatusName={newStatusName}
          handleNewStatusNameChange={handleNewStatusNameChange}
          handleEnterKey={handleEnterKey}
          selectedColor={selectedColor}
          CreateStatusList={CreateStatusList}
          setSelectedColor={setSelectedColor}
          handleAddStatus={handleAddStatus}
          filteredOptions={filteredOptions}
          updateStatus={updateStatus}
          handleStatusChange={handleStatusChange}
          editedStatus={editedStatus}
          statusModalShow={statusModalShow}
          deleteStatus={deleteStatus}
          editStatus={editStatus}
        />
      </Modal>

      <Modal
        show={showRequestTimeModal}
        onHide={handleCloseRequestTimeModal}
        size="md"
        backdrop="static"
        keyboard={false}
      >
        <RequestMoreTimeModal
          formDataRT={formData}
          taskId={taskId}
          handleDates={handleDates}
          handleCloseRequestTimeModal={handleCloseRequestTimeModal}
          ProjectsandTasks={ProjectsandTasks}
        />
      </Modal>
    </div>
  );
};

export default TaskDataRow;
