import React, { useRef, useState } from "react";
import profile from "../../assets/images/profile.png";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { companyProfileValidationSchema } from "./ProfileValidation";
import { storageData } from "../helpers/helper";
import { companyInfoData, API } from "../../api/profile";
import { useTaskContextProvider } from "../../utils/TaskContextProvider";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
function CompanyInfo() {
  const storageInfo = storageData();
  const companyname = storageInfo.data.company_info.name;
  const address = storageInfo.data.company_info.address;
  const city = storageInfo.data.company_info.city;
  const phone = storageInfo.data.company_info.phone_no;
  const state = storageInfo.data.company_info.state;

  const companyemail = storageInfo.data.email;
  const {
    userInfo,
    setUserInfo,
    setUserImage,
    userImage,
    isBtnLoading,
    setBtnLoading,
  } = useTaskContextProvider();
  const userPic = storageInfo.data.company_info.company_logo;
  const baseURL = process.env.REACT_APP_URL;
  const [compname, setName] = useState(companyname);
  const [compaddress, setAddress] = useState(address);
  const [compcity, setCity] = useState(city);
  const [compphone, setPhone] = useState(phone);
  const [compstate, setState] = useState(state);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(companyProfileValidationSchema),
  });
  const inputref = useRef(null);
  const [image, setimage] = useState("");
  const handleImageClick = () => {
    inputref.current.click();
  };
  const handleImageChange = (event) => {
    setimage(event.target.files[0]);
  };

  const HandleName = (event) => {
    setName(event.target.value);
  };
  const HandleAddress = (event) => {
    setAddress(event.target.value);
  };
  const HandleCity = (event) => {
    setCity(event.target.value);
  };
  const HandlePhone = (event) => {
    setPhone(event.target.value);
  };
  const HandleState = (event) => {
    setState(event.target.value);
  };
  const onSubmit = async (data) => {
    setBtnLoading(true);
    try {
      const formData = new FormData();
      formData.append("company_name", data.company_name);
      formData.append("phone_no", data.phone_no);
      formData.append("state", data.state);
      formData.append("city", data.city);
      formData.append("address", data.address);
      if (image) {
        formData.append("img", image);
        formData.append("p_img", userPic);
      } else if (userPic !== null) {
        formData.append("img", "");
      }
      const response = await API.post("user/personal-info", formData, {
        headers: {
          Authorization: `Bearer ${storageInfo?.data?.token}`,
        },
      });
      setBtnLoading(false);
      companyData();
      toast.success(response.data.message);
    } catch (error) {
      setBtnLoading(false);
    }
  };

  const companyData = async () => {
    try {
      const formData = new FormData();
      const response = await companyInfoData(formData, storageInfo.data.token);
      setUserInfo({
        ...userInfo,
        name: response.data.data.name,
      });
      setUserImage({
        ...userImage,
        company_logo: response.data.data.company_logo,
      });
      storageInfo.data.company_info.name = response.data.data.name;
      storageInfo.data.company_info.address = response.data.data.address;
      storageInfo.data.company_info.city = response.data.data.city;
      storageInfo.data.company_info.phone_no = response.data.data.phone_no;
      storageInfo.data.company_info.state = response.data.data.state;
      storageInfo.data.company_info.company_logo =
        response.data.data.company_logo;
      localStorage.setItem("c_info", JSON.stringify(storageInfo));
    } catch (error) {}
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-lg-7 col-md-7">
            <div className="personal_info">
              <h3>Company Information</h3>
              <div className="personal_form row g-3">
                <div className="personal_col col-6">
                  <label>Company Name</label>
                  <input
                    {...register("company_name")}
                    name="company_name"
                    type="text"
                    className="form-control"
                    placeholder="Company Name"
                    onChange={HandleName}
                    value={compname}
                  />
                  {errors.company_name && (
                    <p className="error text-danger m-0">
                      {errors.company_name.message}
                    </p>
                  )}
                </div>
                <div className="personal_col col-6">
                  <label>Phone No</label>
                  <input
                    {...register("phone_no")}
                    name="phone_no"
                    type="text"
                    className="form-control"
                    placeholder="Phone No"
                    onChange={HandlePhone}
                    value={compphone}
                  />
                </div>

                <div className="personal_col pas_inputs col-6">
                  <label>Email</label>
                  <input
                    {...register("c_email")}
                    name="c_email"
                    type="text"
                    className="form-control"
                    placeholder="Email"
                    value={companyemail}
                  />
                </div>
                <div className="personal_col pas_inputs col-6">
                  <label>State</label>
                  <input
                    {...register("state")}
                    name="state"
                    type="text"
                    className="form-control"
                    placeholder="State"
                    onChange={HandleState}
                    value={compstate}
                  />
                </div>
                <div className="personal_col pas_inputs col-6">
                  <label>City</label>
                  <input
                    {...register("city")}
                    name="city"
                    type="text"
                    className="form-control"
                    placeholder="City"
                    onChange={HandleCity}
                    value={compcity}
                  />
                </div>
                <div className="personal_col pas_inputs col-6">
                  <label>Address</label>
                  <input
                    {...register("address")}
                    name="address"
                    className="form-control"
                    placeholder="Address"
                    onChange={HandleAddress}
                    value={compaddress}
                  />
                </div>
                <div className="sign_btn ">
                  <button
                    type="submit"
                    className="request_btn apply_btn"
                    disabled={isBtnLoading}
                  >
                    {!isBtnLoading ? (
                      "Save"
                    ) : (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-5">
            <div className="picker col-8">
              <div className="inner_picker">
                <div className="image_picker p-0" onClick={handleImageClick}>
                  <label className="penlabel" for="image-picker">
                    <i className="fa-solid fa-pen"></i>
                  </label>

                  {image ? (
                    <img src={URL.createObjectURL(image)} alt="" />
                  ) : userPic ? (
                    <img src={`${baseURL}${userPic}`} alt="" />
                  ) : (
                    <img src={profile} alt="" />
                  )}

                  <input
                    type="file"
                    onChange={handleImageChange}
                    ref={inputref}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default CompanyInfo;
