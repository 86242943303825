import React from "react";
import GeneralSpinner from "../../components/spiner/Spiner";
import { Spinner, ToastContainer } from "react-bootstrap";
import { companyProfileValidationSchema } from "../../components/profile/ProfileValidation";
import { useTaskContextProvider } from "../../utils/TaskContextProvider";
import LoginHeader from "../auth/login_header";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
function FBcallBack() {
  const {
    loading,
    setLoading,
    setActivePlan,
    setAssignedPermissions,
    setActivePlanId,
    isBtnLoading,
    setBtnLoading,
  } = useTaskContextProvider();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(companyProfileValidationSchema),
  });
  const onSubmit = async (data) => {};
  return (
    <>
      {!loading ? (
        <div className="mian_body pb-4">
          <LoginHeader />
          <div className="container">
            <div className="sign_header col-lg-10 m-auto">
              <div className="heading_">
                <h3>Company Information</h3>
              </div>
            </div>
            <ToastContainer />
            <div className="col-lg-6 m-auto">
              <div className="signup_card mt-4">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="personal_info">
                    <div className="personal_form row g-3">
                      <div className="personal_col col-12">
                        <label>Company Name</label>
                        <input
                          {...register("company_name")}
                          name="company_name"
                          type="text"
                          className="form-control"
                          placeholder="Company Name"
                        />
                        {errors.company_name && (
                          <p className="error text-danger m-0">
                            {errors.company_name.message}
                          </p>
                        )}
                      </div>

                      <div className="sign_btn justify-content-center">
                        <button
                          type="submit"
                          className="request_btn apply_btn"
                          disabled={isBtnLoading}
                        >
                          {!isBtnLoading ? (
                            "Save"
                          ) : (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          {/* <p>Checking your profile info...</p> */}
          <GeneralSpinner />
        </>
      )}
    </>
  );
}

export default FBcallBack;
