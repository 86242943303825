import * as yup from "yup";

export const companyProfileValidationSchema = yup.object({
  company_name: yup.string().required("Company Name is required"),
  phone_no: yup.string().optional(),
  address: yup.string().optional(),
  email: yup.string().optional(),
  state: yup.string().optional(),
  city: yup.string().optional(),
});
export const memberProfileValidationSchema = yup.object({
  full_name: yup.string().required("Full Name is required"),
  email: yup.string().required("Email is required"),
});
export const passwordValidationSchema = yup.object({
  old_password: yup.string().required("Old Password is required"),
  new_password: yup.string().required("New Password is required"),
  con_password: yup.string().required("Confirm Password is required"),
});
