import React from "react";

function ProjectHead({ resetForm }) {
  return (
    <div>
      <div className="board_header">
        <div className="main_heading">
          <h3>All Projects</h3>
          <p>
            Here you can see complete list of tasks that you assigned to others
            in the past
          </p>
        </div>
        {/* <div className="more_time">
          <button
            className="btn request_btn w-100"
            type="button"
            onClick={resetForm}
          >
            Add New Project
          </button>
        </div> */}
      </div>
    </div>
  );
}

export default ProjectHead;
