import React from "react";

function TaskHead() {
  return (
    <div>
      <div className="board_header">
        <div className="main_heading">
          <h3>My Task</h3>
          <p>
            You can see here your tasks, submission time and priority also
            assign a task here.
          </p>
        </div>
      </div>
    </div>
  );
}

export default TaskHead;
