import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { passwordValidationSchema } from "./ProfileValidation";
import { updatePassword } from "../../api/profile";
import { storageData } from "../helpers/helper";
import { ToastContainer, toast } from "react-toastify";
import { useTaskContextProvider } from "../../utils/TaskContextProvider";
import { Spinner } from "react-bootstrap";
function ProfileSecurity() {
  const { isBtnLoading, setBtnLoading } = useTaskContextProvider();

  const storageInfo = storageData();
  const tokenid = storageInfo.data.token;
  const userID = storageInfo.data.id;
  const [passwordType, setPasswordType] = useState("password");
  const [passwordTypeA, setPasswordTypeA] = useState("password");
  const [passwordTypeB, setPasswordTypeB] = useState("password");
  let passwordcheck = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm;
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    } else {
      setPasswordType("password");
    }
  };
  const togglePasswordA = () => {
    if (passwordTypeA === "password") {
      setPasswordTypeA("text");
      return;
    } else {
      setPasswordTypeA("password");
    }
  };
  const togglePasswordB = () => {
    if (passwordTypeB === "password") {
      setPasswordTypeB("text");
      return;
    } else {
      setPasswordTypeB("password");
    }
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(passwordValidationSchema),
  });
  const onSubmit = async (data) => {
    if (!data.new_password.match(passwordcheck)) {
      toast.error(
        "Password must be at least 8 character with combination of uper case,special characterand number"
      );
      return false;
    } else if (data.new_password !== data.con_password) {
      toast.error("Password not matched");
      return false;
    }
    setBtnLoading(true);

    try {
      const formData = {
        id: userID,
        old_pswd: data.old_password,
        password: data.new_password,
        confirm_password: data.con_password,
      };
      const Token = tokenid;
      const response = await updatePassword(formData, Token);
      setBtnLoading(false);

      toast.success(response.data.message);
      return false;
    } catch (error) {
      setBtnLoading(false);

      toast.error(error.response.data.error);
    }
  };
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ToastContainer />
        <div className="row">
          <div className="col-lg-7 col-md-7">
            <div className="personal_info">
              <h3>Security Settings</h3>
              <div className="personal_form row g-3">
                <div className="col-md-12 position-relative">
                  <label for="validationCustom05" className="form-label">
                    Old Password*
                  </label>
                  <input
                    {...register("old_password")}
                    name="old_password"
                    type={passwordType}
                    // value={passwordInput}
                    className="form-control"
                    placeholder="Enter Old Password"
                  />
                  {errors.old_password && (
                    <p className="error text-danger m-0">
                      {errors.old_password.message}
                    </p>
                  )}
                  <div className="input-group-btn pass_icon">
                    <span onClick={togglePassword}>
                      {passwordType === "password" ? (
                        <i className="fa-sharp fa-solid fa-eye-slash"></i>
                      ) : (
                        <i className="fa-solid fa-eye"></i>
                      )}
                    </span>
                  </div>
                </div>
                <div className="col-md-12 position-relative">
                  <label for="validationCustom06" className="form-label">
                    New Password*
                  </label>
                  <input
                    {...register("new_password")}
                    name="new_password"
                    type={passwordTypeA}
                    // value={passwordInputA}
                    className="form-control"
                    placeholder="Enter New Password"
                  />
                  {errors.new_password && (
                    <p className="error text-danger m-0">
                      {errors.new_password.message}
                    </p>
                  )}
                  <div className="input-group-btn pass_icon">
                    <span onClick={togglePasswordA}>
                      {passwordTypeA === "password" ? (
                        <i className="fa-sharp fa-solid fa-eye-slash"></i>
                      ) : (
                        <i className="fa-solid fa-eye"></i>
                      )}
                    </span>
                  </div>
                </div>
                <div className="col-md-12 position-relative">
                  <label for="validationCustom06" className="form-label">
                    Conform Password*
                  </label>
                  <input
                    {...register("con_password")}
                    name="con_password"
                    type={passwordTypeB}
                    // value={passwordInputB}
                    className="form-control"
                    placeholder="Conform New Password"
                  />
                  {errors.con_password && (
                    <p className="error text-danger m-0">
                      {errors.con_password.message}
                    </p>
                  )}
                  <div className="input-group-btn pass_icon">
                    <span onClick={togglePasswordB}>
                      {passwordTypeB === "password" ? (
                        <i className="fa-sharp fa-solid fa-eye-slash"></i>
                      ) : (
                        <i className="fa-solid fa-eye"></i>
                      )}
                    </span>
                  </div>
                </div>
                <div className="sign_btn ">
                  <button
                    type="submit"
                    className="request_btn apply_btn btn"
                    disabled={isBtnLoading}
                  >
                    {!isBtnLoading ? (
                      "Change Password"
                    ) : (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ProfileSecurity;
