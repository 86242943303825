import React, { useState } from "react";
import { createChecklist, deleteChecklist } from "../../api/task";
import { storageData } from "../helpers/helper";
import { useTaskContextProvider } from "../../utils/TaskContextProvider";
import { getChecklistHelper } from "../helpers/TaskHelpers";
import { toast } from "react-toastify";

const Checklist = ({ taskId }) => {
  const { departIdasInt, checkLists, setCheckLists, assignedPermissions } =
    useTaskContextProvider();
  const storageInfo = storageData();
  const [newItem, setNewItem] = useState("");
  const [editState, setEditState] = useState({
    editingIndex: null,
    editedItemName: "",
  });

  const handleEditClick = (id, name) => {
    setEditState({ editingIndex: id, editedItemName: name });
  };

  const handleAction = async (action, checkId) => {
    if (assignedPermissions.createCheckList) {
      const updatedChecklists = [...checkLists];
      const targetIndex = updatedChecklists.findIndex(
        (item) => item.check_id === checkId
      );
      setCheckLists(updatedChecklists);

      const formData = {
        department_id: departIdasInt,
        task_id: taskId,
        check_id: checkId,
        name: updatedChecklists[targetIndex]?.name,
      };

      switch (action) {
        case "create":
          formData.name = newItem;
          formData.check_status = 0;
          try {
            const createResponse = await createChecklist(
              formData,
              storageInfo.data.token
            );
            const newChecklist = createResponse.data.data;
            setCheckLists((prevChecklists) => [
              newChecklist,
              ...prevChecklists,
            ]);
            setNewItem("");
            const checklistResponse = await getChecklistHelper(taskId);
            setCheckLists(checklistResponse.data);
          } catch (error) {
            console.error("Error creating checklist:", error);
          }
          break;

        case "check":
        case "uncheck":
          if (targetIndex !== -1) {
            updatedChecklists[targetIndex].check_status =
              action === "check" ? 1 : 0;
            formData.check_status = action === "check" ? 1 : 0;
            try {
              await createChecklist(formData, storageInfo.data.token);
              const checklistResponse = await getChecklistHelper(taskId);
              setCheckLists(checklistResponse.data);
            } catch (error) {
              console.error("Error updating checklist:", error);
            }
          }
          break;

        case "edit":
          if (editState.editingIndex !== null) {
            const itemToEdit = updatedChecklists.find(
              (item) => item.check_id === editState.editingIndex
            );
            if (itemToEdit) {
              itemToEdit.name = editState.editedItemName;
              formData.name = itemToEdit.name;

              try {
                await createChecklist(formData, storageInfo.data.token);
                setCheckLists(updatedChecklists);
                setEditState({ editingIndex: null, editedItemName: "" });
                const checklistResponse = await getChecklistHelper(taskId);
                setCheckLists(checklistResponse.data);
              } catch (error) {
                console.error("Error editing checklist:", error);
              }
            }
          }
          break;

        case "delete":
          const confirmed = window.confirm(
            "Are you sure you want to delete this checklist?"
          );
          if (confirmed) {
            const updatedChecklistsAfterDelete = checkLists.filter(
              (item) => item.check_id !== checkId
            );
            setCheckLists(updatedChecklistsAfterDelete);
            try {
              await deleteChecklist(formData, storageInfo.data.token);
            } catch (error) {
              console.error("Error deleting checklist:", error);
            }
          }
          break;

        default:
          break;
      }
    } else {
      toast.error(
        "You don't have permission for this action. Please contact your admin!"
      );
    }
  };

  const renderChecklistItem = (item, index, labelId, checkboxId) => (
    <div className="form-check mt-2" key={index}>
      <input
        type="checkbox"
        className="form-check-input"
        id={checkboxId}
        checked={item.check_status === 1}
        onChange={() =>
          handleAction(
            item.check_status === 1 ? "uncheck" : "check",
            item.check_id
          )
        }
      />
      {editState.editingIndex === item.check_id ? (
        <div className="flex">
          <input
            type="text"
            value={editState.editedItemName}
            className="form-control"
            onChange={(e) =>
              setEditState({ ...editState, editedItemName: e.target.value })
            }
          />
          <button
            className="btn request_btn w-100 mt-2"
            onClick={() => handleAction("edit", item.check_id)}
          >
            Save
          </button>
        </div>
      ) : (
        <>
          <label className="form-check-label label-name" htmlFor={labelId}>
            {item.name}
          </label>

          {assignedPermissions.createCheckList && (
            <i
              className="fa-solid fa-pencil"
              onClick={() => handleEditClick(item.check_id, item.name)}
            ></i>
          )}

          {assignedPermissions.deleteCheckList && (
            <i
              className="fa-solid fa-trash"
              onClick={() => handleAction("delete", item.check_id)}
            ></i>
          )}
        </>
      )}
    </div>
  );

  return (
    <div>
      <div className="detail_discription">
        <h6>Checklists</h6>
      </div>

      {assignedPermissions.createCheckList && (
        <div>
          <input
            type="text"
            value={newItem}
            className="form-control mb-2"
            placeholder="Checklist Name"
            onChange={(e) => setNewItem(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && handleAction("create")}
          />
          <button
            className="btn signup_btn w-100 py-2 mb-3"
            onClick={() => handleAction("create")}
          >
            Add Checklist
          </button>
        </div>
      )}

      {checkLists?.length === 0 && (
        <p
          style={{
            fontSize: "14px",
            color: "red",
          }}
        >
          No CheckList Added
        </p>
      )}

      {checkLists.length !== 0 && (
        <div>
          <h6>Pending Checklists</h6>
          {checkLists
            .filter((item) => item.check_status === 0)
            .map((item, index) =>
              renderChecklistItem(
                item,
                index,
                `checklist-${index}`,
                `checklist-${index}`
              )
            )}
        </div>
      )}

      {checkLists.length !== 0 && (
        <div className="mt-2">
          <h6>Completed Checklists</h6>
          {checkLists
            .filter((item) => item.check_status === 1)
            .map((item, index) =>
              renderChecklistItem(
                item,
                index,
                `completed-checklist-${index}`,
                `completed-checklist-${index}`
              )
            )}
        </div>
      )}
    </div>
  );
};

export default Checklist;
