import React, { useState, useRef, useEffect } from "react";

function BigTimer({ toDateReport }) {
  const Ref = useRef(null);
  const [timer, setTimer] = useState("00:00:00");

  const getTimeRemaining = (endDate) => {
    const total = Date.parse(endDate) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));

    return {
      total,
      days,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = (endDate) => {
    let { total, days, hours, minutes, seconds } = getTimeRemaining(endDate);
    if (total >= 0) {
      setTimer(
        (days > 0 ? days + " days " : "") +
          (hours > 9 ? hours : "0" + hours) +
          " hrs : " +
          (minutes > 9 ? minutes : "0" + minutes) +
          " min : " +
          (seconds > 9 ? seconds : "0" + seconds) +
          " sec"
      );
    }
  };

  const clearTimer = (endDate) => {
    setTimer("--:--:--");

    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(endDate);
    }, 1000);
    Ref.current = id;
  };

  useEffect(() => {
    clearTimer(toDateReport);
  }, [toDateReport]);

  return (
    <div>
      <div className="clock">
        <h1>{timer}</h1>
      </div>
    </div>
  );
}

export default BigTimer;
