import React from "react";
import { ReactComponent as Cross } from "../../../assets/images/cross.svg";
function MobileSite(props) {
  return (
    <div>
      {props.mobiledata.map((post, index) => {
        return (
          <div className="insight_right_main mobile_view_insight pb-0">
            <div className="mobile_view_insight border border-white">
              <div
                className="row"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#M${post.id}`}
                aria-expanded="false"
                aria-controls={`M${post.id}`}
              >
                <div className="col-6">
                  <div className="">
                    <div className="header_contents text-center">
                      <p>Name</p>
                    </div>
                    <div className="collapse height_set" id={`M${post.id}`}>
                      <div className="header_contents text-center">
                        <p className="">Email</p>
                        <p className="">Role</p>
                        <p className="">Department</p>
                        <p className="">Status</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6 position-relative">
                  <div className="header_contents">
                    <p>{post.full_name}</p>
                  </div>
                  <div className="drop_btn mobile_dropdown">
                    <i className="fa-solid fa-trash"></i>
                  </div>
                  <div className="collapse height_set" id={`M${post.id}`}>
                    <div className="">
                      <p className="">{post.email}</p>
                      <div className="levels">
                        <p>{post.role_name}</p>
                      </div>
                      <div className="levels">
                        <p>{post.tmp_department_name}</p>
                      </div>
                      <div className="tick_marked d-flex">
                        <Cross />
                        <p>Rejected</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default MobileSite;
