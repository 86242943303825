import React, { useState } from "react";
import { Modal } from "react-bootstrap";

const RequestTime = () => {
  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  return (
    <div>
      <div className="priority_card">
        <div className="request_heading pb-3">
          <h3>Need more time to complete this task?</h3>
        </div>
        <div className="more_time">
          <button
            className="btn request_btn w-100"
            type="button"
            onClick={handleShow2}
          >
            Request more time
          </button>
        </div>
      </div>
      <Modal
        show={show2}
        onHide={handleClose2}
        size="lg"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <div className="model_heading">
            <h1 className="modal-title" id="staticBackdropLabel">
              Fill In The Form To Extend Your Deadline
            </h1>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="request_info">
            <form action="">
              <div className="col-md-12">
                <label for="validationCustom01" className="form-label">
                  How Much Time Do You Need More
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="validationCustom01"
                  placeholder="For example: 10 Days"
                  required
                />
              </div>
              <div className="col-md-12 mt-3">
                <label for="validationCustom01" className="form-label">
                  Tell Us Why You Need More Time.
                </label>
                <textarea
                  rows="10"
                  cols="50"
                  className="text_box"
                  placeholder="Give a descriptive reason"
                />
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            variant="secondary"
            className="cancel_btn"
            onClick={handleClose2}
          >
            Close
          </button>
          <button
            variant="primary"
            className="request_btn apply_btn"
            onClick={handleClose2}
          >
            Save Changes
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default RequestTime;
