import React from "react";
import profile from "../../../assets/images/profile.png";
import { CircularProgressbar } from "react-circular-progressbar";
import ProgressBar from "react-bootstrap/ProgressBar";
import ProjectInsightMobile from "./ProjectInsightMobile";
import ImageFunction from "../../department/ImageFunction";

const ProjectOverview = ({ insightdata }) => {
  const baseURL = process.env.REACT_APP_URL;

  if (insightdata !== "") {
    var projectHealth = (
      (insightdata.info.project_completed_tasks /
        (insightdata.info.total_project_tasks === 0
          ? (insightdata.info.total_project_tasks = 1)
          : insightdata.info.total_project_tasks)) *
      100
    ).toFixed(1);
  }

  const getRemarks = (percentage) => {
    if (percentage < 25) {
      return "Poor";
    } else if (percentage >= 25 && percentage < 50) {
      return "Below Average";
    } else if (percentage >= 50 && percentage < 75) {
      return "Average";
    } else if (percentage >= 75 && percentage <= 100) {
      return "Good";
    }
  };

  const getRemarksClass = (percentage) => {
    if (percentage < 25) {
      return "remarks-poor";
    } else if (percentage >= 25 && percentage < 50) {
      return "remarks-below-average";
    } else if (percentage >= 50 && percentage < 75) {
      return "remarks-average";
    } else if (percentage >= 75 && percentage <= 100) {
      return "remarks-good";
    }
  };

  return (
    <>
      <div className="col-lg-3 col-md-3 col-sm-4">
        {
          <div className="project_card_main">
            <div className="name_company">
              <h6>{insightdata.info?.project_name}</h6>
            </div>

            <hr></hr>

            <div className="main_bar">
              <h5>Project health</h5>
              <p>Based on your team's work</p>
              <div className="d-flex justify-content-center">
                <div className="bar">
                  <CircularProgressbar
                    value={projectHealth}
                    text={`${parseFloat(projectHealth)}%`}
                  />
                </div>
              </div>
              <div className={`${getRemarksClass(projectHealth)} good mt-3`}>
                <p>{getRemarks(projectHealth)}</p>
              </div>
            </div>
            <div className="pcard_header">
              <div className="project_owner">
                <h6>Added By</h6>
                <div className="owner_pic">
                  <p>{insightdata.info?.added_by}</p>
                </div>
              </div>
            </div>
          </div>
        }
      </div>

      <div className="col-lg-9 col-md-9 col-sm-8">
        {insightdata.task_members?.length === 0 ? (
          <div className="insight_right_main">
            <ImageFunction name="Member" line="Try to add more members" />
          </div>
        ) : (
          <div className="insight_right_main">
            <div className="desktop_view_insight">
              <div className="header_row">
                <div className="row">
                  <div className="col-lg-4 col-4">
                    <div className="header_content">
                      <p>Members</p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-4">
                    <div className="header_content">
                      <p>Progress</p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-4">
                    <div className="header_content">
                      <p>Remarks</p>
                    </div>
                  </div>
                </div>
              </div>
              {insightdata.task_members?.map((post) => {
                const progressPercentage = (
                  (post.project_completed_tasks / post.total_assigned_tasks) *
                  100
                ).toFixed(2);
                return (
                  <>
                    <div className="body_row">
                      <div className="row">
                        <div className="col-lg-4 col-4">
                          <div className="header_content mt-2">
                            <div className="image_name">
                              {post.profile_image ? (
                                <img
                                  src={`${baseURL}${post.profile_image}`}
                                  alt=""
                                />
                              ) : (
                                <img src={profile} alt="" />
                              )}
                              <p>{post.member_name}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-4">
                          <div className="Progress_content">
                            <label>{`${parseFloat(
                              progressPercentage
                            )}%`}</label>
                            <ProgressBar
                              now={progressPercentage}
                              label={`${parseFloat(progressPercentage)}%`}
                              visuallyHidden
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-4">
                          <div className="header_content">
                            <div
                              className={getRemarksClass(progressPercentage)}
                            >
                              <p className="remarks-text">
                                {getRemarks(progressPercentage)}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
            <div className="mobile_view_insight">
              <ProjectInsightMobile insightdata={insightdata} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default ProjectOverview;
