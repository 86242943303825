import React from "react";
import profile from "../../../assets/images/profile.png";
import ProgressBar from "react-bootstrap/ProgressBar";

function ProjectInsightMobile({ insightdata }) {
  const baseURL = process.env.REACT_APP_URL;
  return (
    <>
      {insightdata.task_members?.map((post) => {
        const completionPercentage = (
          (post.completed_tasks / post.total_assigned_tasks) *
          100
        ).toFixed(2);
        const inProgressPercentage = (
          (post.in_progress_tasks / post.total_assigned_tasks) *
          100
        ).toFixed(2);
        return (
          <div
            className="row mb-4"
            type="button"
            data-bs-toggle="collapse colapse_class"
            data-bs-target={`#M${post.user_id}`}
            aria-expanded="false"
            aria-controls={`M${post.user_id}`}
          >
            <div className="col-6">
              <div className="head_mobile">
                <div className="header_content">
                  <p>Members</p>
                </div>
                <div className="collapse colapse_class" id={`M${post.user_id}`}>
                  <div className="">
                    <div className="header_content">
                      <p className="task_tag">Tasks</p>
                      <p className="progres_tag">Progress</p>
                      <p className="remark_tag">Remarks</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 position-relative">
              <div className="image_name">
                {post.profile_image ? (
                  <img src={`${baseURL}${post.profile_image}`} alt="" />
                ) : (
                  <img src={profile} alt="" />
                )}
                <p className="m-0">{post.member_name}</p>
              </div>
              <div className="updown_icon">
                <i className="fa-solid fa-arrows-up-down"></i>
              </div>
              <div className="collapse colapse_class" id={`M${post.user_id}`}>
                <div className="">
                  <div className="header_content mt-3">
                    <ProgressBar
                      now={completionPercentage}
                      label={`${parseFloat(completionPercentage)}% Completed`}
                    />
                  </div>
                  <div className="Progress_content mt-3">
                    <label>{`${parseFloat(inProgressPercentage)}%`}</label>
                    <ProgressBar
                      now={inProgressPercentage}
                      label={`${parseFloat(inProgressPercentage)}%`}
                      visuallyHidden
                    />
                  </div>
                  <div className="header_content mt-4">
                    <div className="good_remarks">
                      <p>Good</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}

export default ProjectInsightMobile;
