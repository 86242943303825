import React from "react";

function ListHead() {
  return (
    <div>
      <div className="projecttable_head">
        <div className="row">
          <div className="col-2">
            <p>Project Name</p>
          </div>
          <div className="col-2">
            <p>Task Name</p>
          </div>
          <div className="col-2">
            <p>Date</p>
          </div>
          <div className="col-2">
            <p>Status</p>
          </div>
          <div className="col-2">
            <p>Assign by</p>
          </div>
          <div className="col-2">
            <p>Priority</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListHead;
