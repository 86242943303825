import { toast } from "react-toastify";
import { getRolePermissions } from "../../api/permissions";
import { findModuleValue } from "./helper";

const rolePermissionsHelper = async (
  dept_id,
  role_id,
  token_,
  setAssignedPermissions,
  setBtnLoading
) => {
  try {
    const formDataPermissions = {
      dept_id: dept_id,
      role_id: role_id,
    };

    const token = token_;
    const rolePermissions = await getRolePermissions(
      formDataPermissions,
      token
    );

    if (rolePermissions.data.data.length > 0) {
      setAssignedPermissions((prevState) => ({
        ...prevState,
        //members
        members: findModuleValue(
          "Member",
          "allMember",
          rolePermissions.data.data
        ),
        edit: findModuleValue(
          "Member",
          "update-member",
          rolePermissions.data.data
        ),
        updatePw: findModuleValue(
          "Member",
          "update-pswd",
          rolePermissions.data.data
        ),
        create: findModuleValue("Member", "invite", rolePermissions.data.data),
        remove: findModuleValue("Member", "delete", rolePermissions.data.data),

        //projects
        projectList: findModuleValue(
          "Project",
          "get-list",
          rolePermissions.data.data
        ),
        createProject: findModuleValue(
          "Project",
          "create-project",
          rolePermissions.data.data
        ),
        projectInsights: findModuleValue(
          "Project",
          "insight",
          rolePermissions.data.data
        ),
        removeProject: findModuleValue(
          "Project",
          "delete",
          rolePermissions.data.data
        ),

        //tasks
        taskList: findModuleValue(
          "Task",
          "project-task-data",
          rolePermissions.data.data
        ),
        taskBoard: findModuleValue(
          "Task",
          "boards-project",
          rolePermissions.data.data
        ),
        createTask: findModuleValue(
          "Task",
          "create-task",
          rolePermissions.data.data
        ),
        removeTask: findModuleValue(
          "Task",
          "remove-task",
          rolePermissions.data.data
        ),

        // taskDetails
        taskDetails: findModuleValue(
          "Task",
          "task-details",
          rolePermissions.data.data
        ),
        taskStatusList: findModuleValue(
          "Task",
          "project-status-list",
          rolePermissions.data.data
        ),
        createStatus: findModuleValue(
          "Task",
          "create-status",
          rolePermissions.data.data
        ),
        deleteStatus: findModuleValue(
          "Task",
          "delete-status",
          rolePermissions.data.data
        ),

        //comments
        commentList: findModuleValue(
          "Comment",
          "comment-task",
          rolePermissions.data.data
        ),
        saveComments: findModuleValue(
          "Comment",
          "save-comments",
          rolePermissions.data.data
        ),
        replyComments: findModuleValue(
          "Comment",
          "reply-comments",
          rolePermissions.data.data
        ),
        deleteComments: findModuleValue(
          "Comment",
          "delete-comment",
          rolePermissions.data.data
        ),

        //attachments
        attachmentsList: findModuleValue(
          "Attachment",
          "attachment-list",
          rolePermissions.data.data
        ),
        saveAttachments: findModuleValue(
          "Attachment",
          "attachment",
          rolePermissions.data.data
        ),
        deleteAttachments: findModuleValue(
          "Attachment",
          "attachment-delete",
          rolePermissions.data.data
        ),

        //checklists
        checkLists: findModuleValue(
          "Check List",
          "check-list",
          rolePermissions.data.data
        ),
        createCheckList: findModuleValue(
          "Check List",
          "create-check-list",
          rolePermissions.data.data
        ),
        deleteCheckList: findModuleValue(
          "Check List",
          "delete-check-list",
          rolePermissions.data.data
        ),
      }));
    }
    setBtnLoading(false);

    return false;
  } catch (error) {
    toast.error(error?.data?.message);
  }
};

export default rolePermissionsHelper;
