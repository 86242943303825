import React from "react";

function LoadMore({ handleLoadMore, loading }) {
  return (
    <div>
      <div className="loadmore_btn d-flex justify-content-center">
        <button
          className="btn request_btn text-center load_more"
          onClick={handleLoadMore}
          disabled={loading}
          type="button"
        >
          Load More
        </button>
      </div>
    </div>
  );
}

export default LoadMore;
