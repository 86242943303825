import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import "./task.css";
import { toast } from "react-toastify";
import {
  GetMembersForTasks,
  GetProjectsAndTasks,
  GetProjectsForTasks,
  GetTasksForBoard,
  GetTasksList,
  deleteTask,
} from "../../api/task";
import { AiOutlineSearch } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";

import Container from "../../components/layouts/container";
import LeftSide from "../../components/layouts/left_side";
import { storageData } from "../../components/helpers/helper";
import { useNavigate } from "react-router";
import { useTaskContextProvider } from "../../utils/TaskContextProvider";
import TaskHead from "../../components/task/TaskHead";
import { Modal } from "react-bootstrap";
import DeleteModal from "../../components/projects/DeleteModal";
import TaskModal from "../../components/task/taskmodals/TaskModal";
import DraggableComponent from "../../components/task/DraggableComponent";
import TaskListData from "../../components/task/TaskListData";
import api from "../../components/helpers/AccessHelper";
import GeneralSpinner from "../../components/spiner/Spiner";
import { Link } from "react-router-dom";
import rolePermissionsHelper from "../../components/helpers/rolePermissionHelper";

function Task() {
  const {
    setProjectlist,
    setTasklist,
    setProjectID,
    setActiveTab,
    departIdasInt,
    projectlistTask,
    setProjectlistForTasks,
    setProjectlistAndTasks,
    setMemberlistForTasks,
    show2,
    handleClose2,
    taskId,
    handleClose1,
    show1,
    setTaskListBoard,
    setLoading,
    loading,
    setAssignedPermissions,
    setBtnLoading,
  } = useTaskContextProvider();

  const pathname = window.location.pathname;
  const parts = pathname.split("/");

  const [tabName, setTabName] = useState("list");

  const storageInfo = storageData();
  const navigate = useNavigate();

  const dept_id = localStorage.getItem("u_did");
  const role_id = localStorage.getItem("role_id");
  const userType = storageInfo.data.user_type;
  const token = storageInfo.data.token;

  const getProjects = async () => {
    try {
      const formData = {
        department_id: JSON.parse(departIdasInt),
      };
      const response = await GetProjectsForTasks(
        formData,
        storageInfo.data.token
      );
      setProjectlistForTasks(response.data.data);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        navigate("/permission-denied");
      } else {
        console.error("Error fetching members:", error);
      }
    }
  };

  const ProjectsandTasks = async () => {
    try {
      const formData = {
        department_id: JSON.parse(departIdasInt),
      };
      const response = await GetProjectsAndTasks(
        formData,
        storageInfo.data.token
      );
      setProjectlistAndTasks(response.data);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        navigate("/permission-denied");
      } else {
        console.error("Error fetching members:", error);
      }
    }
  };

  const getMembers = async () => {
    try {
      const formData = {
        department_id: JSON.parse(departIdasInt),
      };
      const response = await GetMembersForTasks(
        formData,
        storageInfo.data.token
      );
      setMemberlistForTasks(response.data.data);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        navigate("/permission-denied");
      } else {
        console.error("Error fetching members:", error);
      }
    }
  };

  const checkDeptAccess = async () => {
    setLoading(true);
    try {
      const projectTaskData = {
        department_id: JSON.parse(departIdasInt),
      };
      const getProjectList = await api.post(
        "task/task-projects",
        projectTaskData,
        {
          headers: {
            Authorization: `Bearer ${storageInfo?.data?.token}`,
          },
        }
      );
      if (getProjectList.data.success === true) {
        if (getProjectList?.data?.data?.length > 0) {
          setProjectlist(getProjectList?.data?.data);
        }
        const projectID = getProjectList?.data?.data[0]["id"];
        setActiveTab("to do");
        const taskData = {
          u_id: storageInfo?.data?.id,
          p_id: projectID,
          task_status: "to do",
          company_id: storageInfo?.data?.company_info?.id,
        };
        setProjectID(projectID);
        const getTasks = await GetTasksList(taskData, storageInfo?.data?.token);
        if (getTasks?.data?.data?.length > 0) {
          setTasklist(getTasks?.data?.data);
        }
        ProjectsandTasks();
        getMembers();
        getProjects();
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);

      if (error?.response?.data?.data === "permission") {
        navigate("/404");
      }
      if (error?.response?.data?.data === "plan") {
        navigate("/dashboard");
      }
    }
  };
  useEffect(() => {
    ProjectsandTasks();
    checkDeptAccess();
  }, []);

  const handleClickList = () => {
    setTabName("list");
    ProjectsandTasks();
  };

  const removeTask = async () => {
    try {
      const formData = {
        id: taskId,
      };
      const Token = storageInfo.data.token;
      const response = await deleteTask(formData, Token);
      checkDeptAccess();
      handleClose2();
      toast.success(response.data.message);
      return false;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  const [searchName, setSearchName] = useState("");
  const searchHandler = (e) => {
    setSearchName(e.target.value);
    GetSearchTaskProjects(e.target.value);
  };
  const GetSearchTaskProjects = async (name) => {
    const taskData = {
      department_id: JSON.parse(departIdasInt),
      search: name,
    };
    if (name.length >= 3) {
      if (tabName === "list") {
        const getTasks = await GetProjectsAndTasks(
          taskData,
          storageInfo.data.token
        );
        setProjectlistAndTasks(getTasks.data);
      } else {
        setLoading(true);
        const tasksListForBoard = await GetTasksForBoard(
          taskData,
          storageInfo?.data?.token
        );
        setTaskListBoard(tasksListForBoard.data.data);
        setLoading(false);
      }
      return false;
    } else if (name.length === 0) {
      if (tabName === "list") {
        const getTasks = await GetProjectsAndTasks(
          taskData,
          storageInfo.data.token
        );
        setProjectlistAndTasks(getTasks.data);
      } else {
        setLoading(true);
        const tasksListForBoard = await GetTasksForBoard(
          taskData,
          storageInfo?.data?.token
        );
        setTaskListBoard(tasksListForBoard.data.data);
        setLoading(false);
      }
    }
  };
  const removeInput = () => {
    setSearchName("");
    GetSearchTaskProjects("");
  };
  // ------------Board Projects------------

  const getBoardProjects = async () => {
    setTabName("board");
    setLoading(true);
    try {
      const formData = {
        department_id: departIdasInt,
        project_id: parts[3] ? parts[3] : null,
      };
      const tasksListForBoard = await GetTasksForBoard(
        formData,
        storageInfo?.data?.token
      );
      setTaskListBoard(tasksListForBoard.data.data);
      setLoading(false);
    } catch (error) {
      toast.error(error.response.data.message);
      setLoading(false);
      if (error.response && error.response.status === 404) {
        navigate("/permission-denied");
      } else {
        console.error("Error fetching members:", error);
      }
    }
  };

  useEffect(() => {
    if (userType === "member") {
      rolePermissionsHelper(
        dept_id,
        role_id,
        token,
        setAssignedPermissions,
        setBtnLoading
      );
    }
  }, []);

  return (
    <div>
      <Container>
        <div className="dashboard_main">
          <div className="main_body">
            <LeftSide />
            <div className="right_side w-100">
              <div className="container-fluid">
                <div className="dashboard_inner col-11 m-auto">
                  <TaskHead />
                  <div className="view_tabs mt-3">
                    <div className="task_head">
                      <div className="nav nav-tabs" id="myTab" role="tablist">
                        <button
                          className="nav-link task_links active"
                          id="profile-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#profile-tab-pane"
                          type="button"
                          role="tab"
                          aria-controls="profile-tab-pane"
                          aria-selected="false"
                          onClick={handleClickList}
                        >
                          List
                        </button>

                        <button
                          className="nav-link task_links "
                          id="home-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#home-tab-pane"
                          type="button"
                          role="tab"
                          aria-controls="home-tab-pane"
                          aria-selected="true"
                          onClick={getBoardProjects}
                        >
                          Board
                        </button>
                      </div>
                      <div className="filter_field">
                        <div className="search_filter_field">
                          <input
                            type="text"
                            className="search_input"
                            placeholder="Search List"
                            value={searchName}
                            onChange={searchHandler}
                          />
                          {searchName === "" ? (
                            <AiOutlineSearch />
                          ) : (
                            <RxCross2 onClick={removeInput} />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {projectlistTask?.data?.length === 0 ? (
                    <div className="alert_warnings alert alert-warning d-flex align-items-center task_alert mt-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="20"
                        width="20"
                        class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"
                        viewBox="0 0 16 16"
                        role="img"
                        aria-label="Warning:"
                      >
                        <path
                          fill="#664d03"
                          d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
                        ></path>
                      </svg>
                      <strong>
                        You don't have any project please add project first
                      </strong>
                      <Link className="add_project_link ms-2" to="/projects">
                        Add Project
                      </Link>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="tab-content" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="profile-tab-pane"
                      role="tabpanel"
                      aria-labelledby="profile-tab"
                      tabindex="0"
                    >
                      {!loading ? (
                        <TaskListData
                          ProjectsandTasks={ProjectsandTasks}
                          checkDeptAccess={checkDeptAccess}
                        />
                      ) : (
                        <GeneralSpinner />
                      )}
                    </div>

                    <div
                      className="tab-pane fade"
                      id="home-tab-pane"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                      tabindex="0"
                    >
                      {!loading ? (
                        <DraggableComponent
                          getBoardProjects={getBoardProjects}
                        />
                      ) : (
                        <GeneralSpinner />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>

      <Modal
        show={show2}
        onHide={handleClose2}
        backdrop="static"
        keyboard={false}
      >
        <DeleteModal
          title="task"
          handleClose2={handleClose2}
          removeCard={removeTask}
        />
      </Modal>

      <Modal
        size="lg"
        show={show1}
        onHide={handleClose1}
        backdrop="static"
        keyboard={false}
      >
        <TaskModal checkDeptAccess={checkDeptAccess} />
      </Modal>
    </div>
  );
}

export default Task;
