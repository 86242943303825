import moment from "moment";
import React from "react";
import { ReactComponent as PDFIcon } from "../../assets/images/PDF Icon.svg";
import { Link } from "react-router-dom";
import { deleteComment, getCommentsReply } from "../../api/task";
import { storageData } from "../helpers/helper";
import { useTaskContextProvider } from "../../utils/TaskContextProvider";
import { toast } from "react-toastify";

const CommentCard = ({
  profileImage,
  profileName,
  createdAt,
  comment,
  setReplyContainer,
  setCommentData,
  key,
  replyContainer,
  fileName,
  filePath,
  fileType,
  setCommentId,
  commentId,
  taskId,
  replyCount,
  setDisplayedRepliesList,
  setDisplayedTextList,
}) => {
  const baseURL = process.env.REACT_APP_URL;
  const storageInfo = storageData();

  const { setLoading_1, assignedPermissions } = useTaskContextProvider();

  const getReplies = async () => {
    setLoading_1(true);

    try {
      const formData = {
        task_id: taskId,
        id: commentId,
      };
      const replies = await getCommentsReply(formData, storageInfo.data.token);
      setLoading_1(false);
      setDisplayedRepliesList(replies.data.data);
    } catch (error) {
      console.error("Error fetching replies:", error);
    } finally {
      setLoading_1(false);
    }
  };

  const deleteCommentReply = async (id) => {
    const formData = {
      id: id,
    };

    try {
      const confirmed = window.confirm(
        "Are you sure you want to delete this comment?"
      );

      if (confirmed) {
        const response = await deleteComment(formData, storageInfo.data.token);

        if (replyContainer) {
          setDisplayedRepliesList((prevList) =>
            prevList.filter((comment) => comment.id !== commentId)
          );
        } else {
          setDisplayedTextList((prevList) =>
            prevList.filter((comment) => comment.id !== commentId)
          );
        }
        toast.success(response.data.message);
      }
    } catch (error) {
      console.error("Error deleting comment:", error);
    }
  };

  return (
    <div key={key} className="comment_container">
      <div className="top-container">
        <div className="comment_img">
          {/* <img src={`${baseURL}${profileImage}`} alt="profile" /> */}
          <h6>{profileName}</h6>
        </div>
        <div className="date_time">
          <p>{moment(createdAt).format("MMM Do, h:mm a")}</p>
        </div>
      </div>

      <p>{comment}</p>

      {fileName && (
        <div className="attachment-container">
          {fileType === "pdf" ? (
            <PDFIcon className="file-preview-icon-comment" />
          ) : (
            <img
              src={`${baseURL}${filePath}`}
              className="file-preview-icon-comment"
              alt="file"
            />
          )}
          <Link
            className="file-name"
            target="_blank"
            to={`${baseURL}${filePath}`}
          >
            {fileName.slice(0, 30)} ...
          </Link>
        </div>
      )}

      <div className="edit-reply-container">
        {!replyContainer && assignedPermissions.replyComments && (
          <p
            className="edit-btn"
            onClick={() => {
              getReplies();
              setReplyContainer(true);
              setCommentId(commentId);
              setCommentData({
                profileImage: profileImage,
                profileName: profileName,
                comment: comment,
                createdAt: createdAt,
                fileName: fileName,
                filePath: filePath,
                fileType: fileType,
              });
            }}
          >
            {replyCount > 0 && replyCount}{" "}
            {replyCount === 0 || replyCount === 1 ? "Reply" : "Replies"}
          </p>
        )}

        {assignedPermissions.deleteComments && (
          <p
            className="reply-btn"
            onClick={() => deleteCommentReply(commentId)}
          >
            Delete
          </p>
        )}
      </div>
    </div>
  );
};

export default CommentCard;
